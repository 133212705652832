import React, { useState, useEffect, useRef, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import EntityTable from '../../parts/EntityTable';
import moment from 'moment';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory, Redirect, useLocation } from 'react-router';
import DeleteDialog from '../../parts/DeleteDialog';
import UserEntry from './UserEntry';
import { ReloadContext } from '../../../contexts/ReloadContext';
import { Switch, useRouteMatch } from 'react-router-dom';
import EntityListFrame from '../../layouts/EntityListFrame';
import { ProtectedRoute } from '../../../protected.route';
import { UserContext } from '../../../contexts/UserContext';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { Snackbar } from '@material-ui/core';
import SetUserPassword from './SetPassword';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  alert: {
    margin: theme.spacing(1),
  },
  title: {
    fontStyle: 'italic',
  },
  buttons: {
    margin: theme.spacing(1),
  },
}));

function Users(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();
  const location = useLocation();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [freeTextSearch, setFreeTextSearch] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: '0',
  });
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [reloadContext] = useContext(ReloadContext);
  const [userContext, setUserContext] = useContext(UserContext);
  const progressRef = useRef(null);
  const history = useHistory();
  const [errorAlertOpen, setErrorAlertOpen] = useState(true);
  const [actionResult, setActionResult] = useState('');
  const [alertErrorMessage, setAlertErrorMessage] = useState(true);
  const [successAlertOpen, setSuccessAlertOpen] = useState(true);

  const [showFrameAsPaper, setShowFrameAsPaper] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    isSuccess: false,
    message: '',
  });

  let match = useRouteMatch();

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService
      .getUsers(page + 1, rowsPerPage, order, orderBy, freeTextSearch)
      .then((result) => {
        progressRef.current.complete();
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
      })
      .catch((err) => {
        console.log('error: ', err);
        if (progressRef && progressRef.current) progressRef.current.complete();
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }, [history, order, orderBy, rowsPerPage, page, dummyCounter, reloadContext, freeTextSearch]);

  useEffect(() => {
    if (location.pathname === '/users/add' || location.pathname.indexOf('edit') > -1 || location.pathname.indexOf('set-password') > -1) {
      setShowFrameAsPaper(false);
    } else {
      setShowFrameAsPaper(true);
    }
  }, [location]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let c = moment(item.createdDate);
      let u = moment(item.updatedDate);
      newArray.push([
        item.appId,
        item.accountNames,
        item.userTypeName,
        item.firstname,
        item.lastname,
        item.username,
        {
          avatar: item.avatarExtension
            ? `\\dynamic\\user\\images\\` + item.appId + '.' + item.avatarExtension + '?v=' + moment().format('YYYMMDDss')
            : '\\dynamic\\user\\images\\' + item.appId,
        },
        // c.isValid() ? c.format('L') : '',
        // u.isValid() ? u.format('L') : '',
      ]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    if (userContext.isAdmin && userContext.permissions.canManageUsers) {
      setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
    } else {
      setErrorAlertOpen(true);
      setActionResult('failed');
      setAlertErrorMessage("you don't have permission to delete users");
    }
  };

  const handleDelete = (id) => {
    apiService
      .deleteUser(id)
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          var errorString = '';
          for (var i = 0; i < result.errors.length; i++) {
            if (result.errors[i].errorMessage) {
              errorString += ' ' + result.errors[i].errorMessage;
            } else errorString += ' ' + result.errors[i];
          }
          setSnackbar({
            open: true,
            isSuccess: false,
            message: errorString,
          });
        } else {
          setDummyCounter(dummyCounter + 1);
          setSnackbar({
            open: true,
            isSuccess: true,
            message: 'User deleted successfully',
          });
        }
      })
      .catch((err) => {
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const handleAdd = () => {
    if (userContext.isAdmin && userContext.permissions.canManageUsers) {
      history.push('/users/add');
    } else {
      setErrorAlertOpen(true);
      setActionResult('failed');
      setAlertErrorMessage("you don't have permission to add users");
    }
  };

  const handleEdit = (event) => {
    if (userContext.isAdmin && userContext.permissions.canManageUsers) {
      history.push('/users/' + event.currentTarget.dataset.tag + '/edit');
    } else {
      setErrorAlertOpen(true);
      setActionResult('failed');
      setAlertErrorMessage("you don't have permission to modify users");
    }
  };

  const headCells = [
    {
      id: 'AccountNames',
      numeric: false,
      disablePadding: true,
      label: 'Account',
    },
    {
      id: 'UserTypeName',
      numeric: false,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'Firstname',
      numeric: false,
      disablePadding: false,
      label: 'Firstname',
    },
    {
      id: 'Lastname',
      numeric: false,
      disablePadding: false,
      label: 'Lastname',
    },
    { id: 'Email', numeric: false, disablePadding: false, label: 'Email' },
    {
      id: 'AvatarExtension',
      numeric: false,
      disablePadding: false,
      label: 'Avatar',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    let snackbartemp = { ...snackbar };
    snackbartemp.open = false;
    setSnackbar(snackbartemp);
  };

  const handleFilterChanged = (filter) => {
    setFreeTextSearch(filter.freeText);
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert theme={theme} onClose={handleSnackbarClose} severity={snackbar.isSuccess ? 'success' : 'error'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <EntityListFrame inPaper={showFrameAsPaper}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {actionResult === 'success' ? (
              <Collapse in={successAlertOpen}>
                <Alert
                  variant="outlined"
                  severity="success"
                  onClose={() => {
                    setSuccessAlertOpen(false);
                  }}
                  className={classes.alert}
                >
                  The password was changed!
                </Alert>
              </Collapse>
            ) : actionResult === 'failed' ? (
              <Collapse in={errorAlertOpen}>
                <Alert
                  variant="outlined"
                  severity="error"
                  onClose={() => {
                    setErrorAlertOpen(false);
                  }}
                  className={classes.alert}
                >
                  {alertErrorMessage}
                </Alert>
              </Collapse>
            ) : (
              ''
            )}
          </Grid>
          {rows === '' ? (
            ''
          ) : (
            <Switch>
              <ProtectedRoute exact path={`${match.path}/add`}>
                <UserEntry action="add" history={history} />
              </ProtectedRoute>

              <ProtectedRoute exact path={`${match.path}/:id/edit`}>
                <UserEntry action="edit" history={history} />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}/:id/set-password`}>
                <SetUserPassword history={history} />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}`}>
                {/* <h4>Users</h4> */}
                <EntityTable
                  title="Users"
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  deleteHandler={handleDeleteRequest}
                  handleAdd={handleAdd}
                  handleEdit={handleEdit}
                  canAdd={userContext.isAdmin && userContext.permissions.canManageUsers}
                  handleFilterChanged={handleFilterChanged}
                />
              </ProtectedRoute>
              <Redirect to="/dashboard" />
              <ProtectedRoute />
            </Switch>
          )}
        </Grid>
        <DeleteDialog
          action={pendingDeleteAction}
          handleDelete={handleDelete}
          title="Delete User?"
          body="Are you sure you want to delete this user with all its data? Once deleted it cannot be undone."
        />
      </EntityListFrame>
    </>
  );
}

export default Users;
