import { createContext } from 'react';

export const MyFeaturesContext = createContext({
  enabledTrackAndTrace: false,
  enabledEDIReport: false,
  enabledTrendsOverTime: false,
  enabledCommercialInvoices: false,
  enabledSpendPerSuppliers: false,
  enabledProducts: false,
  enabledPurchaseOrder: false,
  enabledPurchaseOrderLineSpendsFeature: false,
  enabledPurchaseOrderLineMilestonesFeature: false,
});
