import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import CardIcon from '../../Card/CardIcon.js';
import CardFooter from '../../Card/CardFooter.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import AssessmentIcon from '@material-ui/icons/Assessment';
import helperService from '../../../services/help.service';
import TitleFont from '../../Typography/TitleFont.js';
import { Link } from 'react-router-dom';
import { format } from 'react-string-format';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
  },
  cardCategory: {
    color: 'rgba(0, 0, 0, 0.87)',
    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));

function TotalFCLTEUCard(props) {
  const classes = useStyles();
  const { FilterComponentData, baseQueryString } = props;
  const history = useHistory();
  const [totalFCLTLU, settotalFCLTLU] = useState(true);

  function getDetailsPageUrl() {
    return format('/shipments?mode=FCL&{0}&sst={1}', baseQueryString, 'Total FCL TEU');
  }

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      apiService
        .getTotalTeuReport(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            settotalFCLTLU(result.data.count);
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (!isCancelled) {
            settotalFCLTLU(0);
          }
          if (err.response && err.response.status === 401) history.push('/login');
        });
    } else settotalFCLTLU(0);

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  return (
    <Card>
      <CardHeader color="success" stats icon>
        <CardIcon color="success">
          <AssessmentIcon />
        </CardIcon>
        <TitleFont>
          <p className={classes.cardCategory}>
            <Link to={getDetailsPageUrl()}>Total FCL TEU</Link>
          </p>
        </TitleFont>
        <h3 className={classes.cardTitle}>{totalFCLTLU ? helperService.thousands_separators(totalFCLTLU) : '0'}</h3>
      </CardHeader>
      <CardFooter stats>
        <div style={{ height: '15px' }} className={classes.stats} />
      </CardFooter>
    </Card>
  );
}

export default TotalFCLTEUCard;
