import React, { useRef, useContext, useState, useEffect } from 'react';
import AdminDrawer from '../layouts/AdminDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { darkTheme } from '../../themes/darkTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightThemeAdmin } from '../../themes/lightTheme';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from '../../contexts/UserContext';
import { ReloadContext } from '../../contexts/ReloadContext';
import Container from '@material-ui/core/Container';
import TopRedBar from '../layouts/TopRedBar';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import Grid from '@material-ui/core/Grid';
import SearchBox from './TrackAndTrace/SearchBox';
import TabsContainer from './TrackAndTrace/TabsContainer';
import { Paper } from '@material-ui/core';
import Milestones from './TrackAndTrace/MileStones';
import Map from './TrackAndTrace/Map';
import apiService from '../../services/api.service';
import { useHistory } from 'react-router';
import { MyFeaturesContext } from '../../contexts/MyFeaturesContext';
import FeatureDisabledMessage from '../parts/FeatureDisabledMessage';
import SubscribeButton from './TrackAndTrace/SubscribeButton';
import FocusShipmentCheckbox from './TrackAndTrace/FocusShipmentCheckbox';
import FilledSnackbar from '../parts/FilledSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
    maxWidth: '1600px',
  },
  spacer: {
    flex: '1 1 auto',
  },
  pdfButton: {
    marginTop: '10px',
    maxHeight: '200px',
    minWidth: '200px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  mapAndMilestonePaper: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 590,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  subscribeButton: {
    padding: theme.spacing(0.5),
    position: 'absolute',
    right: 0,
  },
  focusCheckbox: {
    padding: theme.spacing(0.5),
    position: 'absolute',
    right: 0,
    top: 40
  }
}));

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

function TrackAndTrace(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();
  const progressRef = useRef(null);
  const [userContext] = useContext(UserContext);
  const [reloadContext] = useContext(ReloadContext);
  const [myFeaturesContext, setMyFeaturesContext] = useContext(MyFeaturesContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shipmentTrackingId, setShipmentTrackingId] = useState();
  const [shipmentTrackingModel, setShipmentTrackingModel] = useState();
  const history = useHistory();

  const handleSelectedShipmentTrackingId = (shipmentTrackingId) => {
    setShipmentTrackingId(shipmentTrackingId);
  };

  useEffect(() => {
    let isCancelled = false;

    if (shipmentTrackingId) {
      setIsLoading(true);
      apiService
        .getShipmentTrackingById(shipmentTrackingId)
        .then((result) => {
          if (!isCancelled) {
            console.log("result.data", result.data);
            setShipmentTrackingModel(result.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [shipmentTrackingId]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    success: false,
  });

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <FilledSnackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          isSuccess={snackbar.success}
          message={snackbar.msg}
        />
        <CssBaseline />
        <div className={classes.root}>
          <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
          <AdminDrawer />
          <main className={classes.content}>
            <TopRedBar />
            <Container maxWidth="lg" className={classes.container}>
              {myFeaturesContext &&
                (myFeaturesContext.enabledTrackAndTrace ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <SearchBox
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        handleSelectedShipmentTrackingId={handleSelectedShipmentTrackingId}
                      ></SearchBox>
                    </Grid>

                    {shipmentTrackingModel ? (
                      <>
                        <Grid item xs={12} md={6}>
                          <TabsContainer shipmentTrackingModel={shipmentTrackingModel} isLoading={isLoading}></TabsContainer>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Paper className={classes.mapAndMilestonePaper}>
                            <Grid container>
                              <Grid item xs>
                                <Milestones shipmentTrackingModel={shipmentTrackingModel}></Milestones>
                              </Grid>
                            </Grid>
                            <Map shipmentTrackingModel={shipmentTrackingModel}></Map>
                            <SubscribeButton shipmentTrackingModel={shipmentTrackingModel} className={classes.subscribeButton} />
                            <FocusShipmentCheckbox shipmentNumber={shipmentTrackingModel.shipmentNumber} className={classes.focusCheckbox} setSnackbar={setSnackbar} />
                          </Paper>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12}></Grid>
                    )}
                  </Grid>
                ) : (
                  <FeatureDisabledMessage />
                ))}
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default TrackAndTrace;
