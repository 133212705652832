import React, { useState, useRef, useContext } from 'react';
// import AdminDrawer from '../layouts/AdminDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { darkTheme } from '../../../themes/darkTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import LoadingBar from 'react-top-loading-bar';
import { Grid, Paper, CircularProgress, Backdrop, Container } from '@material-ui/core';
import SupplierDrawer from '../../layouts/SupplierDrawer';
import TopRedBar from '../../layouts/TopRedBar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& h6': {
            fontWeight: 600,
        },
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflowY: 'auto',
    },
    container: {
        paddingBottom: theme.spacing(4),
        maxWidth: '1600px',
    },
    spacer: {
        flex: '1 1 auto',
    },
    pdfButton: {
        marginTop: '10px',
        maxHeight: '200px',
        minWidth: '200px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    paper: {
        width: '100%',
        padding: '50px',
        marginBottom: theme.spacing(2),
    },
}));

function SupplierEntityListFrame(props) {
    let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
    const classes = useStyles();
    const progressRef = useRef(null);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Backdrop className={classes.backdrop} open={openBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CssBaseline />
                <div className={classes.root}>
                    <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
                    <SupplierDrawer />
                    <main className={classes.content}>
                        <TopRedBar />
                        <Container maxWidth="lg" className={classes.container}>
                            <Paper elevation={12} className={classes.paper}>
                                <Grid container spacing={3}>
                                    {props.children}
                                </Grid>
                            </Paper>
                        </Container>
                    </main>
                </div>
            </ThemeProvider>
        </>
    );
}

export default SupplierEntityListFrame;
