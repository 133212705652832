import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  featureDisabledMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function FeatureDisabledMessage(props) {
  const { title = 'Sorry.', message = 'This feature is not enabled for you. Please contact an administrator.' } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.featureDisabledMessage}>
        <h4>{title}</h4>
      </Grid>
      <Grid item xs={12} className={classes.featureDisabledMessage}>
        {message}
      </Grid>
    </Grid>
  );
}

FeatureDisabledMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};
