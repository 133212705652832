import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

function FilledSnackbar(props) {
    const { open, autoHideDuration, onClose, isSuccess, message } = props;
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
            <Alert onClose={onClose} variant="filled" severity={isSuccess ? "success" : "error"}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default FilledSnackbar;