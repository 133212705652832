import React, { useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import { useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ThemeProvider } from '@material-ui/styles';
import { lightTheme } from '../../../themes/lightTheme';
import '../../../util/extensions';
import ContentFont from '../../Typography/ContentFont';
import ChartLogo from '../../parts/ChartLogo';
import ChartNoData from '../../parts/ChartNoData.js';
import LoadingOverlay from '../../parts/LoadingOverlay';
import { Link } from 'react-router-dom';
import { format } from 'react-string-format';

const useStyles = makeStyles((theme) => ({
  chart: {
    position: 'relative',
    height: '500px',
    marginTop: '20px',
    marginBottom: '0px',
  },
}));

function TEUPerPortPair(props) {
  const classes = useStyles();
  const [chartDataInput, setChartDataInput] = useState([]);
  const { FilterComponentData, baseQueryString } = props;
  const [scaleSet, setScaleSet] = useState(1);
  const [dataLength, setDataLength] = useState(0);
  const labelLimit = 3;
  const scaleWidth = 20;
  const [showNoData, setShowNoData] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);

  const history = useHistory();

  function hasChartData() {
    if (chartDataInput && chartDataInput.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function getDetailsPageUrl(origin, destination, originDestinationFullName) {
    if (origin && destination) {
      return format('/shipments?pOri={0}&pDest={1}&{2}&sst={3}', origin, destination, baseQueryString, originDestinationFullName);
    } else {
      return '/shipments?' + baseQueryString;
    }
  }

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      setIsLoading(true);
      apiService
        .getTuePerPortPairReport(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            if (result.data.teuPerPortPairReportItems != '' && result.data.teuPerPortPairReportItems.length > 0) {
              setDataLength(result.data.teuPerPortPairReportItems.length);
              if (result.data.teuPerPortPairReportItems.length > scaleWidth) {
                setScaleSet((scaleWidth / result.data.teuPerPortPairReportItems.length).toFixed(1));
              } else {
                setScaleSet(1);
              }
              setShowNoData(false);
            } else {
              setDataLength(0);
              setScaleSet(1);
              setShowNoData(true);
            }

            setChartDataInput(result.data.teuPerPortPairReportItems);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  useLayoutEffect(() => {
    var chart;
    if (hasChartData()) {
      var colorList = [];
      for (var i = 0; i < lightTheme.chart.colors.length; i++) {
        colorList.push(am4core.color(lightTheme.chart.colors[i]));
      }

      chart = am4core.create('SeriesTEUPERPORTPAIR', am4charts.XYChart);
      chart.logo.disabled = true;
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.visible = scaleSet < 1;
      chart.scrollbarX.end = scaleSet;

      chart.colors.list = colorList;
      chart.data = [chartDataInput];
      chart.data = prepareStuff(chartDataInput);

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'pathshipping';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 15;
      if (dataLength > labelLimit) {
        categoryAxis.renderer.labels.template.horizontalCenter = 'right';
        categoryAxis.renderer.labels.template.verticalCenter = 'middle';
        categoryAxis.fontSize = 11;
      } else categoryAxis.fontSize = 14;

      categoryAxis.renderer.labels.template.rotation = dataLength < labelLimit ? 0 : 315;
      categoryAxis.renderer.minHeight = 110;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = 'teu';
      series.dataFields.categoryX = 'pathshipping';
      series.tooltipText = '{pathshippingFullName} : [bold]{valueY}[/]';
      series.columns.template.strokeWidth = 0;
      series.columns.template.url = getDetailsPageUrl('{origin}', '{destination}', '{pathshippingFullName}');

      series.tooltip.pointerOrientation = 'vertical';
      series.columns.template.column.fillOpacity = 0.8;

      let hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 5;
      hoverState.properties.cornerRadiusTopRight = 5;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      chart.cursor = new am4charts.XYCursor();
    }
    return () => {
      if (chart) chart.dispose();
    };
  }, [chartDataInput, showNoData]);

  const prepareStuff = (data) => {
    var tempArray = [];
    data.forEach((item) => {
      let itemObj = {
        pathshipping: item.origin + '-' + item.destination, // item.supplier,
        pathshippingFullName: item.originFullName + ' - ' + item.destinationFullName,
        teu: item.teu,
        origin: item.origin,
        destination: item.destination,
      };
      tempArray.push(itemObj);
    });
    return tempArray;
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Card chart>
        <LoadingOverlay IsLoading={IsLoading} />
        <CardHeader className={'dashboard-chart-area'}>
          <ContentFont>{showNoData ? <ChartNoData /> : <div id="SeriesTEUPERPORTPAIR" className={classes.chart}></div>}</ContentFont>
        </CardHeader>
        <CardBody className={'dashboard-chart-title-area'}>
          <Link to={getDetailsPageUrl()}>
            <h4>TEU Per Port-Pair</h4>
          </Link>
          <ChartLogo />
        </CardBody>
      </Card>
    </ThemeProvider>
  );
}

export default TEUPerPortPair;
