import { Box, makeStyles, Paper, Tab, Tabs, withStyles, withWidth } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShipmentDetails from './ShipmentDetails';
import Messages from './Messages';
import Documents from './Documents';
import { MyTab, MyTabs, TabPanel } from '../../parts/MyTabs';
import TrackingEvents from './TrackingEvents';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  fixHeightTabContent: {
    height: 493,
  },
  lastUpdated: {
    position: 'absolute',
    color: '#666',
    fontSize: '0.75rem',
    bottom: 5,
    right: 5,
  },
}));

function TabsContainer(props) {
  const { width, shipmentTrackingModel } = props;
  const [value, setValue] = React.useState(0);
  const [lastUpdatedDateText, setLastUpdatedDateText] = useState('');
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabContentClassName = () => {
    if (width === 'md' || width === 'lg' || width === 'xl') {
      return classes.fixHeightTabContent;
    } else {
      return '';
    }
  };

  useEffect(() => {
    setValue(0);
    let lastUpdatedText = getDateTimeText(shipmentTrackingModel.lastUpdatedDate);
    setLastUpdatedDateText(lastUpdatedText);
  }, [shipmentTrackingModel]);


  function getDateTimeText(dateTimeValue) {
    if (dateTimeValue) {
      var momentTime = moment(dateTimeValue);

      if (momentTime.isValid()) {
        return momentTime.format('L');
      }
    }
    return dateTimeValue;
  }

  return (
    <div className={classes.root}>
      <MyTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
        <MyTab label="Shipment Details" key='shipmentDetailsTab' />
        {/* <MyTab label="Messages" key='messagesTab' /> */}
        <MyTab label="Tracking Events" key='trackingEventsTab' />
        <MyTab label="Documents" key='documentsTab' />
      </MyTabs>
      <TabPanel value={value} index={0} key='shipmentDetailsTabPanel'>
        <ShipmentDetails key='shipmentDetails' className={getTabContentClassName()} shipmentTrackingModel={shipmentTrackingModel} />
      </TabPanel>
      {/* <TabPanel value={value} index={1} key='messagesTabPanel'>
        <Messages className={getTabContentClassName()} shipmentTrackingModel={shipmentTrackingModel} />
      </TabPanel> */}
      <TabPanel value={value} index={1} key='trackingEventsTabPanel'>
        <TrackingEvents className={getTabContentClassName()} shipmentTrackingModel={shipmentTrackingModel} />
      </TabPanel>
      <TabPanel value={value} index={2} key='documentsTabPanel'>
        <Documents className={getTabContentClassName()} shipmentTrackingModel={shipmentTrackingModel} />
      </TabPanel>
      <Box className={classes.lastUpdated}>Last updated: {moment(lastUpdatedDateText, "DDMMYYYY").fromNow() + ' ' + `(${lastUpdatedDateText})`}</Box>
    </div>
  );
}

TabsContainer.propTypes = {
  width: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']).isRequired,
};

export default withWidth()(TabsContainer);
