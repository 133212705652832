import React, { useState, useRef, useContext, useEffect } from 'react';
import AdminDrawer from '../layouts/AdminDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { darkTheme } from '../../themes/darkTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightThemeAdmin } from '../../themes/lightTheme';
import LoadingBar from 'react-top-loading-bar';
import Container from '@material-ui/core/Container';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer.js';
import TopRedBar from '../layouts/TopRedBar';
import PDFModule from '../../services/pdf.service';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { MyFeaturesContext } from '../../contexts/MyFeaturesContext';
import FeatureDisabledMessage from '../parts/FeatureDisabledMessage';
import TabsContainer from './Trends/TabsContainer';
import apiService from '../../services/api.service';
import TrendsFilters from './Trends/FilterControls/TrendsFilters';

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: (props) => props.adminTheme.palette.trendsOverTime.mainBackgroundColor,
  },
  container: {
    paddingBottom: theme.spacing(4),
    maxWidth: '1600px',
  },
  pdfButton: {
    marginTop: '10px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function Trends(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const themeProps = { adminTheme: theme };
  const classes = useStyles(themeProps);
  const progressRef = useRef(null);
  const [filterComponentData, setFilterComponentData] = useState(true);
  const [myFeaturesContext] = useContext(MyFeaturesContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [baseQueryString, setBaseQueryString] = React.useState();
  const [accountId, setAccountId] = useState();
  const [trendOverTimeSettings, setTrendOverTimeSettings] = useState();


  const generatePdf = () => {
    setOpenBackdrop(true);
    am4core.unuseTheme(am4themes_animated);
    const element = document.querySelector('#dashboardPrint');
    const options = {
      filename: 'Trends-' + new Date().toDateString() + '.pdf',
      excludeClassNames: ['exclude'],
      company: filterComponentData.companyName,
      period: filterComponentData.periodName,
      suppliers: filterComponentData.suppliers ? filterComponentData.supplierName : 'ALL',
    };

    return PDFModule(element, options, () => {
      am4core.useTheme(am4themes_animated);
      setOpenBackdrop(false);
    });
  };
  
  useEffect(() => {
    if (filterComponentData && filterComponentData.accountId) {
      setAccountId(filterComponentData.accountId);
    }
  }, [filterComponentData]);
  
  useEffect(() => {
    const loadSettings = async () => {
      let result = await apiService.getTrendOverTimeSettings(accountId);
      setTrendOverTimeSettings(result.data);
    };

    setTrendOverTimeSettings(undefined);
    if (accountId) {
      loadSettings();
    }
  }, [accountId]);
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <div className={classes.root}>
          <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
          <AdminDrawer />
          <main className={classes.content}>
            <TopRedBar />
            <Container id="dashboardPrint" maxWidth="lg" className={classes.container}>
              {myFeaturesContext &&
                (myFeaturesContext.enabledTrendsOverTime ? (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <TrendsFilters
                          sendFilterComponentData={setFilterComponentData}
                          sendQueryStrings={setBaseQueryString}
                          onClickGeneratePdf={generatePdf}
                        />
                      </GridItem>
                    </GridContainer>
                    <TabsContainer filterComponentData={filterComponentData} baseQueryString={baseQueryString} trendOverTimeSettings={trendOverTimeSettings}></TabsContainer>
                  </>
                ) : (
                  <FeatureDisabledMessage />
                ))}
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Trends;
