import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import { ThemeProvider } from '@material-ui/styles';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import EntityListFrame from '../../layouts/EntityListFrame';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { CSVLink } from 'react-csv';
import ExportMenu from '../../../wrappers/ExportMenu';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import FeatureDisabledMessage from '../../parts/FeatureDisabledMessage';
import moment from 'moment';
import tableService from '../../../services/table.service';
import ColumnSelector from '../../parts/ColumnSelector';
import PurchaseOrderDataTableFilter from '../PurchaseOrder/PurchaseOrderDataTableFilter';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  arrivedOnTime: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.onTime,
    color: 'white',
  },
  arrivedLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.late,
    color: 'white',
  },
  arrivedVeryLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.veryLate,
    color: 'white',
  },
}));

function PurchaseOrderLineSpendsDataTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [baseQueryString, setBaseQueryString] = React.useState();
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [myFeaturesContext] = useContext(MyFeaturesContext);

  const progressRef = useRef(null);
  const history = useHistory();

  const defaultHeadCells = [
    {
      id: 'OrderNumber',
      numeric: false,
      disablePadding: true,
      label: 'Order No.',
    },
    {
      id: 'LineId',
      numeric: true,
      disablePadding: false,
      label: 'Line No.',
    },
    {
      id: 'OrderNumberSplit',
      numeric: true,
      disablePadding: false,
      label: 'Split No.',
    },
    {
      id: 'Code',
      numeric: false,
      disablePadding: false,
      label: 'Code',
    },
    {
      id: 'UnitPrice',
      numeric: true,
      disablePadding: false,
      label: 'Unit Price($)',
    },
    {
      id: 'QtyMet',
      numeric: true,
      disablePadding: false,
      label: 'Qty Receive',
    },
    {
      id: 'RemainingQty',
      numeric: true,
      disablePadding: false,
      label: 'Remaining Qty',
    },
    {
      id: 'CommercialValue',
      numeric: true,
      disablePadding: false,
      label: 'Shipped Commercial Value($)',
    },
    {
      id: 'RemainingCommercialValue',
      numeric: true,
      disablePadding: false,
      label: 'Remaining Commercial Value($)',
    },
    {
      id: 'FreightSpendPerUnit',
      numeric: true,
      disablePadding: false,
      label: 'Freight per Unit($)',
    },
    {
      id: 'FreightSpendPerLine',
      numeric: true,
      disablePadding: false,
      label: 'Freight per Line($)',
      tooltip: 'Freight per Unit x Qty Receive',
    },
    {
      id: 'TotalSpendsPerLine',
      numeric: true,
      disablePadding: false,
      label: 'Total Spends per Line($)',
      tooltip: 'Freight per Line + Shipped Commercial Value',
    },
  ];

  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getPurchaseOrderLineSpendsTableReport(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers,
        data.buyerId
      )
      .then((result) => {
        console.log("result", result);
        let contentRows = createTableListData(result.data.items);
        let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
        // console.log(filteredTable.headCells);
        setHeadCells(filteredTable.headCells);
        setRows(filteredTable.contentRows);
        setTotalNumberOfRows(result.data.count);
        setHiddenColumnNames(result.data.hiddenColumnNames);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item, index) => {

      let unitPrice = '';
      if (item.unitPrice) {
        if (item.unitQty > 1) {
          unitPrice = `$${item.unitPrice} per ${item.unitQty}${item.unitUOM ?? ''}`;
        } else {
          unitPrice = `$${item.unitPrice} per ${item.unitUOM ?? ''}`;
        }
      }

      var shipmentNumber = { component: 'linkList', links: [] };
      if (item.shipmentNumber) {
        shipmentNumber.links.push({
          text: item.shipmentNumber,
          path: '/shipments?s=' + item.shipmentNumber,
        });
      } else {
        shipmentNumber = '';
      }
      let rowData = {
        id: index,
        OrderNumber: item.orderNumber,
        LineId: item.lineId,
        OrderNumberSplit: item.orderNumberSplit,
        Code: item.code,
        UnitPrice: unitPrice,
        QtyMet: item.qtyMet ? item.qtyMet.toLocaleString() : '0',
        RemainingQty: item.remainingQty ? item.remainingQty.toLocaleString() : '0',
        CommercialValue: item.commercialValue ? item.commercialValue.toLocaleString() : '0',
        RemainingCommercialValue: item.remainingCommercialValue ? item.remainingCommercialValue.toLocaleString() : '0',
        FreightSpendPerUnit: item.freightSpendPerUnit ? item.freightSpendPerUnit.toLocaleString(undefined, { maximumFractionDigits: 2, }) : '',
        FreightSpendPerLine: item.freightSpendPerLine ? item.freightSpendPerLine.toLocaleString(undefined, { maximumFractionDigits: 2, }) : '',
        TotalSpendsPerLine: item.totalSpendsPerLine ? item.totalSpendsPerLine.toLocaleString(undefined, { maximumFractionDigits: 2, }) : '',
      };

      newArray.push(rowData);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderLineSpendsTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generatePurhcaseOrderEDILineSpendsPDF(
            result.data.items,
            'Purchase Orders Line Spends     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Purchase Orders Line Spends Report',
            result.data.hiddenColumnNames
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderLineSpendsTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generatePurchaseOrderLineSpendsCsvData(
            result.data.items,
            filter.accountName + '_' + filter.supplierName + '_' + filter.periodName,
            result.data.hiddenColumnNames
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleColumnSelected = (headCells) => {
    setNewHeadCells(headCells);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
        <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
        <EntityListFrame>
          {myFeaturesContext &&
            (myFeaturesContext.enabledPurchaseOrder ? (
              <>
                <Grid container className={classes.titleArea}>
                  <Grid item xs={12} sm className="align-self-center">
                    <h4>Order Lines Spends</h4>
                  </Grid>
                  <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                    <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
                  </Grid>
                  <Grid item xs={12} sm className={'align-self-center ' + classes.ExportMenuContainer}>
                    <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <PurchaseOrderDataTableFilter
                    sendFilterComponentData={filterCallback}
                    sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                    showSuppliers={true}
                    freeTextFilterOn='Order Number, Code and Status.'
                  />
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  {rows && (
                    <DataContentTable
                      title=""
                      headCells={newHeadCells}
                      totalNumberOfRows={totalNumberOfRows}
                      rows={rows}
                      page={page}
                      handleRequestSort={handleRequestSort}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </Grid>
              </>
            ) : (
              <FeatureDisabledMessage />
            ))}
        </EntityListFrame>
      </ThemeProvider>
    </>
  );
}

export default withRouter(PurchaseOrderLineSpendsDataTable);
