import React, { useLayoutEffect, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import { useState } from 'react';
import helpService from '../../../services/help.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ThemeProvider } from '@material-ui/styles';
import { lightTheme } from '../../../themes/lightTheme';
import '../../../util/extensions';
import ContentFont from '../../Typography/ContentFont';
import ChartLogo from '../../parts/ChartLogo';
import LoadingOverlay from '../../parts/LoadingOverlay';
import { Link } from 'react-router-dom';
import { format } from 'react-string-format';
import ChartNoData from '../../parts/ChartNoData.js';

const useStyles = makeStyles((theme) => ({
  chart: {
    position: 'relative',
    height: '500px',
    marginTop: '20px',
    marginBottom: '0px',
  },
}));

function AirFreightKGSPerSupplierChart(props) {
  const classes = useStyles();
  const [chartDataInput, setChartDataInput] = useState([]);
  const { FilterComponentData, baseQueryString } = props;
  const [scaleSet, setScaleSet] = useState(1);
  const [dataLength, setDataLength] = useState(0);
  const labelLimit = 5;
  const scaleWidth = 40;
  const [showNoData, setShowNoData] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);

  const history = useHistory();

  function hasChartData() {
    if (chartDataInput && chartDataInput.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function getDetailsPageUrl(supplierfullname) {
    if (supplierfullname) {
      return format('/shipments?trans=AIR&s={0}&{1}&sst={2}', supplierfullname, baseQueryString, 'Air Freight');
    } else {
      return format('/shipments?trans=AIR&{0}&sst={1}', baseQueryString, 'Air Freight');
    }
  }

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      setIsLoading(true);
      apiService
        .getAirFreightKgsPerSupplier(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            if (result.data.airFreightKGSPerSupplierReportItems != '' && result.data.airFreightKGSPerSupplierReportItems.length > 0) {
              setDataLength(result.data.airFreightKGSPerSupplierReportItems.length);
              if (result.data.airFreightKGSPerSupplierReportItems.length > scaleWidth) {
                setScaleSet((scaleWidth / result.data.airFreightKGSPerSupplierReportItems.length).toFixed(1));
              } else {
                setScaleSet(1);
              }
              setShowNoData(false);
            } else {
              setDataLength(0);
              setScaleSet(1);
              setShowNoData(true);
            }

            setChartDataInput(result.data.airFreightKGSPerSupplierReportItems);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  useLayoutEffect(() => {
    var chart;
    if (hasChartData()) {
      var colorList = [];
      for (var i = 0; i < lightTheme.chart.colors.length; i++) {
        colorList.push(am4core.color(lightTheme.chart.colors[i]));
      }

      chart = am4core.create('AirFreightKGSPeriodSupplierChart', am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.visible = scaleSet < 1;
      chart.scrollbarX.end = scaleSet;

      chart.colors.list = colorList;

      chart.data = prepareStuff(chartDataInput);
      chart.logo.disabled = true;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.title.numberFormatter.numberFormat = '#,###.## Kg';
      categoryAxis.dataFields.category = 'supplier';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      if (dataLength > labelLimit) {
        categoryAxis.renderer.labels.template.horizontalCenter = 'right';
        categoryAxis.renderer.labels.template.verticalCenter = 'middle';
        categoryAxis.fontSize = 11;
      } else categoryAxis.fontSize = 14;

      categoryAxis.renderer.labels.template.rotation = dataLength < labelLimit ? 0 : 315;

      categoryAxis.renderer.minHeight = 110;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = 'weight';
      series.dataFields.categoryX = 'supplier';
      series.tooltipText = '{supplierfullname}: [bold]{valueY}[/]';
      series.columns.template.strokeWidth = 0;
      series.tooltip.pointerOrientation = 'vertical';
      series.columns.template.column.fillOpacity = 0.8;
      series.columns.template.url = getDetailsPageUrl('{supplierfullname}');

      // on hover, make corner radiuses bigger
      let hoverState = series.columns.template.column.states.create('hover');
      hoverState.properties.cornerRadiusTopLeft = 5;
      hoverState.properties.cornerRadiusTopRight = 5;
      hoverState.properties.fillOpacity = 1;

      series.columns.template.adapter.add('fill', function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });

      // Cursor
      chart.cursor = new am4charts.XYCursor();
    }
    return () => {
      if (chart) chart.dispose();
    };
  }, [chartDataInput, showNoData]);

  const prepareStuff = (data) => {
    var tempArray = [];
    data.forEach((item) => {
      let itemObj = {
        supplier: dataLength < labelLimit ? item.supplier : helpService.truncateString(item.supplier, 15),
        supplierfullname: item.supplier, // item.supplier,
        weight: item.weight,
      };
      tempArray.push(itemObj);
    });

    return tempArray;
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Card chart>
        <LoadingOverlay IsLoading={IsLoading} />
        <CardHeader className={'dashboard-chart-area'}>
          <ContentFont>{showNoData ? <ChartNoData /> : <div id="AirFreightKGSPeriodSupplierChart" className={classes.chart}></div>}</ContentFont>
        </CardHeader>
        <CardBody className={'dashboard-chart-title-area'}>
          <Link to={getDetailsPageUrl()}>
            <h4>Air Freight KGS Per Supplier</h4>
          </Link>
          <ChartLogo />
        </CardBody>
      </Card>
    </ThemeProvider>
  );
}

export default AirFreightKGSPerSupplierChart;
