import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../wrappers/Button';

function DialogRegenerateKey(props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const { onConfirm, action, title, body } = props;
  useEffect(() => {
    if (action && action.show) {
      setOpenDialog(true);
    }
  }, [action]);

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm(action);
    setOpenDialog(false);
  };
  return (
    <Dialog open={openDialog} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button theme={parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark'} onClick={handleConfirm}>
          Yes, please
        </Button>
        <Button theme={parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark'} isSecondary="true" onClick={handleCancel}>
          No, take me back
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogRegenerateKey;
