import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { format } from 'react-string-format';

const useMessagesItemStyles = makeStyles((theme) => ({
  messageRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  message: {
    fontSize: '0.875rem',
  },
  date: {
    color: theme.palette.shipmentTracking.shipmentDetails.titleColor,
    fontSize: '0.75rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hr: {
    marginBottom: 0,
    marginTop: 0,
  },
}));

function getDateTimeText(dateTimeValue, showTime) {
  if (dateTimeValue) {
    var momentTime = moment(dateTimeValue);

    if (showTime) {
      return momentTime.format('l LT');
    } else {
      return momentTime.format('l');
    }
  } else {
    return '';
  }
}

function MessagesItem(props) {
  const classes = useMessagesItemStyles();
  const { model, index } = props;

  function getMessage(model) {
    return format(model.messageTemplate, getDateTimeText(model.eventDate, true), getDateTimeText(model.eventDate2, true));
  }

  return (
    <Grid container item xs={12} className={'p-1'}>
      <Grid container item xs={12} className={classes.messageRow}>
        <Grid item xs={9} className={classes.message}>
          {getMessage(model)}
        </Grid>
        <Grid item xs={3} className={classes.date}>
          {getDateTimeText(model.createdDate, true)}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr className={classes.hr} />
      </Grid>
    </Grid>
  );
}

const useMessagesStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  scrollableList: {
    overflowY: 'auto',
  },
  emptyMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
}));

export default function Messages(props) {
  const { shipmentTrackingModel } = props;
  const classes = useMessagesStyles();

  return (
    <Box className={clsx(props.className, classes.root)}>
      <Grid container className={classes.scrollableList}>
        {shipmentTrackingModel &&
          (shipmentTrackingModel.messages && shipmentTrackingModel.messages.length > 0 ? (
            shipmentTrackingModel.messages.map((item, index) => {
              return <MessagesItem key={'messageItem' + index} model={item} index={index} />;
            })
          ) : (
            <Grid item xs={12} className={classes.emptyMessage}>
              There is no message on this shipment yet.
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
