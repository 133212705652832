import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../wrappers/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { UserContext } from '../../../contexts/UserContext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useEndpointListItemStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.palette.externalEndpointListItem.backgroundColor,
  },
  requestMethod: {
    fontWeight: 'bold',
    width: 80,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    justifyContent: 'center',
    height: '100%',
    color: '#FAFAFA',
  },
  post: {
    backgroundColor: '#49CC90',
  },
  get: {
    backgroundColor: '#61AFFE',
  },
  patch: {
    backgroundColor: '#50E3C2',
  },
  delete: {
    backgroundColor: '#F93E3E',
  },
  put: {
    backgroundColor: '#FCA130',
  },
  endpointNameBox: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  endpointName: {
    paddingRight: theme.spacing(1),
  },
  endpointInfoButton: {
    padding: 1,
  },
  tag: {
    position: 'relative',
    whiteSpace: 'nowrap',
    padding: 5,
    paddingLeft: 30,
    paddingRight: 12,
    backgroundColor: 'white',
    color: 'black',
    border: 'green solid 1px',
    borderRadius: 16,
    margin: 2,
    '& svg': {
      position: 'absolute',
      left: 5,
      top: 6,
      fontSize: '1.2rem',
      color: 'green',
      backgroundImage: 'linear-gradient(#fff,#fff)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50% 50%',
      backgroundPosition: 'center',
    },
  },
}));

function EndpointListItem(props) {
  const { endpoint, onEndpointInfoClick, onEndpointCheckedChange, canManage } = props;
  const classes = useEndpointListItemStyles();

  const getHttpMethodClassName = () => {
    if (endpoint.requestMethod === 'GET') {
      return classes.get;
    } else if (endpoint.requestMethod === 'POST') {
      return classes.post;
    } else if (endpoint.requestMethod === 'PATCH') {
      return classes.patch;
    } else if (endpoint.requestMethod === 'DELETE') {
      return classes.delete;
    } else if (endpoint.requestMethod === 'PUT') {
      return classes.put;
    }
  };

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" className={classes.paper}>
        <Box className={clsx(classes.requestMethod, getHttpMethodClassName())}>{endpoint.requestMethod}</Box>
        <Box className={classes.endpointNameBox}>
          <span className={classes.endpointName}>{endpoint.name}</span>
          <IconButton className={classes.endpointInfoButton} aria-label="Endpoint Info Button" onClick={() => onEndpointInfoClick(endpoint.endpointId)}>
            <HelpOutlineIcon size="small" className={classes.buttonIcon} />
          </IconButton>
        </Box>
        {endpoint.enabledSupplierFilter && (
          <div className={classes.tag}>
            <CheckCircleIcon /> Supplier Filter
          </div>
        )}
        <Checkbox
          checked={endpoint.enabled}
          disabled={!canManage}
          color="primary"
          inputProps={{ 'aria-label': `${endpoint.name} checkbox` }}
          onChange={() => onEndpointCheckedChange(endpoint.endpointId)}
        />
      </Paper>
    </Grid>
  );
}

const useEndpointListStyles = makeStyles((theme) => ({
  dialogContentTitle: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 'bold',
  },
  dialogContentContent: {
    whiteSpace: 'pre-line',
  },
}));

export default function ExternalEndpointList(props) {
  const classes = useEndpointListStyles();
  const { endpoints, onEndpointsSelected } = props;

  const [selectedEndpointOnDialog, setSelectedEndpointOnDialog] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const descriptionElementRef = useRef(null);
  const [userContext] = useContext(UserContext);

  useEffect(() => {
    if (openDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openDialog]);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleEndpointInfoClick = (endpointId) => {
    let selectedEndpoint = endpoints.find((x) => x.endpointId === endpointId);
    console.log(endpointId, selectedEndpoint);
    setSelectedEndpointOnDialog(selectedEndpoint);
    setOpenDialog(true);
  };

  const handleEndpointCheckedChange = (endpointId) => {
    let selectedEndpoint = endpoints.find((x) => x.endpointId === endpointId);
    selectedEndpoint.enabled = !selectedEndpoint.enabled;

    let enabledEndpointIds = endpoints.filter((x) => x.enabled).map((x) => x.endpointId);
    if (onEndpointsSelected) {
      console.log(enabledEndpointIds);
      onEndpointsSelected(enabledEndpointIds);
    }
  };

  const canManage = () => {
    return userContext && userContext.permissions && userContext.permissions.canManageExternalApplications;
  };

  return (
    <>
      {endpoints && endpoints.length > 0 && (
        <>
          <Grid container direction="column" spacing={2}>
            <Grid container item xs={12}>
              <strong>Endpoints:</strong>
            </Grid>
            {endpoints.map((endpoint, index) => {
              return (
                <EndpointListItem
                  key={'EndpointListItem' + index}
                  endpoint={endpoint}
                  onEndpointInfoClick={handleEndpointInfoClick}
                  onEndpointCheckedChange={handleEndpointCheckedChange}
                  canManage={canManage()}
                />
              );
            })}
          </Grid>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            fullWidth={true}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            {selectedEndpointOnDialog && (
              <>
                <DialogTitle id="scroll-dialog-title">Endpoint: {selectedEndpointOnDialog.name}</DialogTitle>
                <DialogContent dividers={true}>
                  <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                    <p className={classes.dialogContentTitle}>Description:</p>
                    <p className={classes.dialogContentContent}>{selectedEndpointOnDialog.description}</p>
                    <p className={classes.dialogContentTitle}>URL:</p>
                    <p className={classes.dialogContentContent}>{selectedEndpointOnDialog.url}</p>
                    <p className={classes.dialogContentTitle}>Parameters:</p>
                    <p className={classes.dialogContentContent}>{selectedEndpointOnDialog.parameters}</p>
                    <p className={classes.dialogContentTitle}>Result:</p>
                    <p className={classes.dialogContentContent}>{selectedEndpointOnDialog.result}</p>
                  </DialogContentText>
                </DialogContent>
              </>
            )}

            <DialogActions>
              <Button onClick={handleCloseDialog} isSecondary="true">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
