import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router';
import InputBase from '@material-ui/core/InputBase';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Button from '../../../wrappers/Button';
import apiService from '../../../services/api.service';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import getSymbolFromCurrency from 'currency-symbol-map';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  currencyConverter: {
    padding: theme.spacing(2),
  },
  inputTitle: {
    minHeight: 32,
    alignItems: 'center',
  },
  inputControl: {
    width: '100%',
    '& input': {
      padding: theme.spacing(1),
    },
  },
  exchangeIcon: {
    backgroundColor: theme.palette.primaryButton.backgroundColor,
    color: theme.palette.primaryButton.fontColor,
    borderRadius: '50%',
    width: 36,
    height: 36,
    padding: 5,
    marginTop: 36,
  },
  amountTextfield: {
    backgroundColor: theme.palette.currencyConverter.amountTextBox.backgroundColor,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  convertedAmountTextfield: {
    backgroundColor: theme.palette.currencyConverter.convertedAmountTextBox.backgroundColor,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  convertButtonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    flexGrow: 1,
  },
  convertButton: {
    marginTop: -12,
  },
}));

const CustomInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: theme.spacing(1),
    paddingRight: '26px',
    border: '1px solid #c1c1c1',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  },
}))(InputBase);

function NumberFormatCustom(props) {
  const { inputRef, onChange, prefixSymbol, ...other } = props;

  useEffect(() => {
    console.log('prefixSymbol', prefixSymbol);
  }, [prefixSymbol]);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix={prefixSymbol}
    />
  );
}

function CurrencyConverter(props) {
  const classes = useStyles();
  const history = useHistory();

  const [selectedFromCurrency, setSelectedFromCurrency] = useState('');
  const [selectedFromCurrencySymbol, setSelectedFromCurrencySymbol] = useState('$');
  const [selectedToCurrency, setSelectedToCurrency] = useState('');
  const [selectedToCurrencySymbol, setSelectedToCurrencySymbol] = useState('');
  const [currencies, setCurrencies] = useState();
  const [amount, setAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState('');

  useEffect(() => {
    const LoadAvailableCurrencies = async () => {
      var availableCurrenciesResult = await apiService.getAvailableCurrencies();
      setCurrencies(availableCurrenciesResult.data);
    };

    LoadAvailableCurrencies();
  }, []);

  const handleFromCurrencyChange = (e) => {
    setSelectedFromCurrency(e.target.value);
    setSelectedFromCurrencySymbol(getSymbolFromCurrency(e.target.value));
    setAmount('');
    setConvertedAmount('');
  };

  const handleToCurrencyChange = (e) => {
    setSelectedToCurrency(e.target.value);
    setSelectedToCurrencySymbol(getSymbolFromCurrency(e.target.value));
    setConvertedAmount('');
  };
  
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setConvertedAmount('');
  };

  const convertCurrencyAmount = async () => {
    if (selectedFromCurrency && selectedToCurrency && amount) {
      var convertedAmountResult = await apiService.getConvertedCurrencyAmount(selectedFromCurrency, selectedToCurrency, amount);
      setConvertedAmount(convertedAmountResult.data);
    }
  };

  return (
    <Paper className={classes.root + ' ' + classes.currencyConverter}>
      <Grid container spacing={3}>
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h6">Currency Converter</Typography>
        </Grid>
        {/* Controls */}
        <Grid item container spacing={2}>
          <Grid item container xs={12} md={10}>
            {/* From */}
            <Grid item spacing={1} direction="column" xs={12} sm={5} container>
              <Grid item className={classes.inputTitle}>
                <Typography variant="caption">From</Typography>
              </Grid>
              <Grid item>
                <Select
                  id="from-currency-select"
                  variant="outlined"
                  value={selectedFromCurrency}
                  onChange={handleFromCurrencyChange}
                  className={[classes.inputControl, classes.inputControlLeft, classes.inputControlRight].join(' ')}
                  input={<CustomInput />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select Currency</em>
                  </MenuItem>
                  {currencies &&
                    currencies.length > 0 &&
                    currencies.map((currency, index) => {
                      return (
                        <MenuItem key={`from-${index}`} value={currency.code}>
                          {currency.code}: {currency.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  className={clsx(classes.inputControl, classes.amountTextfield)}
                  value={amount}
                  onChange={handleAmountChange}
                  inputProps={{ prefixSymbol: selectedFromCurrencySymbol }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                ></TextField>
              </Grid>
            </Grid>
            {/* Exchange Icon */}
            <Grid item spacing={1} direction="column" xs={12} sm container alignContent="center">
              <SyncAltIcon className={classes.exchangeIcon} />
            </Grid>
            {/* To */}
            <Grid item spacing={1} direction="column" xs={12} sm={5} container>
              <Grid item className={classes.inputTitle}>
                <Typography variant="caption">To</Typography>
              </Grid>
              <Grid item>
                <Select
                  id="to-currency-select"
                  variant="outlined"
                  value={selectedToCurrency}
                  onChange={handleToCurrencyChange}
                  className={classes.inputControl}
                  input={<CustomInput />}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>Select Currency</em>
                  </MenuItem>
                  {currencies &&
                    currencies.length > 0 &&
                    currencies.map((currency, index) => {
                      return (
                        <MenuItem key={`to-${index}`} value={currency.code}>
                          {currency.code}: {currency.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  className={clsx(classes.inputControl, classes.convertedAmountTextfield)}
                  value={convertedAmount}
                  inputProps={{ prefixSymbol: selectedToCurrencySymbol }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    readOnly: true,
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          {/* Button */}
          <Grid item xs={2} className={classes.convertButtonContainer}>
            <Button theme={'light'} variant="contained" className={classes.convertButton} type="submit" onClick={() => convertCurrencyAmount()}>
              Convert
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CurrencyConverter;
