import React, { useContext } from 'react';
import AdminDrawer from '../layouts/AdminDrawer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/styles';
import { darkTheme } from '../../themes/darkTheme';
import { lightThemeAdmin } from '../../themes/lightTheme';
import { UserContext } from '../../contexts/UserContext';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TopRedBar from './TopRedBar';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    // maxWidth: '1600px',
  },
  paper: {
    width: '100%',
    padding: '50px',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  nonePaper: {
    padding: 0,
    boxShadow: 'none',
    background: 'none',
    transition: 'none',
  },
}));

function EntityListFrame(props) {
  const [userContext] = useContext(UserContext);
  const classes = useStyles();
  const { inPaper = true } = props;
  return (
    <ThemeProvider theme={parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme}>
      <CssBaseline />
      <div className={classes.root}>
        <AdminDrawer />
        <main className={classes.content}>
          <TopRedBar />
          <Container maxWidth="xl" className={classes.container}>
            <Paper elevation={12} className={clsx(classes.paper, { [classes.nonePaper]: !inPaper })}>
              <Grid container spacing={3}>
                {props.children}
              </Grid>
            </Paper>
            {/* {inPaper ? (
              <Paper elevation={12} className={classes.paper}>
                <Grid container spacing={3}>
                  {props.children}
                </Grid>
              </Paper>
            ) : (
              <Grid container spacing={3}>
                {props.children}
              </Grid>
            )} */}
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default EntityListFrame;
