import React from 'react';
import { render } from 'react-dom';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import helperService from '../services/help.service';
import { lightThemeAdmin } from '../themes/lightTheme';
import { format } from 'react-string-format';
import tableService from './table.service';
import moment from 'moment';

class pdfTableService {
  generatePortPairPDF(data, header, fileName) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'portrait'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;
      const headers = [['Origin', 'Destination', 'Weight (Kg)', 'Volume (m3)', 'TEU', 'Avg. Transit Time (days)']];

      const pdfData = data.map((elt) => [
        elt.origin,
        elt.destination,
        elt.weight.toLocaleString(),
        elt.volume.toLocaleString(),
        elt.teu,
        elt.avgTransitTime.toLocaleString(),
      ]);

      let content = {
        startY: 50,
        head: headers,
        body: pdfData,
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateCommercialInvoiceValuePerSupplierPDF(data, header, fileName) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'portrait'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;
      const headers = [
        ['Supplier', 'Customs ($)', 'Duty ($)', 'FOB ($)', 'FOB Currency', 'CIF ($)', 'CIF Currency', 'Commercial Invoice Value ($)', 'Invoice Currency'],
      ];

      const pdfData = data.map((item) => [
        item.supplier,
        item.customs.toLocaleString(),
        item.duty.toLocaleString(),
        item.fob.toLocaleString(),
        'AUD', //item.fobCurrency,//Feedback from Nicole(2021-03-18) FOB value to always AUD
        item.cif.toLocaleString(),
        item.cifCurrency,
        item.invoiceValue.toLocaleString(),
        item.invoiceCurrency,
      ]);

      let content = {
        startY: 50,
        head: headers,
        body: pdfData,
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateShipmentPDF(data, header, fileName, hiddenColumnNames) {
    var VolumeM2 = 'Volume ';
    var Msup = '(M';
    var Mend = ')';
    var sup2 = "<sup class='sup2'>2</sup>";
    var fulltext = VolumeM2 + Msup + ' ' + ' ' + Mend + sup2;

    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;

      const defaultHeaders = [
        { id: 'ShipmentNumber', label: 'Shipment No.' },
        // { id: 'Vessel', label: 'Vessel' },
        { id: 'DepartureVessel', label: 'Departure Vessel' },
        { id: 'ArrivalVessel', label: 'Arrival Vessel' },
        { id: 'Origin', label: 'Origin' },
        { id: 'Destination', label: 'Destination' },
        { id: 'FDPName', label: 'Final Delivery' },
        { id: 'Trans', label: 'Trans' },
        { id: 'Mode', label: 'Mode' },
        { id: 'Supplier', label: 'Supplier' },
        { id: 'Importer', label: 'Importer' },
        { id: 'OriginETD', label: 'Origin ETD' },
        { id: 'DestinationETA', label: 'Destination ETA' },
        { id: 'FDPETA', label: 'FDP ETA' },
        { id: 'DelayInDays', label: 'Delay (Day)' },
        { id: 'Weight', label: 'Weight (Kg)' },
        { id: 'Volume', label: 'Volume (m3)' },
        { id: 'TEU', label: 'TEU' },
        { id: 'CarrierCode', label: 'Carrier Code' },
        { id: 'ContainerCount', label: 'Container Count' },
        { id: 'Incoterm', label: 'Incoterm' },
      ];

      const pdfData = data.map((elt) => [
        elt.shipmentNumber,
        // elt.vessel,
        elt.departureVessel,
        elt.arrivalVessel,
        elt.origin,
        elt.destination,
        elt.fdpName,
        elt.trans,
        elt.mode,
        elt.supplier,
        elt.importer,
        elt.originETD.substring(0, 10),
        elt.destinationETA.substring(0, 10),
        elt.fdpeta.substring(0, 10),
        elt.delayInDays > 0 ? format('+{0}', elt.delayInDays) : elt.delayInDays,
        helperService.thousands_separators(elt.weight),
        helperService.thousands_separators(elt.volume),
        elt.teu,
        elt.carrierCode,
        elt.containerCount,
        elt.incoterm,
      ]);

      let filteredTable = tableService.filterHiddenTableColumns(defaultHeaders, pdfData, hiddenColumnNames, 0);
      let headerLabels = filteredTable.headCells.map((cell) => {
        return cell.label;
      });

      const headers = [[...headerLabels]];

      let content = {
        startY: 50,
        head: headers,
        body: filteredTable.contentRows,
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;
            if (table.cell.text[0] === 'Shipment No.') {
              table.cell.styles.cellWidth = 55;
            }
            if (table.cell.text[0] === 'Origin ETD' || table.cell.text[0] === 'Destination ETA') {
              table.cell.styles.cellWidth = 55;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateSpendPerSupplierPDF(data, header, fileName) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'portrait'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;
      const headers = [['Supplier', 'Total Spend ($)']];

      const pdfData = data.map((elt) => [elt.supplier, helperService.thousands_separators(elt.amount)]);

      let content = {
        startY: 50,
        head: headers,
        body: pdfData,
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateConsolidatedShipmentsSpendsPDF(data, header, fileName) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(11);

      const title = header;

      const headers = [
        [
          'Shipment No.(s)',
          'Origin',
          'Destination',
          'Trans',
          'Mode',
          'Origin ETD',
          'Destination ETA',
          'Carrier Name',
          'Carrier Booking Ref.',
          'Freight ($)',
          'Commercial Value ($)',
          'Total ($)',
          'TEU',
        ],
      ];

      const pdfData = data.map((elt) => [
        elt.shipmentNumbers,
        elt.origin,
        elt.destination,
        elt.trans,
        elt.mode,
        elt.originETD.substring(0, 10),
        elt.destinationETA.substring(0, 10),
        elt.carrierName,
        elt.carrierBookingReference,
        elt.totalFreightSpend && helperService.thousands_separators(elt.totalFreightSpend),
        elt.commercialValue && helperService.thousands_separators(elt.commercialValue),
        elt.totalSpends && helperService.thousands_separators(elt.totalSpends),
        elt.teu,
      ]);

      let content = {
        startY: 50,
        head: headers,
        body: pdfData,
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;
          }
          if (table.cell.text[0] === 'Shipment No.(s)') {
            table.cell.styles.cellWidth = 60;
          }
          if (table.cell.text[0] === 'Origin ETD' || table.cell.text[0] === 'Destination ETA') {
            table.cell.styles.cellWidth = 55;
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateShipmentSpendsPDF(data, header, fileName, hiddenColumnNames) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(11);

      const title = header;

      const defaultHeaders = [
        { id: 'ShipmentNumber', label: 'Shipment No.' },
        { id: 'Origin', label: 'Origin' },
        { id: 'Destination', label: 'Destination' },
        { id: 'Trans', label: 'Trans' },
        { id: 'Mode', label: 'Mode' },
        { id: 'OriginETD', label: 'Origin ETD' },
        { id: 'DestinationETA', label: 'Destination ETA' },
        { id: 'CarrierName', label: 'Carrier Name' },
        { id: 'CarrierBookingReference', label: 'Carrier Booking Ref.' },
        { id: 'TotalFreightSpend', label: 'Freight ($)' },
        { id: 'CommercialValue', label: 'Commercial Value ($)' },
        { id: 'TotalSpends', label: 'Total ($)' },
        { id: 'TEU', label: 'TEU' },
      ];

      const pdfData = data.map((elt) => [
        elt.shipmentNumber,
        elt.origin,
        elt.destination,
        elt.trans,
        elt.mode,
        elt.originETD.substring(0, 10),
        elt.destinationETA.substring(0, 10),
        elt.carrierName,
        elt.carrierBookingReference,
        elt.totalFreightSpend && helperService.thousands_separators(elt.totalFreightSpend),
        elt.commercialValue && helperService.thousands_separators(elt.commercialValue),
        elt.totalSpends && helperService.thousands_separators(elt.totalSpends),
        elt.teu,
      ]);

      let filteredTable = tableService.filterHiddenTableColumns(defaultHeaders, pdfData, hiddenColumnNames, 0);
      let headerLabels = filteredTable.headCells.map((cell) => {
        return cell.label;
      });

      const headers = [[...headerLabels]];

      let content = {
        startY: 50,
        head: headers,
        body: pdfData,
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;
          }
          if (table.cell.text[0] === 'Shipment No.') {
            table.cell.styles.cellWidth = 55;
          }
          if (table.cell.text[0] === 'Origin ETD' || table.cell.text[0] === 'Destination ETA') {
            table.cell.styles.cellWidth = 55;
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateShipmentDetailsPDF(data, header, fileName, hiddenColumnNames) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;

      const defaultHeaders = [
        { id: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'supplier', label: 'Supplier' },
        { id: 'productCode', label: 'Product Code' },
        { id: 'description', label: 'Description' },
        { id: 'invoiceQty', label: 'Qty' },
        { id: 'invoiceUQ', label: 'UQ' },
        { id: 'unitValue', label: 'Unit Value ($)' },
        { id: 'DelayInDays', label: 'Delay (Day)' },
      ];

      const pdfData = data.map((elt) => [
        elt.shipmentNumber,
        elt.supplier,
        elt.productCode,
        elt.description,
        elt.invoiceQty.toLocaleString(),
        elt.invoiceUQ,
        elt.unitValue.toLocaleString(),
        elt.delayInDays > 0 ? format('+{0}', elt.delayInDays) : elt.delayInDays,
      ]);

      let filteredTable = tableService.filterHiddenTableColumns(defaultHeaders, pdfData, hiddenColumnNames, 0);
      let headerLabels = filteredTable.headCells.map((cell) => {
        return cell.label;
      });
      const headers = [[...headerLabels]];

      let content = {
        startY: 50,
        head: headers,
        body: filteredTable.contentRows,
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;

            if (table.cell.text[0] === 'Consignor') {
              table.cell.styles.cellWidth = 60;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateProductsPDF(data, header, fileName) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;
      const headers = [['Product Code', 'Description', 'Total Qty.', 'Avg. ($)', 'Min ($)', 'Max ($)', 'Suppliers']];
      const pdfData = data.map((item) => [
        item.productCode,
        item.description,
        item.totalQty.toLocaleString(),
        item.avgUnitValue.toLocaleString(),
        item.minUnitValue.toLocaleString(),
        item.maxUnitValue.toLocaleString(),
        item.suppliers,
      ]);

      let content = {
        startY: 50,
        head: headers,
        body: pdfData,
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;

            if (table.cell.text[0] === 'Consignor') {
              table.cell.styles.cellWidth = 60;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generatePurhcaseOrderEDIPDF(data, header, fileName) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;
      const headers = [['Order Number', 'Supplier', 'Deliver To', 'Order Date', 'Shipment Status']];
      const pdfData = data.map((item) => {
        var shipmentStatusesText = 'Pending';
        if (item.shipmentStatuses) {
          var shipmentStatuses = [];
          item.shipmentStatuses.map((shipmentStatus) => {
            shipmentStatuses.push(shipmentStatus.shipmentNumber + ': ' + shipmentStatus.status);
          });
          shipmentStatusesText = shipmentStatuses.join(', ');
        }

        return [item.orderNumber, item.supplier, item.deliverTo, item.orderDate.substring(0, 10), shipmentStatusesText];
      });

      let content = {
        startY: 50,
        head: headers,
        body: pdfData,
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;

            if (table.cell.text[0] === 'Consignor') {
              table.cell.styles.cellWidth = 60;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generatePurhcaseOrderEDILinesPDF(data, header, fileName, hiddenColumnNames) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(10);

      const title = header;

      const defaultHeaders = [
        { id: 'orderNumber', label: 'Order No.' },
        { id: 'lineId', label: 'Line No.' },
        { id: 'orderNumberSplit', label: 'Split No.' },
        { id: 'code', label: 'Code' },
        // { id: 'description', label: 'Description' },
        // { id: 'qty', label: 'Qty' },
        // { id: 'qtyUOM', label: 'Qty UOM' },
        { id: 'unitPrice', label: 'Unit Price($)' },
        // { id: 'unitQty', label: 'Unit Qty' },
        // { id: 'unitUOM', label: 'Unit UOM' },
        // { id: 'linePrice', label: 'Line Price($)' },
        { id: 'qtyMet', label: 'Qty Receive' },
        { id: 'remainingQty', label: 'Remaining Qty' },
        { id: 'commercialValue', label: 'Shipped Commercial Value($)' },
        { id: 'remainingCommercialValue', label: 'Remaining Commercial Value($)' },
        // { id: 'buyer', label: 'Buyer' },
        // { id: 'orderDate', label: 'Order Date' },
        { id: 'shippingDate', label: 'Shipping Date' },
        { id: 'requiredInStoreDate', label: 'Required In Store' },
        { id: 'deliveryDate', label: 'Delivery Date' },
        // { id: 'supplier', label: 'Supplier' },
        // { id: 'shipmentNumber', label: 'Shipment No.' },
        // { id: 'recentEventType', label: 'Status' },
        { id: 'originETD', label: 'ETD' },
        { id: 'originATD', label: 'ATD' },
        { id: 'destinationETA', label: 'ETA' },
        { id: 'destinationATA', label: 'ATA' },
        { id: 'inStoreDelayInDays', label: 'In Store Delay (Days)' },
        { id: 'arrivalDelayInDays', label: 'Arrival Delay (Days)' },
        { id: 'departureDelayInDays', label: 'Departure Delay (Days)' },
        { id: 'transitDelayInDays', label: 'Transit Delay (Days)' },
        { id: 'vendorBookingDelayInDays', label: 'Vendor Booking Delay (Days)' },
      ];

      const pdfData = data.map((elt) => {
        let originETD = moment(elt.originETD);
        let originATD = moment(elt.originATD);
        let destinationETA = moment(elt.destinationETA);
        let destinationATA = moment(elt.destinationATA);
        let requiredInStoreDate = moment(elt.requiredInStoreDate);
        let deliveryDate = moment(elt.deliveryDate);
        // let orderDate = moment(elt.orderDate);
        let shippingDate = moment(elt.shippingDate);

        let unitPrice = '';
        if (elt.unitPrice) {
          if (elt.unitQty > 1) {
            unitPrice = `$${elt.unitPrice} per ${elt.unitQty}${elt.unitUOM ?? ''}`;
          } else {
            unitPrice = `$${elt.unitPrice} per ${elt.unitUOM ?? ''}`;
          }
        }

        return [
          elt.orderNumber,
          elt.lineId,
          elt.orderNumberSplit,
          elt.code,
          // elt.description,
          // elt.qty ? elt.qty.toLocaleString() : '',
          // elt.qtyUOM,
          unitPrice,
          // elt.unitQty ? elt.unitQty.toLocaleString() : '',
          // elt.unitUOM,
          // elt.linePrice ? elt.linePrice.toLocaleString() : '',
          elt.qtyMet ? elt.qtyMet.toLocaleString() : '0',
          elt.remainingQty ? elt.remainingQty.toLocaleString() : '0',
          elt.commercialValue ? elt.commercialValue.toLocaleString() : '0',
          elt.remainingCommercialValue ? elt.remainingCommercialValue.toLocaleString() : '0',
          // elt.buyer,
          // orderDate.isValid() ? orderDate.format('L') : '',
          shippingDate.isValid() ? shippingDate.format('L') : '',
          requiredInStoreDate.isValid() ? requiredInStoreDate.format('L') : '',
          deliveryDate.isValid() ? deliveryDate.format('L') : '',
          // elt.supplier,
          // elt.shipmentNumber,
          // elt.recentEventType,
          originETD.isValid() ? originETD.format('L') : '',
          originATD.isValid() ? originATD.format('L') : '',
          destinationETA.isValid() ? destinationETA.format('L') : '',
          destinationATA.isValid() ? destinationATA.format('L') : '',
          elt.inStoreDelayInDays > 0 ? format('+{0}', elt.inStoreDelayInDays) : elt.inStoreDelayInDays,
          elt.arrivalDelayInDays > 0 ? format('+{0}', elt.arrivalDelayInDays) : elt.arrivalDelayInDays,
          elt.departureDelayInDays > 0 ? format('+{0}', elt.departureDelayInDays) : elt.departureDelayInDays,
          elt.transitDelayInDays > 0 ? format('+{0}', elt.transitDelayInDays) : elt.transitDelayInDays,
          elt.vendorBookingDelayInDays > 0 ? format('+{0}', elt.vendorBookingDelayInDays) : elt.vendorBookingDelayInDays,
        ];
      });

      let filteredTable = tableService.filterHiddenTableColumns(defaultHeaders, pdfData, hiddenColumnNames, 0);
      let headerLabels = filteredTable.headCells.map((cell) => {
        return cell.label;
      });
      const headers = [[...headerLabels]];

      let content = {
        startY: 50,
        head: headers,
        body: filteredTable.contentRows,
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;

            if (table.cell.text[0] === 'Description') {
              table.cell.styles.cellWidth = 60;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generatePurhcaseOrderEDILineSpendsPDF(data, header, fileName, hiddenColumnNames) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(10);

      const title = header;

      const defaultHeaders = [
        { id: 'orderNumber', label: 'Order No.' },
        { id: 'lineId', label: 'Line No.' },
        { id: 'orderNumberSplit', label: 'Split No.' },
        { id: 'code', label: 'Code' },
        { id: 'unitPrice', label: 'Unit Price($)' },
        { id: 'qtyMet', label: 'Qty Receive' },
        { id: 'remainingQty', label: 'Remaining Qty' },
        { id: 'commercialValue', label: 'Shipped Commercial Value($)' },
        { id: 'remainingCommercialValue', label: 'Remaining Commercial Value($)' },
        { id: 'freightSpendPerUnit', label: 'Freight per Unit($)' },
        { id: 'freightSpendPerLine', label: 'Freight per Line($)' },
        { id: 'totalSpendsPerLine', label: 'Total Spends per Line($)' },
      ];

      const pdfData = data.map((elt) => {
        let unitPrice = '';
        if (elt.unitPrice) {
          if (elt.unitQty > 1) {
            unitPrice = `$${elt.unitPrice} per ${elt.unitQty}${elt.unitUOM ?? ''}`;
          } else {
            unitPrice = `$${elt.unitPrice} per ${elt.unitUOM ?? ''}`;
          }
        }

        return [
          elt.orderNumber,
          elt.lineId,
          elt.orderNumberSplit,
          elt.code,
          unitPrice,
          elt.qtyMet ? elt.qtyMet.toLocaleString() : '0',
          elt.remainingQty ? elt.remainingQty.toLocaleString() : '0',
          elt.commercialValue ? elt.commercialValue.toLocaleString() : '0',
          elt.remainingCommercialValue ? elt.remainingCommercialValue.toLocaleString() : '0',
          elt.freightSpendPerUnit ? elt.freightSpendPerUnit.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '',
          elt.freightSpendPerLine ? elt.freightSpendPerLine.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '',
          elt.totalSpendsPerLine ? elt.totalSpendsPerLine.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '',
        ];
      });

      let filteredTable = tableService.filterHiddenTableColumns(defaultHeaders, pdfData, hiddenColumnNames, 0);
      let headerLabels = filteredTable.headCells.map((cell) => {
        return cell.label;
      });
      const headers = [[...headerLabels]];

      let content = {
        startY: 50,
        head: headers,
        body: filteredTable.contentRows,
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;

            if (table.cell.text[0] === 'Description') {
              table.cell.styles.cellWidth = 60;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generatePurhcaseOrderEDILineMilestonesPDF(data, header, fileName, hiddenColumnNames) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(10);

      const title = header;

      const defaultHeaders = [
        { id: 'orderNumber', label: 'Order No.' },
        { id: 'lineId', label: 'Line No.' },
        { id: 'orderNumberSplit', label: 'Split No.' },
        { id: 'shippingDate', label: 'Shipping Date' },
        { id: 'deliveryDate', label: 'Delivery Date' },
        { id: 'requiredInStoreDate', label: 'Required In Store' },
        { id: 'originETD', label: 'ETD' },
        { id: 'originATD', label: 'ATD' },
        { id: 'destinationETA', label: 'ETA' },
        { id: 'destinationATA', label: 'ATA' },
        { id: 'inStoreDelayInDays', label: 'In Store Delay (Days)' },
        { id: 'arrivalDelayInDays', label: 'Arrival Delay (Days)' },
        { id: 'departureDelayInDays', label: 'Departure Delay (Days)' },
        { id: 'transitDelayInDays', label: 'Transit Delay (Days)' },
        { id: 'vendorBookingDelayInDays', label: 'Vendor Booking Delay (Days)' },
      ];

      const pdfData = data.map((elt) => {
        let originETD = moment(elt.originETD);
        let originATD = moment(elt.originATD);
        let destinationETA = moment(elt.destinationETA);
        let destinationATA = moment(elt.destinationATA);
        let requiredInStoreDate = moment(elt.requiredInStoreDate);
        let deliveryDate = moment(elt.deliveryDate);
        let shippingDate = moment(elt.shippingDate);

        return [
          elt.orderNumber,
          elt.lineId,
          elt.orderNumberSplit,
          shippingDate.isValid() ? shippingDate.format('L') : '',
          deliveryDate.isValid() ? deliveryDate.format('L') : '',
          requiredInStoreDate.isValid() ? requiredInStoreDate.format('L') : '',
          originETD.isValid() ? originETD.format('L') : '',
          originATD.isValid() ? originATD.format('L') : '',
          destinationETA.isValid() ? destinationETA.format('L') : '',
          destinationATA.isValid() ? destinationATA.format('L') : '',
          elt.inStoreDelayInDays > 0 ? format('+{0}', elt.inStoreDelayInDays) : elt.inStoreDelayInDays,
          elt.arrivalDelayInDays > 0 ? format('+{0}', elt.arrivalDelayInDays) : elt.arrivalDelayInDays,
          elt.departureDelayInDays > 0 ? format('+{0}', elt.departureDelayInDays) : elt.departureDelayInDays,
          elt.transitDelayInDays > 0 ? format('+{0}', elt.transitDelayInDays) : elt.transitDelayInDays,
          elt.vendorBookingDelayInDays > 0 ? format('+{0}', elt.vendorBookingDelayInDays) : elt.vendorBookingDelayInDays,
        ];
      });

      let filteredTable = tableService.filterHiddenTableColumns(defaultHeaders, pdfData, hiddenColumnNames, 0);
      let headerLabels = filteredTable.headCells.map((cell) => {
        return cell.label;
      });
      const headers = [[...headerLabels]];

      let content = {
        startY: 50,
        head: headers,
        body: filteredTable.contentRows,
        bodyStyles: {
          fontSize: 9,
        },
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;

            if (table.cell.text[0] === 'Description') {
              table.cell.styles.cellWidth = 60;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }

  generateExceptionsPDF(data, header, fileName, hiddenColumnNames) {
    if (data) {
      const unit = 'pt';
      const size = 'A4'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(12);

      const title = header;

      const defaultHeaders = [
        { id: 'ShipmentNumber', label: 'Shipment No.' },
        { id: 'OrderNumber', label: 'Order No.' },
        { id: 'Supplier', label: 'Supplier' },
        { id: 'RequiredExWorksDate', label: 'Req. ExWorks' },
        { id: 'RequiredDeliveryDate', label: 'Req. Delivery' },
        { id: 'EstimatedDeliveryDate', label: 'Est. Delivery' },
        { id: 'ActualDeliveryDate', label: 'Act. Delivery' },
        { id: 'EstimatedExFactoryDate', label: 'Est. ExFactory' },
        { id: 'ActualExFactoryDate', label: 'Act ExFactory' },
        { id: 'ETD', label: 'ETD' },
        { id: 'ATD', label: 'ATD' },
        { id: 'ETA', label: 'ETA' },
        { id: 'ATA', label: 'ATA' },
        { id: 'EstimatedCustomsClearedDate', label: 'Est. Customs Cleared' },
        { id: 'ActualCustomsClearedDate', label: 'Act. Customs Cleared' },
        { id: 'CTOAvailability', label: 'CTO Availability' },
      ];

      const pdfData = data.map((item) => {
        let etd = moment(item.etd);
        let atd = moment(item.atd);
        let eta = moment(item.eta);
        let ata = moment(item.ata);
        let requiredExWorksDate = moment(item.requiredExWorksDate);
        let requiredDeliveryDate = moment(item.requiredDeliveryDate);
        let estimatedDeliveryDate = moment(item.estimatedDeliveryDate);
        let actualDeliveryDate = moment(item.actualDeliveryDate);
        let estimatedExFactoryDate = moment(item.estimatedExFactoryDate);
        let actualExFactoryDate = moment(item.actualExFactoryDate);
        let estimatedCustomsClearedDate = moment(item.estimatedCustomsClearedDate);
        let actualCustomsClearedDate = moment(item.actualCustomsClearedDate);
        let ctoAvailability = moment(item.ctoAvailability);

        return [
          item.shipmentNumber,
          item.orderNumber,
          item.supplierName,
          requiredExWorksDate.isValid() ? requiredExWorksDate.format('L') : '',
          requiredDeliveryDate.isValid() ? requiredDeliveryDate.format('L') : '',
          estimatedDeliveryDate.isValid() ? estimatedDeliveryDate.format('L') : '',
          actualDeliveryDate.isValid() ? actualDeliveryDate.format('L') : '',
          estimatedExFactoryDate.isValid() ? estimatedExFactoryDate.format('L') : '',
          actualExFactoryDate.isValid() ? actualExFactoryDate.format('L') : '',
          etd.isValid() ? etd.format('L') : '',
          atd.isValid() ? atd.format('L') : '',
          eta.isValid() ? eta.format('L') : '',
          ata.isValid() ? ata.format('L') : '',
          estimatedCustomsClearedDate.isValid() ? estimatedCustomsClearedDate.format('L') : '',
          actualCustomsClearedDate.isValid() ? actualCustomsClearedDate.format('L') : '',
          ctoAvailability.isValid() ? ctoAvailability.format('L') : '',
        ];
      });

      let filteredTable = tableService.filterHiddenTableColumns(defaultHeaders, pdfData, hiddenColumnNames, 0);
      let headerLabels = filteredTable.headCells.map((cell) => {
        return cell.label;
      });

      const headers = [[...headerLabels]];

      let content = {
        startY: 50,
        head: headers,
        body: filteredTable.contentRows,
        bodyStyles: {
          fontSize: 8,
        },
        didParseCell: function (table) {
          if (table.section === 'head') {
            table.cell.styles.fillColor = lightThemeAdmin.pdf.table.headerColor;
            if (table.cell.text[0] === 'Shipment No.') {
              table.cell.styles.cellWidth = 55;
            }
            if (table.cell.text[0] === 'Order No.') {
              table.cell.styles.cellWidth = 55;
            }
          }
        },
      };

      doc.text(title, marginLeft, 30);
      doc.autoTable(content);
      doc.save(fileName + '.pdf');
    }
  }
}
export default new pdfTableService();
