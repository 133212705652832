import React, { useEffect, useRef, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import EntityTable from '../../parts/EntityTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import AccountEntry from './AccountEntry';
import { useHistory, Redirect, useLocation } from 'react-router';
import DeleteDialog from '../../parts/DeleteDialog';
import { ProtectedRoute } from '../../../protected.route';
import { Switch, useRouteMatch } from 'react-router-dom';
import { ReloadContext } from '../../../contexts/ReloadContext';
import { UserContext } from '../../../contexts/UserContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import IconButton from '@material-ui/core/IconButton';
import PublicIcon from '@material-ui/icons/Public';

import { withRouter } from 'react-router';

import moment from 'moment';
import FeaturesSettingEntry from './FeaturesSettingEntry';
import ExternalApplication from '../ExternalApplication/ExternalApplicationEntry';

function Accounts(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;

  const [showFrameAsPaper, setShowFrameAsPaper] = useState(true);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [userContext] = useContext(UserContext);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: '0',
  });
  const [reloadContext] = useContext(ReloadContext);

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService
      .getAccounts(page + 1, rowsPerPage, order, orderBy)
      .then((result) => {
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }, [order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  useEffect(() => {
    if (location.pathname === '/accounts/add' || location.pathname.indexOf('edit') > -1) {
      setShowFrameAsPaper(false);
    } else {
      setShowFrameAsPaper(true);
    }
  }, [location]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let c = moment(item.createdDate);
      let u = moment(item.updatedDate);
      newArray.push([item.id, item.name, item.contactEmail, c.format('L'), u.format('L')]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
  };

  const handleDelete = (id) => {
    apiService
      .deleteAccount(id)
      .then((result) => {
        setDummyCounter(dummyCounter + 1);
      })
      .catch((err) => {
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const handleAdd = () => {
    history.push('/accounts/add');
  };

  const handleEdit = (event) => {
    history.push('/accounts/' + event.currentTarget.dataset.tag + '/edit');
  };

  const handleEditFeature = (event) => {
    history.push('/accounts/' + event.currentTarget.parentNode.dataset.tag + '/features');
  };

  const headCells = [
    { id: 'Name', numeric: false, disablePadding: true, label: 'Company' },
    {
      id: 'ContactEmail',
      numeric: false,
      disablePadding: false,
      label: 'Contact Email',
    },

    {
      id: 'CreatedDate',
      numeric: true,
      disablePadding: false,
      label: 'Created Date',
    },
    {
      id: 'UpdatedDate',
      numeric: true,
      disablePadding: false,
      label: 'Updated Date',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <EntityListFrame inPaper={showFrameAsPaper}>
        <Grid item xs={12}>
          {rows === '' ? (
            ''
          ) : (
            <Switch>
              {userContext.isAdmin && userContext.permissions.canManageAccounts ? (
                <ProtectedRoute exact path={`${match.path}/add`}>
                  <AccountEntry action="add" />
                </ProtectedRoute>
              ) : (
                ''
              )}
              <ProtectedRoute exact path={`${match.path}/:id/edit`}>
                <AccountEntry action="edit" />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}/:id/features`}>
                <FeaturesSettingEntry />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}/:accountId/external-application`}>
                <ExternalApplication />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}`}>
                <EntityTable
                  title="Accounts"
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  deleteHandler={handleDeleteRequest}
                  handleAdd={handleAdd}
                  handleEdit={handleEdit}
                  canAdd={userContext.isAdmin && userContext.permissions.canManageAccounts}
                  additionalRowAction={
                    <IconButton aria-label="edit" onClick={handleEditFeature}>
                      <BallotOutlinedIcon size="small" />
                    </IconButton>
                  }
                />
              </ProtectedRoute>
              <Redirect to="/dashboard" />
              <ProtectedRoute />
            </Switch>
          )}
        </Grid>
        <DeleteDialog
          action={pendingDeleteAction}
          handleDelete={handleDelete}
          title="Delete Account?"
          body="Are you sure you want to delete this account with all its data? Once deleted it cannot be undone."
        />
      </EntityListFrame>
    </>
  );
}

export default withRouter(Accounts);
