import { Box, makeStyles, MenuItem } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../../contexts/UserContext';
import apiService from '../../../services/api.service';
import SmallSelect from '../../../wrappers/SmallSelect';

const useStyles = makeStyles((theme) => ({
  customerSelectContainer: {
    paddingBottom: theme.spacing(1.5),
  },
}));

export default function EDIReportFilter(props) {
  const { callbackSelectedFilterChanged, setIsLoading } = props;
  const classes = useStyles();
  const [selectedCompanyAccountId, setSelectedCompanyAccountId] = useState('');
  const [reportFilter, setReportFilter] = useState([]);
  const history = useHistory();
  const [userContext] = useContext(UserContext);

  useEffect(() => {
    let isCancelled = false;

    setIsLoading(true);
    apiService
      .getEDIReportFilter()
      .then((result) => {
        if (!isCancelled) {
          setReportFilter(result.data);
          if (result.data.companies && result.data.companies.length > 0) {
            setSelectedCompanyAccountId(result.data.companies[0].accountId);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) history.push('/login');
      });

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (callbackSelectedFilterChanged && selectedCompanyAccountId) {
      let selectedCompanyName = reportFilter.companies.find((x) => x.accountId === selectedCompanyAccountId).name;
      callbackSelectedFilterChanged({
        companyAccountId: selectedCompanyAccountId,
        companyName: selectedCompanyName,
      });
    }
  }, [selectedCompanyAccountId]);

  const handleCompanyChange = (e) => {
    setSelectedCompanyAccountId(e.target.value);
  };

  return (
    <>
      {userContext.isAdmin && (
        <Box className={classes.customerSelectContainer}>
          <SmallSelect labelId="companySelect-label" id="companySelect" variant="outlined" value={selectedCompanyAccountId} onChange={handleCompanyChange}>
            {reportFilter &&
              reportFilter.companies &&
              reportFilter.companies.map((company) => {
                return (
                  <MenuItem key={company.accountId} value={company.accountId}>
                    {company.name}
                  </MenuItem>
                );
              })}
          </SmallSelect>
        </Box>
      )}
    </>
  );
}
