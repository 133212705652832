import React, { useCallback, useEffect, useState, useContext, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import apiService from '../../services/api.service';
import { UserContext } from '../../contexts/UserContext';
import { ReloadContext } from '../../contexts/ReloadContext';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TitleFont from '../Typography/TitleFont';
import { Checkbox, Divider, FormControlLabel, Grid, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import helpService from '../../services/help.service';
import CancelIcon from '@material-ui/icons/Cancel';
import DelayChangedTextField from '../../wrappers/DelayChangedTextField';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import CustomCheckbox from './CustomCheckbox';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  checkBlockControl: {
    paddingTop: '6px',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  checkBox: {
    marginRight: theme.spacing(1),
  },
  icon: {
    color: '#888888',
  },
  select: {
    fontFamily: theme.font.menu,
  },
  specialSearch: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    '& b': {
      paddingRight: theme.spacing(1),
    },
  },
  filterTag: {
    position: 'relative',
    padding: 5,
    paddingLeft: 12,
    paddingRight: 30,
    backgroundColor: theme.table.filterTag.color,
    color: 'white',
    borderRadius: 16,
    margin: 2,
    '& svg': {
      position: 'absolute',
      right: 5,
      top: 5,
      fontSize: '1.2rem',
      color: '#1c1c1c',
      cursor: 'pointer',
      backgroundImage: 'linear-gradient(#fff,#fff)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50% 50%',
      backgroundPosition: 'center',
      '&:hover': {
        color: '#636363',
      },
    },
  },
}));
const defaultPeriodMonths = 0;
const dateBaseOns = { eta: 'ETA', etd: 'ETD', ata: 'ATA', atd: 'ATD' };

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Suppliers(props) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
      <InputLabel id="demo-mutiple-checkbox-label">
        <TitleFont>Suppliers</TitleFont>
      </InputLabel>
      <Select
        className={classes.select}
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        label="Suppliers"
        value={(props.suppliers && props.selectedSupplierValues) || ''}
        onChange={props.handleSupplierValuesChange}
        autoWidth={true}
      >
        <MenuItem value="All" key="all">
          ALL
        </MenuItem>
        <Divider style={{ marginBottom: '10px' }}></Divider>
        {props.suppliers &&
          props.suppliers.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

function Period(props) {
  const classes = useStyles();
  let labelId = props.id + '-label';
  return (
    <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
      <InputLabel id={labelId}>
        <TitleFont>{props.title}</TitleFont>
      </InputLabel>
      <Select
        className={classes.select}
        labelId={labelId}
        id={props.id}
        label={props.title}
        value={(props.periods && props.selectedPeriodValue) || ''}
        onChange={(e) => props.handlePeriodChange(e)}
      >
        {props.periods &&
          props.periods.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id} disabled={props.disablePeriodMenuItem(item)}>
                {item.displayName}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

const DataTableFilter = forwardRef((props, ref) => {
  let query = useQuery();

  const { showSuppliers = false, showFreeText = true, specialSearch, showDateBasedOn = true, showFocused } = props;

  const history = useHistory();
  const classes = useStyles();
  const [userContext] = useContext(UserContext);
  const [reloadContext] = useContext(ReloadContext);
  const [periods, setPeriods] = useState();
  const [suppliers, setSuppliers] = useState();

  const [selectedSupplierValues, setSelectedSupplierValues] = useState(query.get('supId') || 'All');
  const [selectedDateBasedOn, setSelectedDateBasedOn] = useState(showDateBasedOn ? query.get('b') || dateBaseOns.eta : dateBaseOns.eta);
  const [selectedStartPeriodValue, setSelectedStartPeriodValue] = useState(query.get('from'));
  const [selectedEndPeriodValue, setSelectedEndPeriodValue] = useState(query.get('to'));
  const [accountName, setAccountName] = useState();
  const [freeTextValue, setFreeTextValue] = useState(query.get('s'));

  const [specialSearchTitle, setSpecialSearchTitle] = useState(query.get('sst'));
  const [portOriginCode, setPortOriginCode] = useState(query.get('pOri'));
  const [portDestinationCode, setPortDestinationCode] = useState(query.get('pDest'));
  const [trans, setTrans] = useState(query.get('trans'));
  const [mode, setMode] = useState(query.get('mode'));

  const [postBackFilterDataCounter, setPostBackFilterDataCounter] = useState(1);

  const [companies, setCompanies] = useState();
  const [companyId, setCompanyId] = useState(query.get('cId'));
  const [accountId, setAccountId] = useState();

  const [showFocusedShipment, setShowFocusedShipment] = useState();

  //set list of companies for super admin
  const setCompaniesFilter = useCallback(() => {
    apiService
      .getDashboardFilters('', selectedDateBasedOn)
      .then((result) => {
        if (result.data.reportCompanies) {
          setCompanies(result.data.reportCompanies);

          let company = result.data.reportCompanies.find((x) => x.id === companyId);
          if (company == null) {
            setCompanyId(result.data.reportCompanies[0].id);
            resetFilters(result.data.reportCompanies[0].accountId, selectedDateBasedOn);
          } else {
            setCompanyId(company.id);
            resetFilters(company.accountId, selectedDateBasedOn);
          }
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }, [history]);

  useEffect(() => {
    if (userContext.isAdmin === true) {
      setCompaniesFilter();
    } else {
      resetFilters(userContext.accountId, selectedDateBasedOn);
    }
  }, [reloadContext, history, userContext, setCompaniesFilter]);

  useEffect(() => {
    if (periods != null) {
      UpdateAddressUrl();
      ResendFilterComponentData();
      console.log('ResendFilterComponentData');
    }
  }, [postBackFilterDataCounter]);

  useEffect(() => {
    if (specialSearch) {
    }
  }, specialSearch);

  useImperativeHandle(ref, () => ({
    clearSpecialSearch() {
      clearSpecialSearch();
    },
  }));

  const clearSpecialSearch = (e) => {
    setSpecialSearchTitle('');
    setPortOriginCode('');
    setPortDestinationCode('');
    setTrans('');
    setMode('');
    setPostBackFilterDataCounter(new Date());
  };

  const handleCompanyChange = (e) => {
    setCompanyId(e.target.value);

    var selectedCompany = companies.find((x) => x.id === e.target.value);
    if (selectedCompany) {
      setFreeTextValue('');
      resetFilters(selectedCompany.accountId, selectedDateBasedOn);
    }

    // var value = companies.filter(function (item) {
    //   return item.id === e.target.value;
    // });

    // var selectedaccountId = value[0].accountId;

    // if (selectedaccountId) {
    //   setFreeTextValue('');
    //   resetFilters(value[0].accountId);
    // }
  };

  const handleStartPeriodChange = (e) => {
    setSelectedStartPeriodValue(e.target.value);
    setPostBackFilterDataCounter(new Date());
  };

  const handleEndPeriodChange = (e) => {
    setSelectedEndPeriodValue(e.target.value);
    setPostBackFilterDataCounter(new Date());
  };

  const handleSupplierValuesChange = (e) => {
    var value = e.target.value;

    setSelectedSupplierValues(value);
    setPostBackFilterDataCounter(new Date());
  };

  const handleFreeTextValueChange = (text) => {
    setFreeTextValue(text);
    setPostBackFilterDataCounter(new Date());
  };

  const handleDateBasedOnChange = (e) => {
    setSelectedDateBasedOn(e.target.value);
    resetFilters(accountId, e.target.value);
    // setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  };

  const handleShowFocusedShipmentChange = () => {
    setShowFocusedShipment(!showFocusedShipment);
    setPostBackFilterDataCounter(new Date());
  }

  function setDefaultPeriodValue(data) {
    if (data && data.length > 0) {
      let currentDate = moment(new Date());
      let currentMonthPeriodId = currentDate.format('YYYY-MM');

      let startPeriodId = selectedStartPeriodValue || currentMonthPeriodId;
      let endPeriodId = selectedEndPeriodValue || currentMonthPeriodId;

      //Set Start Period
      let startPeriodValue = data.find((x) => x.id === startPeriodId);
      if (startPeriodValue == null) {
        var startPeriodIndex = defaultPeriodMonths;
        while (startPeriodIndex > data.length - 1) {
          startPeriodIndex--;
        }
        setSelectedStartPeriodValue(data[startPeriodIndex].id);
      } else {
        setSelectedStartPeriodValue(startPeriodValue.id);
      }

      //Set End Period
      let endPeriodValue = data.find((x) => x.id === endPeriodId);
      if (endPeriodValue == null) {
        setSelectedEndPeriodValue(data[0].id);
      } else {
        setSelectedEndPeriodValue(endPeriodValue.id);
      }
    } else {
      setSelectedStartPeriodValue('');
      setSelectedEndPeriodValue('');
    }
  }

  function resetFilters(selectedAccountId, selectedDateBasedOn) {
    apiService
      .getDashboardFilters(selectedAccountId, selectedDateBasedOn)
      .then((result) => {
        //Periods
        if (result.data.filterPeriods) {
          setDefaultPeriodValue(null);
          setPeriods(result.data.filterPeriods);
          setDefaultPeriodValue(result.data.filterPeriods);
        } else {
          setPeriods(null);
          setDefaultPeriodValue(null);
        }

        //Suppliers
        if (result.data.reportSuppliers) {
          let tempSuppliers = result.data.reportSuppliers.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

          tempSuppliers.forEach((item) => {
            item.name = helpService.truncateString(item.name, 40);
          });

          setSuppliers(tempSuppliers);
          let selectedSupplier = tempSuppliers.find((x) => x.id === selectedSupplierValues);
          if (selectedSupplier == null) {
            setSelectedSupplierValues('All');
          }
        } else {
          setSuppliers(null);
          setSelectedSupplierValues('All');
        }

        //Reset filter values
        setAccountId(selectedAccountId);

        let selectedCompany = result.data.reportCompanies.find((x) => x.accountId === selectedAccountId);
        setCompanyId(selectedCompany.id);
        setAccountName(selectedCompany.name);

        setPostBackFilterDataCounter(new Date());
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }

  function ResendFilterComponentData() {
    let selectedSupplierIds = GetSelectedSupplierId();
    var selectedStartPeriod = periods.find((x) => x.id === selectedStartPeriodValue);
    var selectedEndPeriod = periods.find((x) => x.id === selectedEndPeriodValue);
    var companyName = userContext.isAdmin ? companies.find((x) => x.id === companyId).name : '';
    var supplierName = GetSelectedSupplierName();

    var periodName = '';
    if (selectedStartPeriod === '' && selectedEndPeriod === '') {
      periodName = 'All';
    } else {
      var startPeriodText = selectedStartPeriod == null ? 'Past' : selectedStartPeriod.displayName;
      var endPeriodText = selectedEndPeriod == null ? 'Present' : selectedEndPeriod.displayName;
      periodName = startPeriodText + ' to ' + endPeriodText;
    }

    const filterComponentData = {
      companyName: companyName,
      accountId: accountId,
      accountName: accountName,
      periodName: periodName,
      start: selectedStartPeriod?.periodStart,
      end: selectedEndPeriod?.periodEnd,
      freeTextSearch: freeTextValue,
      supplierName: supplierName,
      suppliers: selectedSupplierIds,
      dateBasedOn: selectedDateBasedOn,
      specialSearch: {
        title: specialSearchTitle,
        portOriginCode: portOriginCode,
        portDestinationCode: portDestinationCode,
        trans: trans,
        mode: mode,
      },
      showFocusedShipment: showFocusedShipment
    };

    props.sendFilterComponentData(filterComponentData);
  }

  function GetSelectedSupplierName() {
    if (selectedSupplierValues === null || selectedSupplierValues === 'All') {
      return 'All';
    } else {
      let selectedSupplierName = suppliers.find((x) => x.id === selectedSupplierValues).name;
      return selectedSupplierName;
    }
  }

  function GetSelectedSupplierId() {
    if (selectedSupplierValues === null || selectedSupplierValues === 'All') {
      return '';
    } else {
      let selectedSupplierId = suppliers.find((x) => x.id === selectedSupplierValues).id;
      return selectedSupplierId;
    }
  }

  function disableStartPeriodMenuItem(periodItem) {
    if (selectedEndPeriodValue) {
      let selectedEndPeriod = periods.find((x) => x.id === selectedEndPeriodValue);
      if (selectedEndPeriod) {
        let endDate = new Date(selectedEndPeriod.periodEnd);
        let startDate = new Date(periodItem.periodStart);
        return startDate > endDate;
      }
    }
    return false;
  }

  function disableEndPeriodMenuItem(periodItem) {
    if (selectedStartPeriodValue) {
      let selectedStartPeriod = periods.find((x) => x.id === selectedStartPeriodValue);
      if (selectedStartPeriod) {
        let startDate = new Date(selectedStartPeriod.periodStart);
        let endDate = new Date(periodItem.periodEnd);
        return startDate > endDate;
      }
    }
    return false;
  }

  function UpdateAddressUrl() {
    let pathName = history.location.pathname;
    var queryString = getQueryStrings(true);
    if (queryString) {
      queryString = '?' + queryString;
    }

    window.history.replaceState(null, null, pathName + queryString);

    if (props.sendQueryStringsNoFreeTextSearch) {
      var queryStringNoFreeSearch = getQueryStrings();
      props.sendQueryStringsNoFreeTextSearch(queryStringNoFreeSearch);
    }
  }

  function getQueryStrings(includeFreeTextSearch) {
    var s = freeTextValue;
    var b = selectedDateBasedOn;
    var from = selectedStartPeriodValue;
    var to = selectedEndPeriodValue;
    var cId = companyId;
    var supId = selectedSupplierValues;

    var searches = [];
    if (includeFreeTextSearch && s) {
      searches.push('s=' + s);
    }
    if (from) {
      searches.push('from=' + from);
    }
    if (to) {
      searches.push('to=' + to);
    }
    if (supId) {
      searches.push('supId=' + supId);
    }
    if (b) {
      searches.push('b=' + b);
    }

    if (cId && userContext.isAdmin) {
      searches.push('cId=' + cId);
    }
    var queryString = searches.map((item) => item).join('&');

    return queryString;
  }

  return (
    <Grid container direction="row" spacing={2} className={classes.root}>
      {specialSearchTitle && (
        <Grid item xs={12} className="align-self-center">
          <h5 className={classes.specialSearch}>
            <b>Filter:</b>{' '}
            <div className={classes.filterTag}>
              {specialSearchTitle} <CancelIcon onClick={clearSpecialSearch} />
            </div>
          </h5>
        </Grid>
      )}

      {showFocused && (
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl + ' ' + classes.checkBlockControl + ' exclude'}>
            <FormControlLabel
              className={classes.checkBox}
              checked={showFocusedShipment}
              onChange={handleShowFocusedShipmentChange}
              control={<CustomCheckbox name="showFocusedShipment" />}
              label="Show only my focused shipments."
            />
            <Tooltip arrow placement="top-end" title="Filter by your focused shipment.">
              <InfoIcon className={classes.icon} />
            </Tooltip>
          </FormControl>
        </Grid>
      )}

      {userContext.isAdmin ? (
        <Grid item xs={12} sm={6} lg={2}>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <InputLabel id="select-customer-label">
              <TitleFont> Customer</TitleFont>
            </InputLabel>
            <Select
              className={classes.select}
              labelId="select-customer-label"
              id="select-customer"
              label="Customer"
              value={(companies && companyId) || ''}
              onChange={(e) => handleCompanyChange(e)}
            >
              {companies &&
                companies.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      ) : null}

      {showSuppliers ? (
        <Grid item xs={12} sm={6} lg={3} xl>
          <Suppliers selectedSupplierValues={selectedSupplierValues} handleSupplierValuesChange={handleSupplierValuesChange} suppliers={suppliers} />
        </Grid>
      ) : (
        ''
      )}

      {showDateBasedOn && (
        <Grid item xs={12} lg={1}>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <InputLabel id="basedOnLabel">
              <TitleFont> Based On</TitleFont>
            </InputLabel>
            <Select labelId="groupByLabel" id="demo-simple-select-outlined" label="Based On" value={selectedDateBasedOn} onChange={handleDateBasedOnChange}>
              <MenuItem value={dateBaseOns.eta}>{dateBaseOns.eta}</MenuItem>
              <MenuItem value={dateBaseOns.etd}>{dateBaseOns.etd}</MenuItem>
              <MenuItem value={dateBaseOns.ata}>{dateBaseOns.ata}</MenuItem>
              <MenuItem value={dateBaseOns.atd}>{dateBaseOns.atd}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6} lg={2}>
        <Period
          id={'period-start'}
          title={'Start'}
          selectedPeriodValue={selectedStartPeriodValue}
          handlePeriodChange={handleStartPeriodChange}
          periods={periods}
          disablePeriodMenuItem={disableStartPeriodMenuItem}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={2}>
        <Period
          id={'period-end'}
          title={'End'}
          selectedPeriodValue={selectedEndPeriodValue}
          handlePeriodChange={handleEndPeriodChange}
          periods={periods}
          disablePeriodMenuItem={disableEndPeriodMenuItem}
        />
      </Grid>

      {showFreeText ? (
        <Grid item xs={12} sm>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <DelayChangedTextField
              id="free-text-filter"
              label="Free Text Filter"
              variant="outlined"
              onValueChange={handleFreeTextValueChange}
              value={freeTextValue || ''}
            />
          </FormControl>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
});

export default DataTableFilter;
