import React from 'react';
import StandardButton from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primaryButton.fontColor,
    backgroundColor:  theme.palette.primaryButton.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.primaryButton.hoverBackgroundColor,
    },
  },
}))(Button);

const ColorButtonGrey = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#444444',
    },
  },
}))(Button);

const useMyButtonStyles = makeStyles((theme) => ({
  circularProgress: {
    height: '20px !important',
    width: '20px !important',
  },
}));

function MyButton(props) {
  const { theme, isSecondary, isLoading, children, ...remainProps } = props;
  const classes = useMyButtonStyles();
  return theme === 'dark' ? (
    <StandardButton variant="outlined" {...remainProps} disabled={isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </StandardButton>
  ) : isSecondary !== 'true' ? (
    <ColorButton variant="contained" color="primary" {...remainProps} disabled={isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </ColorButton>
  ) : (
    <ColorButtonGrey variant="contained" color="primary" {...remainProps} disabled={isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </ColorButtonGrey>
  );
}

export default MyButton;
