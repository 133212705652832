import { Grid, TextField, makeStyles, Typography, Button, withStyles, IconButton, Divider, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useBookingStore } from '../../../contexts/BookingContext';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section: {
        backgroundColor: '#0000001f',
        borderRadius: 8,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    formControl: {
        minWidth: 210,
    },
    formControlUnit: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: theme.palette.inputControl.backgroundColor,
        }
    },
    selectOutline: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.inputControl.backgroundColor,
    },
    input: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
    unitInput: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
    row: {
        padding: 12 + '!important',
        background: '#0000000f',
        borderRadius: 8,
        marginBottom: theme.spacing(0.5)
    },
}));

const AddButton = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        color: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primaryButton.hoverBackgroundColor,
        }
    },
}))((props) => (
    <Button
        {...props}
        variant="text"
        size="small"
        disableElevation
        disableRipple
        startIcon={<AddCircleIcon />}
    >
        Add reference numbers
    </Button>
));

const DeleteButton = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5),
        color: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primaryButton.hoverBackgroundColor,
        }
    },
}))((props) => (
    <IconButton
        aria-label="delete"
        {...props}>
        <DeleteIcon />
    </IconButton>
));

const SmallTextField = (props) => {
    const { width } = props;
    const classes = useStyles();
    return <TextField variant="outlined" InputProps={{
        className: classes.input, style: { width: width || 180 }
    }}{...props} />;
}

const SmallSelect = (props) => {
    const { width, menuItems, selectedValue, value, referenceNumberId, referenceNumberKey, handleInputChange } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(selectedValue || '');

    useEffect(() => {
        if (value) { setSelected(value) }
    }, [])

    useEffect(() => {
        if (selected !== value) {
            handleInputChange(referenceNumberId, referenceNumberKey, selected)
        }
    }, [selected])

    return (
        <FormControl className={classes.formControlUnit} style={{ width: width || 210 }}>
            <Select
                variant="outlined"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={selected}
                onChange={(e) => { setSelected(e.target.value) }}
                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
            >
                <MenuItem value=''>Number Type</MenuItem>
                {menuItems && menuItems.map((item, index) => <MenuItem key={`${item + index}`} value={`${item}`}>{item}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

function ReferenceNumbers(props) {
    const bookingStore = useBookingStore();
    const classes = useStyles();
    const [referenceNumbers, setReferenceNumbers] = useState(toJS(bookingStore.referenceNumbers));

    const tmpReferenceNumber = { id: "", country: "", numberType: "", number: "", issueDate: "", information: "" };
    const numberType = [`Customs Office Code (Override)`, `AMS Number`, `Under Bond Approval Reference Number`]

    const randomNumber = () => {
        const number = Math.floor(100000 + Math.random() * 900000);
        return String(number);
    }

    useEffect(() => {
        if (referenceNumbers && referenceNumbers.length < 1) {
            tmpReferenceNumber.id = randomNumber();
            setReferenceNumbers(prevState => [...prevState, tmpReferenceNumber]);
        }
    }, [])

    const handleAddButtonClick = () => {
        const newReferenceNumber = tmpReferenceNumber;
        newReferenceNumber.id = randomNumber();
        setReferenceNumbers(prevState => [...prevState, newReferenceNumber]);
    }

    const handleDelete = (referenceNumberId) => {
        let newReferenceNumber = referenceNumbers.filter(x => x.id !== referenceNumberId);
        console.log("newReferenceNumber", newReferenceNumber);
        setReferenceNumbers(newReferenceNumber);
    }

    useEffect(() => {
        if (referenceNumbers) {
            console.log("referenceNumbers", referenceNumbers);
            bookingStore.setReferenceNumbers(referenceNumbers);
            console.log("referenceNumbersStore", toJS(bookingStore.referenceNumbers));
        }
    }, [referenceNumbers])

    const handleInputChange = (referenceNumberId, key, value) => {
        const tmpReferenceNumbers = referenceNumbers.map(item => {
            if (item.id === referenceNumberId) {
                item[key] = value;
            }
            return item;
        })
        setReferenceNumbers(tmpReferenceNumbers);
    }

    return (
        <>
            <Grid item container xs={12} alignItems="center" className={classes.row} spacing={1}>
                {referenceNumbers.length > 0 && referenceNumbers.map((item, index) => <Grid item container xs={12} alignItems="center" key={`${item.id + index}`} spacing={1} >
                    <Grid item ><SmallTextField width={180} placeholder="Country" value={item.country} onChange={(e) => handleInputChange(item.id, 'country', e.target.value)} /></Grid>
                    <Grid item><SmallSelect menuItems={numberType} width={255} value={item.numberType} referenceNumberId={item.id} referenceNumberKey={'numberType'} handleInputChange={handleInputChange} /></Grid>
                    <Grid item><SmallTextField width={120} placeholder="Number" value={item.number} onChange={(e) => handleInputChange(item.id, 'number', e.target.value)} /></Grid>
                    <Grid item><SmallTextField type="date" width={180} placeholder="Issue Date" value={item.issueDate} onChange={(e) => handleInputChange(item.id, 'issueDate', e.target.value)} /></Grid>
                    <Grid item xs><SmallTextField fullWidth width={'100%'} placeholder="Information" value={item.information} onChange={(e) => handleInputChange(item.id, 'information', e.target.value)} /></Grid>
                    <Grid item><DeleteButton onClick={() => handleDelete(item.id)} /></Grid>
                </Grid>
                )}
                <Grid item xs={12}>
                    <AddButton onClick={handleAddButtonClick} />
                </Grid>
            </Grid>
        </>
    );
}

export default ReferenceNumbers;