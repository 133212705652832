import { makeStyles, withWidth } from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MyTab, MyTabs, TabPanel } from '../../parts/MyTabs';
import FileSentList from './FileSentList';
import FileReceivedList from './FileReceivedList';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fixHeightTabContent: {
    height: 493,
  },
  tabPanel: {
    borderBottomRightRadius: 8,
  },
}));

function TabsContainer(props) {
  const { width, filterValue } = props;
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTabContentClassName = () => {
    if (width === 'md' || width === 'lg' || width === 'xl') {
      return classes.fixHeightTabContent;
    } else {
      return '';
    }
  };

  useEffect(() => {
    setValue(0);
  }, [filterValue]);

  return (
    <div className={classes.root}>
      <MyTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
        <MyTab label="Files Received" />
        <MyTab label="Files Sent" />
      </MyTabs>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <FileReceivedList filterValue={filterValue} />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel}>
        <FileSentList filterValue={filterValue} />
      </TabPanel>
    </div>
  );
}

TabsContainer.propTypes = {
  width: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']).isRequired,
};

export default withWidth()(TabsContainer);
