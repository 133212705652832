import React, { useRef, useContext, useState } from 'react';
import AdminDrawer from '../layouts/AdminDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { darkTheme } from '../../themes/darkTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightThemeAdmin } from '../../themes/lightTheme';
import LoadingBar from 'react-top-loading-bar';
import Container from '@material-ui/core/Container';
import TopRedBar from '../layouts/TopRedBar';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import Grid from '@material-ui/core/Grid';
import TabsContainer from './EDIReport/TabsContainer';
import { MyFeaturesContext } from '../../contexts/MyFeaturesContext';
import SentReceivedChart from './EDIReport/SentReceivedChart';
import EDIReportFilter from './EDIReport/EDIReportFilter';
import FeatureDisabledMessage from '../parts/FeatureDisabledMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
    maxWidth: '1600px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  featureDisabledMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

function EDIReport(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();
  const progressRef = useRef(null);
  const [myFeaturesContext] = useContext(MyFeaturesContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState([]);

  const handleSelectedFilterChanged = (selectedFilter) => {
    setFilterValue(selectedFilter);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <div className={classes.root}>
          <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
          <AdminDrawer />
          <main className={classes.content}>
            <TopRedBar />
            <Container maxWidth="lg" className={classes.container}>
              {myFeaturesContext &&
                (myFeaturesContext.enabledEDIReport ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={7} xl={8}>
                      <EDIReportFilter setIsLoading={setIsLoading} callbackSelectedFilterChanged={handleSelectedFilterChanged} />
                      <SentReceivedChart filterValue={filterValue} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={5} xl={4}>
                      <TabsContainer filterValue={filterValue} />
                    </Grid>
                  </Grid>
                ) : (
                  <FeatureDisabledMessage />
                ))}
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default EDIReport;
