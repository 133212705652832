import React, { useState, useRef, useContext } from 'react';
import AdminDrawer from '../layouts/AdminDrawer';
import { makeStyles } from '@material-ui/core/styles';
import StandardButton from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/styles';
import { darkTheme } from '../../themes/darkTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { lightThemeAdmin } from '../../themes/lightTheme';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from '../../contexts/UserContext';
import { ReloadContext } from '../../contexts/ReloadContext';
import Container from '@material-ui/core/Container';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer.js';
import { Avatar, Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TopRedBar from '../layouts/TopRedBar';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import ChargeableWeightCalculator from './Home/ChargeableWeightCalculator';
import News from './Home/News';
import Articles from './Home/Articles';
import Grid from '@material-ui/core/Grid';
import CurrentLocalTimes from './Home/CurrentLocalTimes';
import CurrencyConverter from './Home/CurrencyConverter';
import LiveExchangeRates from './Home/LiveExchangeRates';
import FBXIndexes from './Home/FBXIndexes';
import StockIndexes from './Home/StockIndexes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
    maxWidth: '1600px',
  },
  spacer: {
    flex: '1 1 auto',
  },
  pdfButton: {
    marginTop: '10px',
    maxHeight: '200px',
    minWidth: '200px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

function Home(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const childRef = useRef();
  const classes = useStyles();
  const progressRef = useRef(null);
  const [userContext] = useContext(UserContext);
  const [reloadContext] = useContext(ReloadContext);
  const [showPDFButton, setshowPDFButton] = useState(true);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <div className={classes.root}>
          <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
          <AdminDrawer />
          <main className={classes.content}>
            <TopRedBar />
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FBXIndexes></FBXIndexes>
                </Grid>
                <Grid item xs={12}>
                  <StockIndexes></StockIndexes>
                </Grid>
                <Grid item xs={12} md={12} lg={5}>
                  <News></News>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Articles></Articles>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <CurrentLocalTimes></CurrentLocalTimes>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {/* <Grid item xs={12}>
                      <ChargeableWeightCalculator></ChargeableWeightCalculator>
                    </Grid> */}
                    <Grid item xs={12}>
                      <CurrencyConverter></CurrencyConverter>
                    </Grid>
                    <Grid item xs={12}>
                      <LiveExchangeRates></LiveExchangeRates>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Home;
