import React, { useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import SupplierEntityListFrame from './SupplierEntityListFrame';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles((theme) => ({
    bookingItem: {
        backgroundColor: '#0000001f',
        borderRadius: 8,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    bookingTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    detailsButton: {
        color: theme.palette.primaryButton.fontColor,
        backgroundColor: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            color: theme.palette.primaryButton.fontColor,
            backgroundColor: theme.palette.primaryButton.hoverBackgroundColor,
        },
    }
}));

function Bookings(props) {
    let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
    const progressRef = useRef(null);
    const classes = useStyles();

    return (
        <>
            <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
            <SupplierEntityListFrame>
                <h4>Bookings</h4>
                <Divider style={{ width: "100%" }} />
                <Grid container alignItems='center'>
                    <Grid item container xs={12} className={classes.bookingItem} alignItems="center">
                        <Grid item container xs>
                            <Grid item xs={12} className={classes.bookingTitle}>
                                <Typography variant='subtitle1'>Thailand, 10100 <ArrowRightAltIcon /> USA, 10024</Typography>
                                {/* <Typography variant='subtitle1'>Thailand, 10100</Typography>
                                <ArrowRightAltIcon />
                                <Typography variant='subtitle1'>USA, 10024</Typography> */}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant='body2'>Type of move: LCL</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant='body2'>Load: 1 Box, 2 Pallets</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant='body2'>Total: 3 Units</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" className={classes.detailsButton} >
                                Details
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} className={classes.bookingItem} alignItems="center">
                        <Grid item container xs>
                            <Grid item xs={12} className={classes.bookingTitle}>
                                <Typography variant='subtitle1'>Thailand, 10100 <ArrowRightAltIcon /> USA, 10024</Typography>
                                {/* <Typography variant='subtitle1'>Thailand, 10100</Typography>
                                <ArrowRightAltIcon />
                                <Typography variant='subtitle1'>USA, 10024</Typography> */}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant='body2'>Type of move: LCL</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant='body2'>Load: 1 Box, 2 Pallets</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant='body2'>Total: 3 Units</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                            <Button variant="contained" color="primary" className={classes.detailsButton} >
                                Details
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </SupplierEntityListFrame>
        </>
    );
}

export default Bookings;