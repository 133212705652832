import React from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import authService from './services/auth.service';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  console.log(rest);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authService.getAuthenticationContext() && authService.getAuthenticationContext().user) {
          return <Component {...props} />;
        } else {
          var pathname = rest?.location?.pathname;
          var search = rest?.location?.search;
          console.log(`redirect pathname: ${pathname}, search: ${search}`);
          const loginLocation = {
            pathname: '/login',
            state: { returnToLocation: rest?.location },
          };
          history.push(loginLocation);
        }
      }}
    />
  );
};
