import { Divider, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useBookingStore } from '../../../contexts/BookingContext';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section: {
        backgroundColor: '#0000001f',
        borderRadius: 8,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: '0 8px',
    },
    formControl: {
        minWidth: 210,
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: theme.palette.inputControl.backgroundColor,
        }
    },
    selectOutline: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.inputControl.backgroundColor,
    },
    input: {
        minWidth: 210,
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
    smallTextFieldWidth: {
        width: "100%",
    }
}));

const SmallTextField = (props) => {
    const classes = useStyles();
    return <TextField variant="outlined" InputProps={{
        className: classes.input,
    }}{...props} />;
}

function FormBookingAddress(props) {
    const bookingStore = useBookingStore();
    const classes = useStyles();
    const [openPickupCompany, setOpenPickupCompany] = useState(false);
    const [openDeliveryCompany, setOpenDeliveryCompany] = useState(false);

    const [pickupData, setPickupData] = useState(toJS(bookingStore.pickupData));
    const [deliveryData, setDeliveryData] = useState(toJS(bookingStore.deliveryData));

    const mockPickupCompany = { company: "DEMO SUPPLIER", address: "2 DEMO PLACE", address2: "", passcode: "", city: "HONG KONG", state: "", country: "Hong Kong", contact: "", phone: "", fax: "", email: "", };
    const mockDeliveryCompany = { company: "DEMO CLIENT", address: "1 DEMO LANE", address2: "SYDNEY", passcode: "2000", city: "SYDNEY", state: "NSW", country: "Australia", contact: "Nicole", phone: "", fax: "", email: "nicole.leapai@verus-global.com", };

    useEffect(() => {
        console.log('====================================');
        console.log(toJS(bookingStore.pickupData));
        console.log('====================================');
        setPickupData(mockPickupCompany);
        setDeliveryData(mockDeliveryCompany);
    }, [])

    const handlePickupDataInputChange = (key, value) => {
        const tmpPickupData = { ...pickupData, [key]: value }
        setPickupData(tmpPickupData);
    }

    useEffect(() => {
        if (pickupData) {
            console.log('=========== pickupData =============');
            console.log(pickupData);
            bookingStore.setPickupData(pickupData);
            console.log("pickupData", toJS(bookingStore.pickupData));
        }
    }, [pickupData])

    const handleDeliveryDataInputChange = (key, value) => {
        const tmpDeliveryData = deliveryData.foreach(item => {
            item[key] = value;
        })
        setDeliveryData(tmpDeliveryData);
    }

    return (
        <div>
            <Grid container alignItems='center'>
                <Grid item container xs={12} className={classes.section} spacing={1} >
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Pickup Address</Typography></Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="body1">Company:</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="pickupcompany"
                                id="pickupcompany"
                                variant="outlined"
                                open={openPickupCompany}
                                onClose={() => setOpenPickupCompany(false)}
                                onOpen={() => setOpenPickupCompany(true)}
                                value={pickupData.company}
                                onChange={(e) => handlePickupDataInputChange('company', e.target.value)}
                                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                            >
                                <MenuItem value={"DEMO SUPPLIER"}>DEMO SUPPLIER</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Address:</Typography></Grid>
                    <Grid item container xs={12} md={10} spacing={1}>
                        <Grid item xs={10}><SmallTextField className={classes.smallTextFieldWidth} value={pickupData.address} onChange={(e) => handlePickupDataInputChange('address', e.target.value)} /></Grid>
                        {/* <Grid item xs={2}></Grid> */}
                        <Grid item xs={10}><SmallTextField className={classes.smallTextFieldWidth} value={pickupData.address2} onChange={(e) => handlePickupDataInputChange('address2', e.target.value)} /></Grid>
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Passcode:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.passcode} onChange={(e) => handlePickupDataInputChange('passcode', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">City:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.city} onChange={(e) => handlePickupDataInputChange('city', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">State:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.state} onChange={(e) => handlePickupDataInputChange('state', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Country:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.country} onChange={(e) => handlePickupDataInputChange('country', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Contact:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.contact} onChange={(e) => handlePickupDataInputChange('contact', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Phone:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.phone} onChange={(e) => handlePickupDataInputChange('phone', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Fax:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.fax} onChange={(e) => handlePickupDataInputChange('fax', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Email:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={pickupData.email} onChange={(e) => handlePickupDataInputChange('email', e.target.value)} /></Grid>
                </Grid>

                <Grid item container xs={12} className={classes.section} spacing={1} >
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Delivery Address</Typography></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Company:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="deliverycompany"
                                id="deliverycompany"
                                variant="outlined"
                                open={openDeliveryCompany}
                                onClose={() => setOpenDeliveryCompany(false)}
                                onOpen={() => setOpenDeliveryCompany(true)}
                                value={deliveryData.company}
                                onChange={(e) => handleDeliveryDataInputChange('company', e.target.value)}
                                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                            >
                                <MenuItem value={"DEMO CLIENT"}>DEMO CLIENT</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Address:</Typography></Grid>
                    <Grid item container xs={12} md={10} spacing={1}>
                        <Grid item xs={10}><SmallTextField className={classes.smallTextFieldWidth} value={deliveryData.address} onChange={(e) => handleDeliveryDataInputChange('address', e.target.value)} /></Grid>
                        {/* <Grid item xs={2}></Grid> */}
                        <Grid item xs={10}><SmallTextField className={classes.smallTextFieldWidth} value={deliveryData.address2} onChange={(e) => handleDeliveryDataInputChange('address2', e.target.value)} /></Grid>
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Passcode:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.passcode} onChange={(e) => handleDeliveryDataInputChange('passcode', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">City:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.city} onChange={(e) => handleDeliveryDataInputChange('city', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">State:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.state} onChange={(e) => handleDeliveryDataInputChange('state', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Country:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.country} onChange={(e) => handleDeliveryDataInputChange('country', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Contact:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.contact} onChange={(e) => handleDeliveryDataInputChange('contact', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Phone:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.phone} onChange={(e) => handleDeliveryDataInputChange('phone', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Fax:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.fax} onChange={(e) => handleDeliveryDataInputChange('fax', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Email:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}><SmallTextField value={deliveryData.email} onChange={(e) => handleDeliveryDataInputChange('email', e.target.value)} /></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default FormBookingAddress;