import React, { useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import { useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ThemeProvider } from '@material-ui/styles';
import { lightTheme } from '../../../themes/lightTheme';
import ContentFont from '../../Typography/ContentFont';
import ChartLogo from '../../parts/ChartLogo';
import LoadingOverlay from '../../parts/LoadingOverlay';
import ChartNoData from '../../parts/ChartNoData.js';
import { Link } from 'react-router-dom';
import { format } from 'react-string-format';

const useStyles = makeStyles((theme) => ({
  chart: {
    height: '500px',
    marginTop: '20px',
    marginBottom: '0px',
  },
}));

function JobTypeSupplierChart(props) {
  const classes = useStyles();
  const [chartDataInput, setChartDataInput] = useState([]);
  const { FilterComponentData, baseQueryString } = props;
  const [scaleSet, setScaleSet] = useState(1);
  const [dataLength, setDataLength] = useState(0);
  const labelLimit = 3;
  const scaleWidth = 20;
  const [showNoData, setShowNoData] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);

  const history = useHistory();
  function hasChartData() {
    if (chartDataInput && chartDataInput.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function getDetailsPageUrl(origin, destination, originDestinationFullName, incoterm) {
    if (origin && destination) {
      return format('/shipments?s={4}&pOri={0}&pDest={1}&{2}&sst={3}', origin, destination, baseQueryString, originDestinationFullName, incoterm);
    } else {
      return '/shipments?' + baseQueryString;
    }
  }

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      setIsLoading(true);
      apiService
        .getIncotermPerPortPairReport(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            if (result.data.incotermPerPortPairReportItems != '' && result.data.incotermPerPortPairReportItems.length > 0) {
              setDataLength(result.data.incotermPerPortPairReportItems.length);
              if (result.data.incotermPerPortPairReportItems.length > scaleWidth) {
                setScaleSet((scaleWidth / result.data.incotermPerPortPairReportItems.length).toFixed(1));
              } else {
                setScaleSet(1);
              }
              setShowNoData(false);
            } else {
              setDataLength(0);
              setScaleSet(1);
              setShowNoData(true);
            }

            setChartDataInput(result.data.incotermPerPortPairReportItems);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  useLayoutEffect(() => {
    var chart;

    if (hasChartData()) {
      var colorList = [];
      for (var i = 0; i < lightTheme.chart.colors.length; i++) {
        colorList.push(am4core.color(lightTheme.chart.colors[i]));
      }

      chart = am4core.create('SeriesXYChartIncotermPPP', am4charts.XYChart);
      chart.logo.disabled = true;
      chart.colors.list = colorList;

      // Add data
      chart.data = prepareStuff(chartDataInput);

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'portpair';
      categoryAxis.title.text = '';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 15;
      if (dataLength > labelLimit) {
        categoryAxis.renderer.labels.template.horizontalCenter = 'right';
        categoryAxis.renderer.labels.template.verticalCenter = 'middle';
        categoryAxis.fontSize = 11;
      } else categoryAxis.fontSize = 14;

      categoryAxis.renderer.labels.template.rotation = dataLength < labelLimit ? 0 : 315;
      categoryAxis.renderer.minHeight = 110;
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;
      valueAxis.min = 0;
      valueAxis.title.text = '';

      // Create series
      function createSeries(field, name, stacked) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.sequencedInterpolation = true;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = 'portpair';
        series.dataFields.categoryX2 = 'portpairfullname';
        series.name = name;
        series.tooltipText = '{categoryX2} - {name}: [bold]{valueY}[/]';
        series.stacked = stacked;
        series.columns.template.strokeWidth = 0;
        series.columns.template.column.fillOpacity = 0.8;
        series.columns.template.url = getDetailsPageUrl('{origin}', '{destination}', '{portpairfullname}', field);
        series.fontSize = 10;
      }

      createSeries('CIF', 'CIF', true);
      createSeries('DDP', 'DDP', true);
      createSeries('EXW', 'EXW', true);
      createSeries('FCA', 'FCA', true);
      createSeries('FOB', 'FOB', true);

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.visible = scaleSet < 1;
      chart.scrollbarX.end = scaleSet;

      chart.cursor = new am4charts.XYCursor();
    }
    return () => {
      if (chart) chart.dispose();
    };
  }, [chartDataInput, showNoData]);

  const prepareStuff = (data) => {
    var tempArray1 = [];

    for (var i = 0; i < data.length; i++) {
      let obj = {};
      obj = {
        portpair: data[i].origin + '-' + data[i].destination,
        portpairfullname: data[i].originFullName + ' - ' + data[i].destinationFullName,
        origin: data[i].origin,
        destination: data[i].destination,
        EXW: 0,
        FOB: 0,
        DDP: 0,
        CIF: 0,
        FCA: 0,
      };
      for (var x = 0; x < data[i].incotermPerPortPairReportItemIncoterms.length; x++) {
        var propertyName = data[i].incotermPerPortPairReportItemIncoterms[x].incoterm;
        obj[propertyName] = data[i].incotermPerPortPairReportItemIncoterms[x].count;
      }

      tempArray1.push(obj);
    }

    return tempArray1;
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Card chart>
        <LoadingOverlay IsLoading={IsLoading} />
        <CardHeader className={'dashboard-chart-area'}>
          <ContentFont>{showNoData ? <ChartNoData /> : <div id="SeriesXYChartIncotermPPP" className={classes.chart}></div>}</ContentFont>
        </CardHeader>
        <CardBody className={'dashboard-chart-title-area'}>
          <Link to={getDetailsPageUrl()}>
            <h4>Incoterm Per Port-Pair</h4>
          </Link>
          <ChartLogo />
        </CardBody>
      </Card>
    </ThemeProvider>
  );
}

export default JobTypeSupplierChart;
