import { FormControl, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import DelayChangedTextField from '../../wrappers/DelayChangedTextField';
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
}));

const EntityTableFilter = (props) => {
  let { label = 'Free Text Filter', handleFilterChanged } = props;
  const classes = useStyles();
  const [freeTextValue, setFreeTextValue] = useState();

  const handleFreeTextValueChange = (text) => {
    setFreeTextValue(text);
    if (handleFilterChanged) {
      handleFilterChanged({ freeText: text });
    }
  };

  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12} sm>
        <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
          <DelayChangedTextField id="free-text-filter" label={label} variant="outlined" onValueChange={handleFreeTextValueChange} value={freeTextValue || ''} />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default EntityTableFilter;
