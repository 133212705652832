import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import MyButton from '../../../wrappers/Button';
import LoadingOverlay from '../../parts/LoadingOverlay';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
    backgroundColor: theme.palette.modal.backgroundColor,
    position: 'relative',
    maxWidth: '100vw',
  },
  searchResultContainer: {
    padding: theme.spacing(2),
  },
  shipmentTrackingsContainer: {
    maxHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',
    '& .infinite-scroll-component__outerdiv': {
      width: '100%',
    },
  },
  shipmentTrackingRow: {
    padding: theme.spacing(1),
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primaryButton.backgroundColor,
      color: theme.palette.primaryButton.fontColor,
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchNotFound: {
    width: '100%',
    textAlign: 'center',
  },
  infiniteScroll: {
    width: '100%',
    overflowX: 'hidden !important',
  },
  infiniteScrollGridContainer: {
    width: '100%',
    margin: 0,
  },
  productCodesContainer: {
    backgroundColor: theme.palette.shipmentTracking.searchResult.productCodesContainer.backgroundColor,
    display: 'block',
    margin: '1px 16px',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    overflowWrap: 'break-word',
    lineHeight: '38px',
  },
  productCodeItem: {
    backgroundColor: theme.palette.shipmentTracking.searchResult.productCodeItem.backgroundColor,
    padding: '4px 8px',
    margin: 8,
    borderRadius: 20,
    whiteSpace: 'nowrap',
  },
}));

export default function SearchResultModal(props) {
  const { totalItems, items, hasMoreItems, loadMoreItems, searchText, onClose, handleSelectedShipmentTrackingId } = props;

  const classes = useStyles();

  const handleRowClicked = (shipmentTrackingId) => (e) => {
    e.preventDefault();
    handleSelectedShipmentTrackingId(shipmentTrackingId);
    onClose();
  };

  const getTitle = () => {
    if (searchText && searchText.trim() !== '') {
      return totalItems + ' results for "' + searchText + '"';
    } else {
      return 'All ' + totalItems + ' results';
    }
  };

  const fetchMoreData = () => {
    if (loadMoreItems) {
      loadMoreItems();
    }
  };

  const prepareProductItems = () => {};

  return (
    <Paper className={classes.root}>
      <LoadingOverlay IsLoading={props.isLoading} />
      <Grid container spacing={4} direction="row" justify="flex-start" alignItems="flex-start" alignItems="stretch" className={classes.searchResultContainer}>
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h6">{getTitle()}</Typography>
        </Grid>
        <Grid id="scrollableDiv" item container xs={12} spacing={1} className={classes.shipmentTrackingsContainer}>
          <InfiniteScroll
            className={classes.infiniteScroll}
            scrollableTarget="scrollableDiv"
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMoreItems}
            loader={<h5 style={{ display: 'flex', justifyContent: 'center' }}>Loading...</h5>}
          >
            <Grid container xs={12} spacing={1} className={classes.infiniteScrollGridContainer}>
              {items && items.length > 0 ? (
                items.map((shipmentTracking, index) => {
                  return (
                    <Grid item xs={12} key={'result' + index}>
                      <a onClick={handleRowClicked(shipmentTracking.id)}>
                        <Paper className={classes.shipmentTrackingRow}>
                          <Grid container>
                            <Grid item xs={12} sm={6} lg={3}>
                              <b>{index + 1}. Shipment No.: </b>
                              {shipmentTracking.shipmentNumber}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                              <b>Order No.: </b>
                              {shipmentTracking.orderNumbers}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                              <b>Container No.: </b>
                              {shipmentTracking.containerNumbers}
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                              <b>House Waybill No.: </b>
                              {shipmentTracking.houseWaybillNumber}
                            </Grid>
                          </Grid>
                        </Paper>
                      </a>
                      {shipmentTracking.orderProductItems && (
                        <p className={classes.productCodesContainer}>
                          {shipmentTracking.orderProductItems.map((orderProductItem, index) => {
                            return (
                              <span className={classes.productCodeItem}>
                                <b>{orderProductItem.lineNumber}: </b>
                                <span>{orderProductItem.productCode}</span>
                              </span>
                            );
                          })}
                        </p>
                      )}
                    </Grid>
                  );
                })
              ) : (
                <p className={classes.searchNotFound}>Search not found</p>
              )}
            </Grid>
          </InfiniteScroll>
        </Grid>
        <Grid item xs={12} className={classes.buttonsContainer}>
          <MyButton isSecondary="true" onClick={onClose}>
            Cancel
          </MyButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
