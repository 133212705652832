import React, { useEffect, useRef, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { ReloadContext } from '../../../contexts/ReloadContext';
import { UserContext } from '../../../contexts/UserContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import DataTableFilter from '../../parts/DataTableFilter';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import moment from 'moment';
import ExportMenu from '../../../wrappers/ExportMenu';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { CSVLink } from 'react-csv';
import tableService from '../../../services/table.service';
import ColumnSelector from '../../parts/ColumnSelector';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import { Backdrop, CircularProgress } from '@material-ui/core';
import FilledSnackbar from '../../parts/FilledSnackbar';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  arrivedOnTime: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.onTime,
    color: 'white',
  },
  arrivedLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.late,
    color: 'white',
  },
  arrivedVeryLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.veryLate,
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgb(0 0 0 / 25%)',
  },
}));

const defaultHeadCells = [
  {
    id: 'ShipmentNumber',
    numeric: false,
    disablePadding: true,
    label: 'Shipment No.',
  },
  {
    id: 'OrderNumber',
    numeric: false,
    disablePadding: false,
    label: 'Order No.',
    minWidth: 145,
  },
  {
    id: 'SupplierName',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
    minWidth: 200,
  },
  {
    id: 'LoadAt',
    numeric: false,
    disablePadding: false,
    label: 'Load At',
  },
  {
    id: 'DischargeAt',
    numeric: false,
    disablePadding: false,
    label: 'Discharge At',
  },
  {
    id: 'Vessel',
    numeric: false,
    disablePadding: false,
    label: 'Vessel',
  },
  {
    id: 'Voyage',
    numeric: false,
    disablePadding: false,
    label: 'Voyage',
  },
  {
    id: 'Carrier',
    numeric: false,
    disablePadding: false,
    label: 'Carrier',
  },
  {
    id: 'ContainersText',
    numeric: false,
    disablePadding: false,
    label: 'Container Numbers',
  },
  {
    id: 'RequiredExWorksDate',
    numeric: true,
    disablePadding: false,
    label: 'Req. Ex Works',
  },
  {
    id: 'RequiredDeliveryDate',
    numeric: true,
    disablePadding: false,
    label: 'Req. Delivery',
  },
  {
    id: 'EstimatedDeliveryDate',
    numeric: true,
    disablePadding: false,
    label: 'Est. Delivery',
  },
  {
    id: 'ActualDeliveryDate',
    numeric: true,
    disablePadding: false,
    label: 'Act. Delivery',
  },
  {
    id: 'EstimatedExFactoryDate',
    numeric: true,
    disablePadding: false,
    label: 'Est. Ex Factory',
  },
  {
    id: 'ActualExFactoryDate',
    numeric: true,
    disablePadding: false,
    label: 'Act. Ex Factory',
  },
  {
    id: 'ETD',
    numeric: true,
    disablePadding: false,
    label: 'ETD',
  },
  {
    id: 'ATD',
    numeric: true,
    disablePadding: false,
    label: 'ATD',
  },
  {
    id: 'ETA',
    numeric: true,
    disablePadding: false,
    label: 'ETA',
  },
  {
    id: 'ATA',
    numeric: true,
    disablePadding: false,
    label: 'ATA',
  },
  {
    id: 'EstimatedCustomsClearedDate',
    numeric: true,
    disablePadding: false,
    label: 'Est. Customs Cleared',
  },
  {
    id: 'ActualCustomsClearedDate',
    numeric: true,
    disablePadding: false,
    label: 'Act. Customs Cleared',
  },
  {
    id: 'CTOAvailability',
    numeric: true,
    disablePadding: false,
    label: 'CTO Availability',
  },
  {
    id: 'DelayDeliveryInDays',
    numeric: true,
    disablePadding: false,
    label: 'Delay Delivery',
    tooltip: 'Req. Delivery - Act. Delivery',
  },
  {
    id: 'DelayExFactoryInDays',
    numeric: true,
    disablePadding: false,
    label: 'Delay Ex Factory',
    tooltip: 'Est. Ex Factory - Act. Ex Factory',
  },
  {
    id: 'DelayDepartureInDays',
    numeric: true,
    disablePadding: false,
    label: 'Delay Dep.',
    tooltip: 'ETD - ATD',
  },
  {
    id: 'DelayArrivalInDays',
    numeric: true,
    disablePadding: false,
    label: 'Delay Arv.',
    tooltip: 'ETA - ATA',
  },
];

function ExceptionsTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('ShipmentNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [baseQueryString, setBaseQueryString] = useState();
  const classes = useStyles();
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();

  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();
  const [myFeaturesContext, setMyFeaturesContext] = useContext(MyFeaturesContext);

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;

    apiService
      .getExceptionsTableReport(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers
      )
      .then((result) => {
        let contentRows = createTableListData(result.data.items);
        let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
        setHeadCells(filteredTable.headCells);
        setRows(filteredTable.contentRows);
        setTotalNumberOfRows(result.data.count);
        setHiddenColumnNames(result.data.hiddenColumnNames);
        progressRef.current.complete();
        handleBackdropClose();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) {
          progressRef.current.complete();
          console.log('error: ', err);
        }
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createDelayCell = (value, veryLateDays = 3) => {
    var delayCell = { text: value, className: '' };
    if (value !== null) {
      if (value > 0) {
        delayCell.text = `+${value}`;
        if (value <= veryLateDays) {
          delayCell.className = classes.arrivedLate;
        } else {
          delayCell.className = classes.arrivedVeryLate;
        }
      } else {
        delayCell.className = classes.arrivedOnTime;
      }
    }
    return delayCell;
  };

  const createTableListData = (items) => {
    let newArray = [];
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      let etd = moment(item.etd);
      let atd = moment(item.atd);
      let eta = moment(item.eta);
      let ata = moment(item.ata);
      let requiredExWorksDate = moment(item.requiredExWorksDate);
      let requiredDeliveryDate = moment(item.requiredDeliveryDate);
      let estimatedDeliveryDate = moment(item.estimatedDeliveryDate);
      let actualDeliveryDate = moment(item.actualDeliveryDate);
      let estimatedExFactoryDate = moment(item.estimatedExFactoryDate);
      let actualExFactoryDate = moment(item.actualExFactoryDate);
      let estimatedCustomsClearedDate = moment(item.estimatedCustomsClearedDate);
      let actualCustomsClearedDate = moment(item.actualCustomsClearedDate);
      let ctoAvailability = moment(item.ctoAvailability);

      var shipmentNumberLinks = item.shipmentNumber;

      if (myFeaturesContext && myFeaturesContext.enabledTrackAndTrace) {
        shipmentNumberLinks = { component: 'linkList', links: [] };
        shipmentNumberLinks.links.push({
          text: item.shipmentNumber,
          path: '/trackandtrace?s=' + item.shipmentNumber,
        });
      }

      var delayDeliveryInDays = createDelayCell(item.delayDeliveryInDays);
      var delayExFactoryInDays = createDelayCell(item.delayExFactoryInDays);
      var delayDepartureInDays = createDelayCell(item.delayDepartureInDays);
      var delayArrivalInDays = createDelayCell(item.delayArrivalInDays);

      var containers = { component: 'tags', texts: [] };
      if (item.containers) {
        item.containers.map((number) => {
          containers.texts.push({ text: number });
        });
      }

      let rowData = {
        id: item.shipmentNumber,
        ShipmentNumber: shipmentNumberLinks,
        OrderNumber: item.orderNumber,
        SupplierName: item.supplierName,
        LoadAt: item.loadAt,
        DischargeAt: item.dischargeAt,
        Vessel: item.vessel,
        Voyage: item.voyage,
        Carrier: item.carrier,
        ContainersText: containers,
        RequiredExWorksDate: requiredExWorksDate.isValid() ? requiredExWorksDate.format('L') : '',
        RequiredDeliveryDate: requiredDeliveryDate.isValid() ? requiredDeliveryDate.format('L') : '',
        EstimatedDeliveryDate: estimatedDeliveryDate.isValid() ? estimatedDeliveryDate.format('L') : '',
        ActualDeliveryDate: actualDeliveryDate.isValid() ? actualDeliveryDate.format('L') : '',
        DelayDeliveryInDays: delayDeliveryInDays,
        EstimatedExFactoryDate: estimatedExFactoryDate.isValid() ? estimatedExFactoryDate.format('L') : '',
        ActualExFactoryDate: actualExFactoryDate.isValid() ? actualExFactoryDate.format('L') : '',
        DelayExFactoryInDays: delayExFactoryInDays,
        ETD: etd.isValid() ? etd.format('L') : '',
        ATD: atd.isValid() ? atd.format('L') : '',
        DelayDepartureInDays: delayDepartureInDays,
        ETA: eta.isValid() ? eta.format('L') : '',
        ATA: ata.isValid() ? ata.format('L') : '',
        DelayArrivalInDays: delayArrivalInDays,
        EstimatedCustomsClearedDate: estimatedCustomsClearedDate.isValid() ? estimatedCustomsClearedDate.format('L') : '',
        ActualCustomsClearedDate: actualCustomsClearedDate.isValid() ? actualCustomsClearedDate.format('L') : '',
        CTOAvailability: ctoAvailability.isValid() ? ctoAvailability.format('L') : '',
      };

      newArray.push(rowData);
    });

    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getExceptionsTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generateExceptionsPDF(
            result.data.items,
            'Exceptions     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Exceptions Report',
            result.data.hiddenColumnNames
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getExceptionsTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generateExceptionsCsvData(
            result.data.items,
            filter.accountName + '_' + filter.periodName,
            result.data.hiddenColumnNames
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleColumnSelected = (headCells) => {
    setNewHeadCells(headCells);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: '',
    success: false,
  });

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <FilledSnackbar open={snackbar.open} autoHideDuration={4000} onClose={handleSnackbarClose} isSuccess={snackbar.success} message={snackbar.msg} />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <EntityListFrame>
        <Grid container className={classes.titleArea}>
          <Grid item xs={12} sm className="align-self-center">
            <h4>Exceptions</h4>
          </Grid>
          <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
            <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
          </Grid>
          <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
            <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTableFilter
            sendFilterComponentData={filterCallback}
            sendQueryStringsNoFreeTextSearch={setBaseQueryString}
            showSuppliers={true}
            showFocused={false}
          />
        </Grid>
        <Divider />

        <Grid item xs={12}>
          {rows && (
            <DataContentTable
              title=""
              headCells={newHeadCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              page={page}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </EntityListFrame>
    </>
  );
}

export default withRouter(ExceptionsTable);
