import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ViewColumnIcon from '@material-ui/icons/ViewWeek';
import CloseIcon from '@material-ui/icons/Close';
import { UserContext } from '../../contexts/UserContext';
import tableService from '../../services/table.service';
import { useLocation } from 'react-router-dom';

const ColumnSelectorCheckbox = withStyles((theme) => ({
  root: {
    '&$checked': {
      color: theme.palette.primaryButton.backgroundColor,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  columnButton: {
    color: theme.palette.primaryButton.backgroundColor,
    width: 38,
    height: 38,
    padding: 6,
    marginLeft: 8,
    '&:hover': {
      boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 80%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 80%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.8);',
    },
  },
  submitDialog: {
    backgroundColor: theme.palette.primaryButton.backgroundColor,
    color: theme.palette.primaryButton.fontColor,
    '&:hover': {
      backgroundColor: theme.palette.primaryButton.hoverBackgroundColor,
      color: theme.palette.primaryButton.fontColor,
    },
  },
  dialogPaper: {
    minWidth: 350,
  },
  dialogTitle: {
    paddingBottom: 0,
    '& h2': {
      fontWeight: '700',
    },
  },
  dialogContent: {
    paddingBottom: 24,
  },
  dialogContentText: {
    fontSize: 14,
  },
  closeIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#707070',
    padding: 0,
  },
}));

export default function ColumnSelector(props) {
  const classes = useStyles();
  let location = useLocation();
  const { headCells, handleColumnSelected, hiddenColumnNames } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [columnNames, setColumnNames] = useState([]);
  const [userContext] = useContext(UserContext);

  const tableName = location.pathname.substring(1);

  useEffect(() => {
    const newHeadCellsArray = createHeadCellsToCheckbox(headCells);
    setColumnNames(newHeadCellsArray);
  }, [userContext, headCells]);

  const createHeadCellsToCheckbox = (items) => {
    const hiddenColumns = tableService.getHiddenColumnsById(userContext.id, userContext.accountId, tableName);
    let newArray = [];
    items.map((item) => {
      if (hiddenColumns && hiddenColumns.length > 0 && hiddenColumns.includes(item.id)) {
        newArray.push({ ...item, checked: false });
      } else {
        newArray.push({ ...item, checked: true });
      }
    });
    return newArray;
  };

  useEffect(() => {
    if (hiddenColumnNames) {
      const newArray = columnNames.filter((x) => hiddenColumnNames.indexOf(x.id) === -1);
      setColumnNames(newArray);
    } else {
      const newHeadCellsArray = createHeadCellsToCheckbox(headCells);
      setColumnNames(newHeadCellsArray);
    }
  }, [hiddenColumnNames]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCheckboxChange = (event) => {
    const newArray = columnNames.map((item) => {
      if (item.id === event.target.name) {
        item.checked = event.target.checked;
      }
      return item;
    });
    setColumnNames(newArray);
    const hiddenHeadCellsIdArray = newArray.filter((x) => x.checked === false).map((x) => x.id);
    tableService.updateHiddenColumns(userContext.id, userContext.accountId, tableName, hiddenHeadCellsIdArray);
  };

  useEffect(() => {
    if (columnNames) {
      const headCellsSelectArray = columnNames.filter((x) => x.checked !== false);
      handleColumnSelected(headCellsSelectArray);
    }
  }, [columnNames]);

  return (
    <>
      <Tooltip title="Columns Selector" placement="top" arrow>
        <IconButton onClick={handleClickOpenDialog} color="default" classes={{ root: classes.columnButton }}>
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" classes={{ paper: classes.dialogPaper }}>
        <IconButton color="default" className={classes.closeIconButton} onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title" classes={{ root: classes.dialogTitle }}>
          {'Column Selector'}
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <DialogContentText
            classes={{ root: classes.dialogContentText }}
          >{`* Please select the columns you want to be displayed in the table.`}</DialogContentText>
          <FormGroup>
            <Grid container>
              {columnNames.map((item, index) => {
                if (item.id !== "FocusedShipments") {
                  return (
                    <Grid item xs={6} key={index}>
                      <FormControlLabel
                        control={<ColumnSelectorCheckbox checked={item.checked} name={item.id} onChange={handleCheckboxChange} className={classes.checkbox} />}
                        label={item.label}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </FormGroup>
        </DialogContent>
      </Dialog>
    </>
  );
}
