import React, { useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import { useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ContentFont from '../../Typography/ContentFont';
import ChartLogo from '../../parts/ChartLogo';
import { lightTheme } from '../../../themes/lightTheme';
import ChartNoData from '../../parts/ChartNoData.js';
import LoadingOverlay from '../../parts/LoadingOverlay.js';

const useStyles = makeStyles((theme) => ({
  chart: {
    height: 300,
    paddingTop: 40,
  },
  chartTitle: {
    height: 125,
  },
}));

function CustomCharges(props) {
  const classes = useStyles();
  const [chartDataInput, setChartDataInput] = useState([]);
  const { FilterComponentData } = props;
  const [showNoData, setShowNoData] = useState(false);
  const [showNoDataText, setShowNoDataText] = useState('');

  const history = useHistory();
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      if (FilterComponentData.suppliers !== '') {
        setShowNoData(true);
        setShowNoDataText('No Supplier Specific Data for This Chart');
        setChartDataInput([]);
        setIsLoading(false);
      } else if (FilterComponentData.dateBasedOn !== 'ETA') {
        setShowNoData(true);
        setShowNoDataText('This chart is based on ETA only.');
        setChartDataInput([]);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        setShowNoData(false);
        apiService
          .getIndividualChargedReport(
            FilterComponentData.accountId,
            FilterComponentData.dateBasedOn,
            FilterComponentData.start,
            FilterComponentData.end,
            FilterComponentData.suppliers
          )
          .then((result) => {
            if (!isCancelled) {
              setChartDataInput(result.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) history.push('/login');
          });
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  useLayoutEffect(() => {
    var chart;

    function hasChartData() {
      if (chartDataInput && chartDataInput['frt'] && chartDataInput['dst'] && chartDataInput['cds']) {
        return true;
      } else {
        return false;
      }
    }

    if (hasChartData()) {
      var colorList = [];
      for (var i = 0; i < lightTheme.chart.colors.length; i++) {
        colorList.push(am4core.color(lightTheme.chart.colors[i]));
      }

      chart = am4core.create('PieSeriesChanges', am4charts.PieChart);
      chart.logo.disabled = true;
      chart.data = prepareStuff(chartDataInput);

      // Add label
      chart.innerRadius = 50;
      chart.radius = 100;
      let label = chart.seriesContainer.createChild(am4core.Label);

      label.horizontalCenter = 'middle';
      label.verticalCenter = 'middle';
      label.fontSize = 11;

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.colors.list = colorList;
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'name';
      pieSeries.slices.template.tooltipText = "{fullName}: {value.percent.formatNumber('#.#')}%";
      pieSeries.fontSize = 11;
      pieSeries.alignLabels = false;
      pieSeries.labels.template.bent = true;
      pieSeries.labels.template.radius = 3;
      pieSeries.labels.template.padding(0, 0, 0, 0);

      pieSeries.ticks.template.disabled = true;
    }

    return () => {
      if (chart) chart.dispose();
    };
  }, [chartDataInput, showNoData]);

  const prepareStuff = (data) => {
    var tempArray = [];
    if (data['frt'] && data['dst'] && data['cds']) {
      tempArray.push({ name: 'FRT', fullName: 'Freight', value: data['frt'] });
      tempArray.push({ name: 'DST', fullName: 'Destination', value: data['dst'] });
      tempArray.push({ name: 'CDS', fullName: 'Customs Disbursement Charges', value: data['cds'] });
    }
    return tempArray;
  };

  return (
    <Card chart>
      <LoadingOverlay IsLoading={IsLoading} />
      <CardHeader className={'dashboard-chart-area'}>
        <ContentFont>
          {showNoData ? <ChartNoData text={showNoDataText} /> : <div id="PieSeriesChanges" className={classes.chart}></div>}
        </ContentFont>
      </CardHeader>
      <CardBody className={classes.chartTitle + ' ' + 'dashboard-chart-title-area'}>
        <h4>Freight, Destination and Customs Disbursement Charges</h4>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

export default CustomCharges;
