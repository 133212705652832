import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import EDIList from './EDIList';
import FileSearchBox from './FileSearchBox';
import moment from 'moment';
import fileDownload from 'js-file-download';

function FileSentList(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const { filterValue } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('DocumentNumber');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [serchCriteria, setSearchCriteria] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);

  const progressRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (serchCriteria && filterValue && filterValue.companyAccountId) getData();
  }, [rowsPerPage, page, serchCriteria, filterValue]);

  const handleSearchChanged = (data) => {
    setPage(0);
    setSearchCriteria(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : serchCriteria;
    apiService
      .getEDIFilesSentTable(filterValue.companyAccountId, data.start, data.end, order, orderBy, page * rowsPerPage, rowsPerPage, data.searchText)
      .then((result) => {
        setRows(createTableListData(result.data.items));
        setItems(result.data.items);
        setTotalNumberOfRows(result.data.count);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      var momentTime = moment(item.sentTime);
      newArray.push([item.ediId, item.documentNumber, momentTime.format('l LT')]);
    });
    return newArray;
  };

  const headCells = [
    {
      id: 'DocumentNumber',
      numeric: false,
      disablePadding: true,
      label: 'Id',
    },
    {
      id: 'SentTime',
      numeric: true,
      disablePadding: false,
      label: 'Sent Time',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const handleDownload = (event) => {
    var ediId = event.currentTarget.parentNode.dataset.tag;
    let ediItem = items.find((item) => item.ediId === ediId);
    var momentTime = moment(ediItem.sentTime);
    let fileName = `${ediItem.documentNumber}_sent_${momentTime.format('MM-DD-YYYY_HH-mm')}.xml`;
    apiService
      .downloadEDIFileById(ediId)
      .then((res) => {
        fileDownload(res, fileName);
      })
      .catch((err) => {
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FileSearchBox callbackSearchChanged={handleSearchChanged} />
        </Grid>
        <Grid item xs={12}>
          {rows === '' ? (
            ''
          ) : (
            <EDIList
              title=""
              headCells={headCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              page={page}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              downloadHandler={handleDownload}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default FileSentList;
