import { Grid, TextField, makeStyles, Typography, Button, withStyles, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section: {
        backgroundColor: '#0000001f',
        borderRadius: 8,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: '0 8px',
    },
    formControl: {
        minWidth: 210,
    },
    formControlUnit: {
        minWidth: 100,
        marginLeft: theme.spacing(0.5),
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: theme.palette.inputControl.backgroundColor,
        }
    },
    selectOutline: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.inputControl.backgroundColor,
    },
    input: {
        minWidth: 210,
        marginRight: theme.spacing(0.5),
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
}));

const AddButton = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        color: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primaryButton.hoverBackgroundColor,
        }
    },
}))((props) => (
    <Button
        {...props}
        variant="text"
        size="small"
        disableElevation
        disableRipple
        startIcon={<AddCircleIcon />}
    >
        Add order
    </Button>
));

const DeleteButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primaryButton.hoverBackgroundColor,
        }
    },
}))((props) => (
    <IconButton
        aria-label="delete"
        {...props}>
        <DeleteIcon />
    </IconButton>
));

const SmallTextField = (props) => {
    const classes = useStyles();
    return <TextField variant="outlined" InputProps={{
        className: classes.input,
    }}{...props} />;
}

function AttachedOrders(props) {
    const classes = useStyles();
    const [orders, setOrders] = useState([]);

    const tmpOrder = { id: "", orderNumber: "", date: "", goodsDescription: "" };

    const randomNumber = () => {
        const number = Math.floor(100000 + Math.random() * 900000);
        return String(number);
    }
    useEffect(() => {
        tmpOrder.id = randomNumber();
        setOrders(prevState => [...prevState, tmpOrder]);
    }, [])

    const handleAddButtonClick = () => {
        const newOrder = tmpOrder;
        newOrder.id = randomNumber();
        setOrders(prevState => [...prevState, newOrder]);
    }

    const handleDeleteOrder = (orderId) => {
        let newOrder = orders.filter(x => x.id !== orderId);
        console.log("newOrder", newOrder);
        setOrders(newOrder);
    }

    useEffect(() => {
        if (orders) {
            console.log("Order", orders);
        }
    }, [orders])

    const handleOrderNumberChange = (orderId, text) => {
        const tmpOrder = orders.map(order => {
            if (order.id === orderId) {
                order.orderNumber = text;
            }
            return order;
        })
        setOrders(tmpOrder);
    }

    return (
        <>
            <Grid item container xs={12} alignItems="center">
                {orders.length > 0 && orders.map((item, index) => <Grid item container xs={12} alignItems="center" key={index}>
                    <Grid item ><SmallTextField placeholder="Order Number" value={item.orderNumber} onChange={(e) => handleOrderNumberChange(item.id, e.target.value)} /></Grid>
                    <Grid item ><SmallTextField placeholder="Date" inputProps={{ readOnly: true }} value={item.date} /></Grid>
                    <Grid item xs><SmallTextField fullWidth placeholder="Goods Description" inputProps={{ readOnly: true }} value={item.goodsDescription} /></Grid>
                    <Grid item><DeleteButton onClick={() => handleDeleteOrder(item.id)} /></Grid>
                </Grid>)}
                <Grid item xs={4}>
                    <AddButton onClick={handleAddButtonClick} />
                </Grid>
            </Grid>
        </>
    );
}

export default AttachedOrders;