import React, { useEffect, useRef, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import EntityTable from '../../parts/EntityTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory, Redirect, useLocation } from 'react-router';
import DeleteDialog from '../../parts/DeleteDialog';
import { ProtectedRoute } from '../../../protected.route';
import { Switch, useRouteMatch } from 'react-router-dom';
import { ReloadContext } from '../../../contexts/ReloadContext';
import { UserContext } from '../../../contexts/UserContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import IconButton from '@material-ui/core/IconButton';
import PublicIcon from '@material-ui/icons/Public';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { withRouter } from 'react-router';

import moment from 'moment';
import ExternalApplicationEntry from './ExternalApplicationEntry';

function ExternalApplications(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;

  const [showFrameAsPaper, setShowFrameAsPaper] = useState(true);
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [userContext] = useContext(UserContext);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: '0',
    name: '',
  });
  const [reloadContext] = useContext(ReloadContext);
  const [dataList, setDataList] = React.useState();

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService
      .getExternalApplications(page + 1, rowsPerPage, order, orderBy)
      .then((result) => {
        setRows(createTableListData(result.data.list));
        setDataList(result.data.list);
        setTotalNumberOfRows(result.data.totalItems);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }, [order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  useEffect(() => {
    if (location.pathname.indexOf('add') > -1 || location.pathname.indexOf('edit') > -1 || location.pathname.indexOf('view') > -1) {
      setShowFrameAsPaper(false);
    } else {
      setShowFrameAsPaper(true);
    }
  }, [location]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      var supplierNames = { component: 'textList', texts: [] };
      if (item.supplierNames) {
        item.supplierNames.map((supplierName) => {
          supplierNames.texts.push({ text: supplierName });
        });
      } else {
        supplierNames.texts.push({ text: 'All Suppliers' });
      }

      newArray.push([item.id, item.accountName, item.name, item.contactName, item.contactEmail, supplierNames]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    let id = event.currentTarget.dataset.tag;
    let selectedItem = dataList.find((x) => x.id === id);
    setPendingDeleteAction({ show: true, id: id, name: selectedItem.name });
  };

  const handleDelete = (id) => {
    apiService
      .deleteExternalApplication(id)
      .then((result) => {
        setDummyCounter(dummyCounter + 1);
      })
      .catch((err) => {
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const handleAdd = () => {
    history.push('/external-applications/add');
  };

  const handleEdit = (event) => {
    history.push('/external-applications/' + event.currentTarget.dataset.tag + '/edit');
  };

  const handleView = (event) => {
    history.push('/external-applications/' + event.currentTarget.parentNode.dataset.tag + '/view');
  };

  const headCells = [
    {
      id: 'accountName',
      numeric: false,
      disablePadding: true,
      label: 'Account',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'contactName',
      numeric: false,
      disablePadding: false,
      label: 'Contact',
    },
    {
      id: 'contactEmail',
      numeric: false,
      disablePadding: false,
      label: 'Contact Email',
    },
    {
      id: 'supplierNames',
      numeric: false,
      disablePadding: false,
      label: 'Suppliers',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <EntityListFrame inPaper={showFrameAsPaper}>
        <Grid item xs={12}>
          {rows === '' ? (
            ''
          ) : (
            <Switch>
              {userContext.isAdmin && userContext.permissions.canManageExternalApplications ? (
                <ProtectedRoute exact path={`${match.path}/add`}>
                  <ExternalApplicationEntry action="add" />
                </ProtectedRoute>
              ) : (
                ''
              )}
              <ProtectedRoute exact path={`${match.path}/:id/edit`}>
                <ExternalApplicationEntry action="edit" />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}/:id/view`}>
                <ExternalApplicationEntry action="view" />
              </ProtectedRoute>
              <ProtectedRoute exact path={`${match.path}`}>
                <EntityTable
                  title="External API"
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  deleteHandler={handleDeleteRequest}
                  handleAdd={handleAdd}
                  handleEdit={handleEdit}
                  canAdd={userContext.isAdmin && userContext.permissions.canManageExternalApplications}
                  additionalRowAction={
                    <>
                      {userContext &&
                        userContext.permissions &&
                        !userContext.permissions.canManageExternalApplications &&
                        userContext.permissions.canReadExternalApplications && (
                          <IconButton aria-label="view" onClick={handleView}>
                            <VisibilityIcon size="small" />
                          </IconButton>
                        )}
                    </>
                  }
                />
              </ProtectedRoute>
              <Redirect to="/dashboard" />
              <ProtectedRoute />
            </Switch>
          )}
        </Grid>
        <DeleteDialog
          action={pendingDeleteAction}
          handleDelete={handleDelete}
          title={`Delete External API, ${pendingDeleteAction.name}?`}
          body="Are you sure you want to delete this external api? Once deleted it cannot be undone."
        />
      </EntityListFrame>
    </>
  );
}

export default withRouter(ExternalApplications);
