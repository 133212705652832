import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '../../wrappers/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Avatar from '@material-ui/core/Avatar';
import MenuFont from '../Typography/MenuFont';
import DataContentTableCell from './DataContentTableCell';
import { Grid } from '@material-ui/core';
import EntityTableFilter from './EntityTableFilter';

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={'tableHeader'}>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: theme.spacing(1),
  },

  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold',
  },
  addButton: {
    marginRight: 0,
  },
  avatar: {
    height: '35px',
    width: '35px',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, handleFilterChanged } = props;

  return (
    <>
      <Toolbar className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}>
        <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
          {props.title}
        </Typography>
        {!props.canAdd ? (
          ''
        ) : (
          <Button
            className={classes.addButton}
            theme={parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark'}
            onClick={props.handleAdd}
            startIcon={<AddCircleOutlineIcon />}
          >
            <MenuFont>ADD</MenuFont>
          </Button>
        )}
      </Toolbar>
      {handleFilterChanged && <EntityTableFilter handleFilterChanged={handleFilterChanged} />}
    </>
  );
};

EnhancedTableToolbar.propTypes = {};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  actionButtonEdit: {
    // marginRight: theme.spacing(1),
    color: green[500],
  },
  actionButtonDelete: {
    color: red[500],
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionCell: {
    minWidth: '130px',
  },
}));

export default function EntityTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { rows } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    props.handleRequestSort(isAsc ? 'desc' : 'asc', property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    props.handleChangeRowsPerPage(parseInt(event.target.value));
  };

  const emptyRows = 0;

  const getTableCell = (cell, key, num) => {
    let block = {
      key: key + '' + num,
      className: '',
    };
    let cellClassName = '';

    if (cell && cell.image) {
      block.component = 'image';
      block.image = cell.image;
    } else if (cell && cell.avatar) {
      block.component = 'avatar';
      block.src = cell.avatar;
      block.variant = 'square';
      block.className = classes.avatar;
    } else if (cell && cell.component) {
      cell.key = block.key;
      block = cell;
    } else if (cell && cell.text !== undefined && cell.className !== undefined) {
      block.text = cell.text;
      cellClassName = cell.className;
    } else {
      block.text = cell;
    }

    return (
      <TableCell align={getTableRowAlign(num)} key={'cell' + block.key}>
        {DataContentTableCell(block)}
      </TableCell>
    );
  };

  const getTableRowAlign = (num) => {
    return props.headCells[num - 1].numeric ? 'right' : 'left';
  };

  return (
    <>
      {/* <Paper elevation={5} className={classes.paper}> */}
      <EnhancedTableToolbar {...props} />
      <TableContainer>
        <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={props.headCells}
          />
          <TableBody>
            {rows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              let entityKey = row[0];
              let contentKey = row[0] + index;

              return (
                <TableRow role="checkbox" tabIndex={-1} key={entityKey}>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell component="th" id={labelId} scope="row" padding="none">
                    {row[1]}
                  </TableCell>

                  {row[2] !== undefined ? getTableCell(row[2], contentKey, 2) : null}
                  {row[3] !== undefined ? getTableCell(row[3], contentKey, 3) : null}
                  {row[4] !== undefined ? getTableCell(row[4], contentKey, 4) : null}
                  {row[5] !== undefined ? getTableCell(row[5], contentKey, 5) : null}
                  {row[6] !== undefined ? getTableCell(row[6], contentKey, 6) : null}
                  {row[7] !== undefined ? getTableCell(row[7], contentKey, 7) : null}
                  {row[8] !== undefined ? getTableCell(row[8], contentKey, 8) : null}
                  {row[9] !== undefined ? getTableCell(row[9], contentKey, 9) : null}
                  {row[10] !== undefined ? getTableCell(row[10], contentKey, 10) : null}
                  {row[11] !== undefined ? getTableCell(row[11], contentKey, 11) : null}
                  {row[12] !== undefined ? getTableCell(row[12], contentKey, 12) : null}
                  {row[13] !== undefined ? getTableCell(row[13], contentKey, 13) : null}
                  {row[14] !== undefined ? getTableCell(row[14], contentKey, 14) : null}
                  {row[15] !== undefined ? getTableCell(row[15], contentKey, 15) : null}

                  <TableCell align="right" className={classes.actionCell} data-tag={entityKey}>
                    {props.additionalRowAction &&
                      (typeof props.additionalRowAction === 'function' ? props.additionalRowAction(row, index) : props.additionalRowAction)}
                    {props.additionalRowAction2 &&
                      (typeof props.additionalRowAction2 === 'function' ? props.additionalRowAction2(row, index) : props.additionalRowAction2)}
                    {!props.canAdd ? (
                      ' '
                    ) : (
                      <IconButton aria-label="edit" data-tag={entityKey} onClick={props.handleEdit}>
                        <EditIcon size="small" className={classes.actionButtonEdit} />
                      </IconButton>
                    )}
                    {!props.canAdd ? (
                      ' '
                    ) : (
                      <IconButton aria-label="delete" data-tag={entityKey} onClick={props.deleteHandler}>
                        <DeleteIcon size="small" className={classes.actionButtonDelete} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={props.totalNumberOfRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {/* </Paper> */}
    </>
  );
}
