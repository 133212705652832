import { Divider, FormControl, Grid, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
import { useBookingStore } from '../../../contexts/BookingContext';
import AttachedOrders from './AttachedOrders';
import GoodsOrPacks from './GoodsOrPacks';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section: {
        backgroundColor: '#0000001f',
        borderRadius: 8,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: '0 8px',
    },
    formControl: {
        minWidth: 210,
    },
    formControlUnit: {
        minWidth: 100,
        marginLeft: theme.spacing(0.5),
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: theme.palette.inputControl.backgroundColor,
        }
    },
    selectOutline: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.inputControl.backgroundColor,
    },
    input: {
        minWidth: 210,
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
}));

const SmallTextField = (props) => {
    const classes = useStyles();
    return <TextField variant="outlined" InputProps={{
        className: classes.input,
    }}{...props} />;
}

function FormBookingContainerInfo(props) {
    const bookingStore = useBookingStore();
    const classes = useStyles();
    const [mode, setMode] = useState(bookingStore.mode);
    const [openMode, setOpenMode] = useState(false);
    const [openPacks, setOpenPacks] = useState(false);
    const [packUnit, setPackUnit] = useState('Pallet');
    const [openWeight, setOpenWeight] = useState(false);
    const [weightUnit, setWeightUnit] = useState('Kilograms');
    const [openVolume, setOpenVolume] = useState(false);
    const [volumeUnit, setVolumeUnit] = useState('Cubic Meters');

    const [containerInfo, setContainerInfo] = useState(toJS(bookingStore.containerInformation));

    const handleInputChange = (key, value) => {
        const tmpData = { ...containerInfo, [key]: value }
        setContainerInfo(tmpData);
    }

    useEffect(() => {
        if (containerInfo) {
            console.log('=========== containerInfo =============');
            console.log(containerInfo);
            bookingStore.setContainerInformation(containerInfo);
            console.log("containerInfo", toJS(bookingStore.containerInformation));
        }
    }, [containerInfo])

    const handleModeClose = () => {
        setOpenMode(false);
    };

    const handleModeOpen = () => {
        setOpenMode(true);
    };

    return (
        <div>
            <Grid container alignItems='center'>
                <Grid item container xs={12} className={classes.section} spacing={1} alignItems='center'>
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Container Information</Typography></Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12} md={2}><Typography variant="body1">Origin:</Typography></Grid>
                        <Grid item xs={12} md={10} lg={4}><SmallTextField value={containerInfo.origin} onChange={(e) => handleInputChange('origin', e.target.value)} /></Grid>
                        <Grid item xs={12} md={2}><Typography variant="body1">Destination:</Typography></Grid>
                        <Grid item xs={12} md={10} lg={4}><SmallTextField value={containerInfo.destination} onChange={(e) => handleInputChange('destination', e.target.value)} /></Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} md={2}><Typography variant="body1">Mode:</Typography></Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="mode"
                                    id="mode"
                                    variant="outlined"
                                    open={openMode}
                                    onClose={handleModeClose}
                                    onOpen={handleModeOpen}
                                    value={containerInfo.mode}
                                    onChange={(e) => handleInputChange('mode', e.target.value)}
                                    classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                                >
                                    <MenuItem value={"Air Freight (LSE)"}>Air Freight (LSE)</MenuItem>
                                    <MenuItem value={"Air Freight (ULD)"}>Air Freight (ULD)</MenuItem>
                                    <MenuItem value={"Sea Freight (LCL)"}>Sea Freight (LCL)</MenuItem>
                                    <MenuItem value={"Sea Freight (FCL)"}>Sea Freight (FCL)</MenuItem>
                                    <MenuItem value={"Road Freight (LCL/LTL)"}>Road Freight (LCL/LTL)</MenuItem>
                                    <MenuItem value={"Road Freight (FCL)"}>Road Freight (FCL)</MenuItem>
                                    <MenuItem value={"Road Freight (FTL)"}>Road Freight (FTL)</MenuItem>
                                    <MenuItem value={"Courier"}>Courier</MenuItem>
                                    <MenuItem value={"Rail Freight (LCL)"}>Rail Freight (LCL)</MenuItem>
                                    <MenuItem value={"Rail Freight (FCL)"}>Rail Freight (FCL)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Shipper's Ref#:</Typography></Grid>
                    <Grid item xs={12} md={10}><SmallTextField value={containerInfo.shipperRef} onChange={(e) => handleInputChange('shipperRef', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Order Ref#:</Typography></Grid>
                    <Grid item xs={12} md={10}><SmallTextField value={containerInfo.orderRef} onChange={(e) => handleInputChange('orderRef', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Goods Description:</Typography></Grid>
                    <Grid item xs={12} md={10}><SmallTextField value={containerInfo.goodsDescription} onChange={(e) => handleInputChange('goodsDescription', e.target.value)} /></Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} md={2}><Typography variant="body1">Packs:</Typography></Grid>
                        <Grid item xs={12} md={10} >
                            <SmallTextField value={containerInfo.pack} onChange={(e) => handleInputChange('pack', e.target.value)} />
                            <FormControl className={classes.formControlUnit}>
                                <Select
                                    labelId="packs"
                                    id="packs"
                                    variant="outlined"
                                    open={openPacks}
                                    onClose={() => setOpenPacks(false)}
                                    onOpen={() => setOpenPacks(true)}
                                    value={containerInfo.packUnit}
                                    onChange={(e) => handleInputChange('packUnit', e.target.value)}
                                    classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                                >
                                    <MenuItem value={"Pallet"}>Pallet</MenuItem>
                                    <MenuItem value={"Package"}>Package</MenuItem>
                                    <MenuItem value={"Unit"}>Unit</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} md={2}><Typography variant="body1">Weight:</Typography></Grid>
                        <Grid item xs={12} md={10} >
                            <SmallTextField value={containerInfo.weight} onChange={(e) => handleInputChange('weight', e.target.value)} />
                            <FormControl className={classes.formControlUnit}>
                                <Select
                                    labelId="weight"
                                    id="weight"
                                    variant="outlined"
                                    open={openWeight}
                                    onClose={() => setOpenWeight(false)}
                                    onOpen={() => setOpenWeight(true)}
                                    value={containerInfo.weightUnit}
                                    onChange={(e) => handleInputChange('weightUnit', e.target.value)}
                                    classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                                >
                                    <MenuItem value={"Kilograms"}>Kilograms</MenuItem>
                                    <MenuItem value={"Pounds"}>Pounds</MenuItem>
                                    <MenuItem value={"Milligrams"}>Milligrams</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} md={2}><Typography variant="body1">Volume:</Typography></Grid>
                        <Grid item xs={12} md={10} >
                            <SmallTextField value={containerInfo.volume} onChange={(e) => handleInputChange('volume', e.target.value)} />
                            <FormControl className={classes.formControlUnit}>
                                <Select
                                    labelId="volume"
                                    id="volume"
                                    variant="outlined"
                                    open={openVolume}
                                    onClose={() => setOpenVolume(false)}
                                    onOpen={() => setOpenVolume(true)}
                                    value={containerInfo.volumeUnit}
                                    onChange={(e) => handleInputChange('volumeUnit', e.target.value)}
                                    classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                                >
                                    <MenuItem value={"Liter"}>Liter</MenuItem>
                                    <MenuItem value={"Cubic Meters"}>Cubic Meters</MenuItem>
                                    <MenuItem value={"Mega Liter"}>Mega Liter</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item container xs={12} className={classes.section} spacing={1} alignItems='center'>
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Sailing Summary</Typography></Grid>
                    <Grid item xs={2}><Typography variant="body1">Voyage No.</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField value={voyageNo} onChange={(e) => { setVoyageNo(e.target.value); bookingStore.setVoyageNo(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">Vessel</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField value={vessel} onChange={(e) => { setVessel(e.target.value); bookingStore.setVessel(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">CFS Cut off</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField type='date' value={CFSCutOff} onChange={(e) => { setCFSCutOff(e.target.value); bookingStore.setCFSCutOff(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">CTO Cut off</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField type='date' value={CTOCutOff} onChange={(e) => { setCTOCutOff(e.target.value); bookingStore.setCTOCutOff(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">ETD</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField type='date' value={ETD} onChange={(e) => { setETD(e.target.value); bookingStore.setETD(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">ETA</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField type='date' value={ETA} onChange={(e) => { setETA(e.target.value); bookingStore.setETA(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">Carrier</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField value={carrier} onChange={(e) => { setCarrier(e.target.value); bookingStore.setCarrier(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">CFS Reference</Typography></Grid>
                    <Grid item xs={10} lg={4}><SmallTextField value={CFSReference} onChange={(e) => { setCFSReference(e.target.value); bookingStore.setCFSReference(e.target.value) }} /></Grid>
                    <Grid item xs={2}><Typography variant="body1">House Bill Number</Typography></Grid>
                    <Grid item xs={10}><SmallTextField value={houseBillNumber} onChange={(e) => { setHouseBillNumber(e.target.value); bookingStore.setHouseBillNumber(e.target.value) }} /></Grid>
                </Grid> */}
                <Grid item container xs={12} className={classes.section} spacing={1} alignItems='center'>
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Attached Orders</Typography></Grid>
                    <AttachedOrders />
                </Grid>
                <Grid item container xs={12} className={classes.section} spacing={1} alignItems='center'>
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Goods / Packs</Typography></Grid>
                    <GoodsOrPacks />
                </Grid>
            </Grid>
        </div >
    );
}

export default FormBookingContainerInfo;