import React, { useState, useContext, useRef, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import authService from '../../services/auth.service';
import { useHistory } from 'react-router';
import { UserContext } from '../../contexts/UserContext';
import LoadingBar from 'react-top-loading-bar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import apiService from '../../services/api.service';
import GoogleIcon from '@material-ui/icons/Google';
import { lightTheme } from '../../themes/lightTheme';
import { ThemeProvider } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import GoogleLogin from 'react-google-login';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '../../wrappers/Button';
import StandardButton from '@material-ui/core/Button';
import '../../index.css';
import clsx from 'clsx';
import { gapi } from "gapi-script"

function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayErrorText, setDisplayErrorText] = useState('');
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState('');
  const history = useHistory();
  const [, setContext] = useContext(UserContext);
  const inputRef = useRef();
  const defaultErrorText = "The username either doesn't exists or the password is wrong";
  const progressRef = useRef(null);
  const { returnToLocation } = props.location.state || { returnToLocation: { pathname: '/' } };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      height: 'calc(100vh)',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: 'auto',
      position: 'relative',
    },
    rootContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '100vw',
      marginLeft: '0px',
      border: '1px',
    },
    title: {
      fontStyle: 'italic',
    },
    googleIcon: {
      backgroundColor: '#fd397a',
      color: '#ffffff',
      margin: theme.spacing(1),
    },
    parallax: {
      height: '100vh',
      maxHeight: '1600px',
      overflow: 'hidden',
      position: 'relative',
      backgroundPosition: '50%',
      backgroundSize: 'cover',
      backgroundColor: '#e0204b',
      margin: '0',
      padding: '0',
      border: '0',
      display: 'flex',
      alignItems: 'center',
    },
    videobg: {
      width: '100vw',
      minWidth: '1300px',
      position: 'fixed',
    },
    arealoginbox: {
      maxWidth: '600px',
      padding: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    formloginBox: {
      width: '600px',
      backgroundColor: '#ffffff',
      padding: '40px 30px',
    },
    gridContainer: {
      paddingTop: '40px',
      marginLeft: '0px',
      width: '100%',
    },
    signInText: {
      fontFamily: theme.font.title,
    },
    contentText: {
      fontFamily: theme.font.content,
    },
    btnSubmit: {
      textTransform: 'none',
      fontFamily: theme.font.button,
    },
    googleBtnStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconGoogleStyle: {
      textTransform: 'none',
      width: '80%',
    },
    img_google: {
      margin: '0 10px 0 0',
    },
  }));

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const successResponseGoogle = (response) => {
    authService
      .loginGoogle(response.googleId, response.tokenObj.id_token)
      .then((result) => {
        apiService.getMe().then((result) => {
          setContext(result);
          setTimeout(() => {
            history.push(returnToLocation);
          }, 350);
          progressRef.current.complete();
        });
      })
      .catch((error) => {
        setAlertErrorMessage('No user registered with this social identity exists in the system. Please Sign Up first');
        setErrorAlertOpen(true);
        if (progressRef && progressRef.current) progressRef.current.complete();
      });
  };

  const failedResponseGoogle = (response) => {
    console.log(response);
  };

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    progressRef.current.continuousStart();
    authService
      .login(email, password)
      .then((result) => {
        apiService.getMe().then((result) => {
          setContext(result);
          setTimeout(() => {
            history.push(returnToLocation);
          }, 350);
          progressRef.current.complete();
        });
      })
      .catch((error) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        setDisplayErrorText(defaultErrorText);
        try {
          if (error.response && error.response.data.errors[0].includes('confirmed')) {
            setDisplayErrorText(error.response.data.errors[0]);
          }
        } catch (err) { }
      });
  };

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <div className={classes.parallax}>
          <video className={classes.videobg} autoPlay loop muted>
            <source src="/images/background_video.mp4" type="video/mp4" />
            {/* <source src='/images/AstonMartinWallpaper.mp4' type='video/mp4' /> */}
            Your browser does not support the video tag
          </video>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={errorAlertOpen}
            onClose={() => {
              setErrorAlertOpen(false);
            }}
          >
            <Alert
              variant="outlined"
              onClose={() => {
                setErrorAlertOpen(false);
              }}
              severity="error"
            >
              {alertErrorMessage}
            </Alert>
          </Snackbar>
          <div className={classes.root}>
            <LoadingBar color={lightTheme.loadingBar.color} ref={progressRef} height="4px" />
            <div className={classes.rootContainer}>
              <Hidden mdDown>
                <div className={classes.arealoginbox}>
                  <div>
                    <img src="/images/VERUS-LOGO-WHITE3.png" alt="background" />
                  </div>
                </div>
              </Hidden>
              <div className={classes.formloginBox}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={4} alignItems="center" className={classes.gridContainer}>
                    <Grid item>
                      <Typography component="h5" variant="h5" color="inherit" noWrap className={classes.signInText}>
                        Sign In
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <TextField
                        className={classes.contentText}
                        label="Username"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        ref={inputRef}
                        fullWidth
                        autoFocus
                      />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <TextField
                        className={classes.contentText}
                        label="Password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!(displayErrorText === '')}
                        helperText={displayErrorText === '' ? '' : displayErrorText}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6} alignItems="center" justify="space-between" style={{ marginTop: '10px', marginLeft: '0px', width: '100%' }}>
                    <Grid item>
                      <StandardButton
                        className={classes.btnSubmit}
                        disableFocusRipple
                        disableRipple
                        variant="text"
                        color="primary"
                        onClick={() => history.push('/forgotpassword')}
                      >
                        Forgot password ?
                      </StandardButton>
                    </Grid>
                    <Grid item>
                      <Button className={classes.btnSubmit} type="submit" variant="contained" color="primary">
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container></Grid>
                  <div className="kt-divider">
                    <span></span>
                    <span className={classes.contentText}>OR</span>
                    <span></span>
                  </div>
                  <div className={classes.googleBtnStyle}>
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          variant="contained"
                          color="primary"
                          className={clsx(classes.googleIcon, classes.contentText, classes.iconGoogleStyle)}
                          startIcon={<GoogleIcon />}
                        >
                          Google
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={successResponseGoogle}
                      onFailure={failedResponseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Login;
