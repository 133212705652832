import { createMuiTheme } from '@material-ui/core';
import { themeConstant } from './themeConstant';

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ea2956',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    adminDrawer: {
      main: '#303030',
      text: '#fff',
      button: '#fff',
      topButton: '#e77',
      drawer: '#424242',
      divider: '#595959',
    },
    background: {
      default: '#000000',
    },
    exportMenu: {
      backgroundColor: '#ea2956',
      fontColor: '#fff',
    },
    primaryButton: {
      backgroundColor: '#ea2956',
      hoverBackgroundColor: '#ef5176',
      fontColor: '#fff',
    },
    myTab: {
      backgroundColor: '#424242',
      borderColor: '#424242',
      activeTabFontColor: '#FFFFFF',
      tabs: {
        marginLeft: 0,
      },
    },
    inputControl: {
      backgroundColor: '#6b6b6b',
      borderColor: '#777777',
    },
    modal: {
      backgroundColor: '#606060',
    },
    shipmentTracking: {
      shipmentDetails: {
        titleColor: '#FFF',
        hightlightRowBackgroundColor: '#6b6b6b',
      },
      documents: {
        fontColor: '#FFF',
      },
      searchResult: {
        productCodesContainer: {
          backgroundColor: '#242526',
        },
        productCodeItem: {
          backgroundColor: '#606060',
        },
      },
      trackingEvents: {
        highlightColor: '#ea2956',
        unHighlightColor: '#bdbdbd',
        iconColor: '#424242'
      }
    },
    topRedBar: {
      backgroundColor: '#242526',
      logoImgFilter: 'invert(37%) sepia(95%) saturate(6212%) hue-rotate(334deg) brightness(98%) contrast(89%)',
    },
    trendsOverTime: {
      enabledComparePeriodBackgroundColor: '#242526',
      moreFilterSection: '#242526',
      mainBackgroundColor: '#000000',
      tabBackgroundColor: '#424242',
    },
    textFieldExternalApplicationKey: {
      backgroundColor: '#606060',
      titleBackgroundColor: '#272C34',
    },
    externalEndpointListItem: {
      backgroundColor: '#606060',
    },
    currencyConverter: {
      convertedAmountTextBox: {
        backgroundColor: '#606060',
      },
      amountTextBox: {
        backgroundColor: '#606060',
      },
    },
    currentLocalTimes: {
      backgroundColor: '#424242',
    },
    stockIndexes: {
      priceColor: '#FFF',
      navigateButtonColor: '#ea2956',
    },
    news: {
      titleColor: '#FFF',
      subTitleColor: '#DDD',
    },
    liveExchangeRates: {
      fromContainerBackgroundColor: '#242526',
    },
    fbxIndexes: {
      ticker: {
        backgroundColor: '#424242',
      },
      lane: {
        color: '#999',
      },
    },
    subscribeToggleButton: {
      disabled: {
        color: '#aaa',
      },
      subscribed: {
        color: '#ea2956',
      },
      unsubscribed: {
        color: '#fff',
      },
    },
    focusShipmentCheckbox: {
      focused: {
        color: '#ea2956',
      },
      unfocused: {
        color: '#000',
      },
    },
    dataTable: {
      scrollBar: {
        backgroundColor: '#424242',
      },
    },
  },
  paletteChart: {
    type: 'dark',
    bg: {
      backgroundColor: '#EEE',
      color: 'rgb(0,0,15)',
    },
    text: {
      color: 'rgb(0,0,15)',
    },
  },
  ...themeConstant,
});
