import React, { useState, useEffect, useRef, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import EntityTable from '../../parts/EntityTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { ReloadContext } from '../../../contexts/ReloadContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { Snackbar, ThemeProvider } from '@material-ui/core';
import DownloadButton from '../../../wrappers/DownloadButton';
import fileDownload from 'js-file-download';
import FeatureDisabledMessage from '../../parts/FeatureDisabledMessage';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  alert: {
    margin: theme.spacing(1),
  },
  title: {
    fontStyle: 'italic',
  },
  buttons: {
    margin: theme.spacing(1),
  },
}));

function ReportFiles(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();

  const [myFeaturesContext] = useContext(MyFeaturesContext);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [reloadContext] = useContext(ReloadContext);
  const progressRef = useRef(null);
  const history = useHistory();
  const [snackbar, setSnackbar] = useState({
    open: false,
    isSuccess: false,
    message: '',
  });
  const [reportFiles, setReportFiles] = useState();

  useEffect(() => {
    progressRef.current.continuousStart();
    apiService
      .getReportFilesTable(page + 1, rowsPerPage, order, orderBy)
      .then((result) => {
        console.log(result);
        progressRef.current.complete();
        setReportFiles(result.data.list);
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
      })
      .catch((err) => {
        console.log('error: ', err);
        if (progressRef && progressRef.current) progressRef.current.complete();
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }, [history, order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      newArray.push([item.id, item.accountName, item.fileName]);
    });
    return newArray;
  };

  const headCells = [
    {
      id: 'accountName',
      numeric: false,
      disablePadding: true,
      label: 'Account',
    },
    {
      id: 'fileName',
      numeric: false,
      disablePadding: false,
      label: 'File Name',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    let snackbartemp = { ...snackbar };
    snackbartemp.open = false;
    setSnackbar(snackbartemp);
  };

  const downloadReportFileById = async (fileId) => {
    const setDownloadingStatus = (isDownloading) => {
      var tmpReportFiles = reportFiles.slice();
      let file = tmpReportFiles.find((x) => x.id === fileId);
      file.isDownloading = isDownloading;
      setReportFiles(tmpReportFiles);
    };

    let file = reportFiles.find((x) => x.id === fileId);
    let fileName = file.fileName;

    setDownloadingStatus(true);
    try {
      var result = await apiService.downloadReportFileById(fileId);
      fileDownload(result, fileName);
      setDownloadingStatus(false);
    } catch (err) {
      setDownloadingStatus(false);
      setSnackbar({
        open: true,
        isSuccess: false,
        message: 'Download error.',
      });
      if (err.response && err.response.status === 401) history.push('/login');
    }
  };

  const handleDownloadClick = (row, index) => {
    let fileId = row[0];
    downloadReportFileById(fileId);
  };

  return (
    <ThemeProvider theme={theme}>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert theme={theme} onClose={handleSnackbarClose} severity={snackbar.isSuccess ? 'success' : 'error'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <EntityListFrame>
        {myFeaturesContext &&
          (myFeaturesContext.enabledReportFilesFeature ? (
            <Grid item xs={12}>
              {rows && (
                <EntityTable
                  title="Report Files"
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  additionalRowAction={(row, index) => {
                    return <DownloadButton onClick={() => handleDownloadClick(row, index)} isDownloading={reportFiles[index].isDownloading} />;
                  }}
                />
              )}
            </Grid>
          ) : (
            <FeatureDisabledMessage />
          ))}
      </EntityListFrame>
    </ThemeProvider>
  );
}

export default ReportFiles;
