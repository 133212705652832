import React, { useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import { useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ContentFont from '../../Typography/ContentFont';
import ChartLogo from '../../parts/ChartLogo';
import { lightTheme } from '../../../themes/lightTheme';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import ChartNoData from '../../parts/ChartNoData.js';
import LoadingOverlay from '../../parts/LoadingOverlay';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  chart: {
    height: 380, //520,
    paddingTop: 20,
  },
  chartTitle: {
    height: 90,
    display: 'flex',
    alignItems: 'flex-end',
  },
  cardTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    paddingRight: 20,
  },
}));

function IncontermCharts(props) {
  const { width } = props;
  const classes = useStyles();
  const [chartDataInput, setChartDataInput] = useState([]);
  const { FilterComponentData } = props;
  // const [chartLayout, setChartLayout] = useState(width === 'lg' || width === 'xl' ? 'vertical' : 'horizontal');
  const [chartLayout, setChartLayout] = useState('horizontal');
  const [showNoData, setShowNoData] = useState(false);
  const [IsLoading, setIsLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      setIsLoading(true);
      apiService
        .getJobIncotermPerCountry(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            if (result.data && result.data.jobIncotermPerCountryReportItem && result.data.jobIncotermPerCountryReportItem.length > 0) {
              setShowNoData(false);
              setChartDataInput(result.data.jobIncotermPerCountryReportItem);
            } else {
              setShowNoData(true);
              setChartDataInput([]);
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData]);

  function hasChartData() {
    if (chartDataInput && chartDataInput.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  useLayoutEffect(() => {
    var chart;

    if (hasChartData()) {
      if (chartLayout === 'vertical') {
        chart = CreateChart('SeriesXYChartInconterm', true);
      } else {
        chart = CreateChart('SeriesXYChartInconterm', false);
      }
    }

    return () => {
      if (chart) chart.dispose();
    };
  }, [chartDataInput, chartLayout, showNoData]);

  useEffect(() => {
    //setChartLayout(width === 'lg' || width === 'xl' ? 'vertical' : 'horizontal');
  }, [width]);

  function CreateChart(chartId, isVertical) {
    let chart = am4core.create(chartId, am4core.Container);
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);
    chart.logo.disabled = true;

    var columnChart, pieChart;

    if (isVertical) {
      chart.layout = 'vertical';
      pieChart = chart.createChild(am4charts.PieChart);
      columnChart = chart.createChild(am4charts.XYChart);
    } else {
      chart.layout = 'horizontal';
      columnChart = chart.createChild(am4charts.XYChart);
      pieChart = chart.createChild(am4charts.PieChart);
    }

    //BAR CHART
    // Create axes
    let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());

    // Create series
    let columnSeries = columnChart.series.push(new am4charts.ColumnSeries());

    columnSeries.columns.template.strokeWidth = 0;
    columnSeries.dataFields.valueX = 'value';
    columnSeries.dataFields.categoryY = 'category';

    //PIE CHART
    // Create chart instance
    pieChart.data = prepareStuff(chartDataInput);
    pieChart.innerRadius = 70;
    pieChart.radius = 120;

    // Add and configure Series
    let pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'category';
    pieSeries.alignLabels = false;
    pieSeries.labels.template.disabled = false;
    pieSeries.labels.template.fontSize = 8;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);
    pieSeries.slices.template.propertyFields.fill = 'color';
    pieSeries.slices.template.tooltipText = "{categoryFullName}: {value.percent.formatNumber('#.#')}% ({value.value})";

    pieSeries.ticks.template.disabled = true;

    // Set up labels
    let label1 = pieChart.seriesContainer.createChild(am4core.Label);
    label1.text = '';
    label1.horizontalCenter = 'middle';
    label1.fontSize = 35;
    label1.fontWeight = 600;
    label1.dy = -30;

    let label2 = pieChart.seriesContainer.createChild(am4core.Label);
    label2.text = '';
    label2.horizontalCenter = 'middle';
    label2.fontSize = 12;
    label2.dy = 20;

    // Auto-select first slice on load
    pieChart.events.on('ready', function (ev) {
      if (pieSeries.slices.getIndex(0)) {
        pieSeries.slices.getIndex(0).isActive = true;
      }
    });

    // Set up toggling events
    pieSeries.slices.template.events.on('toggled', function (ev) {
      if (ev.target.isActive) {
        // Untoggle other slices
        pieSeries.slices.each(function (slice) {
          if (slice != ev.target) {
            slice.isActive = false;
          }
        });

        // Update column chart
        columnSeries.appeared = false;
        columnChart.data = ev.target.dataItem.dataContext.breakdown;
        columnSeries.fill = ev.target.fill;
        columnSeries.reinit();

        // Update labels
        label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
        label1.fill = ev.target.fill;

        label2.text = ev.target.dataItem.dataContext.categoryFullName;
      }
    });

    return chart;
  }

  const prepareStuff = (data) => {
    var tempArray1 = [];

    let totolColor = lightTheme.chart.colors.length;
    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        var obj1 = {
          category: data[i].nameCtry,
          categoryFullName: data[i].countryFullName,
          value: 0,
          color: '',
          breakdown: [
            {
              category: 'CIF',
              value: 0,
            },
            {
              category: 'DDP',
              value: 0,
            },
            {
              category: 'EXW',
              value: 0,
            },
            {
              category: 'FCA',
              value: 0,
            },
            {
              category: 'FOB',
              value: 0,
            },
          ],
        };
        for (let property of data[i].incoterms) {
          obj1.breakdown.push({
            category: property['incotermsName'],
            value: property['incotermsCOUNT'],
          });
          obj1.color = lightTheme.chart.colors[i % totolColor];
          obj1.value += property['incotermsCOUNT']; //parseInt(property["incotermsCOUNT"]);
        }
        tempArray1.push(obj1);
      }
    }
    return tempArray1;
  };

  return (
    <Card chart>
      <LoadingOverlay IsLoading={IsLoading} />
      <CardHeader className={'dashboard-chart-area'}>
        <ContentFont>{showNoData ? <ChartNoData /> : <div id="SeriesXYChartInconterm" className={classes.chart}></div>}</ContentFont>
      </CardHeader>
      <CardBody className={'dashboard-chart-title-area'}>
        <h4>Jobs & Incoterms Per Country</h4>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

IncontermCharts.propTypes = {
  width: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']).isRequired,
};

export default withWidth()(IncontermCharts);
