import { Grid, makeStyles, Paper } from '@material-ui/core';
import apiService from '../../../services/api.service';
import React, { useEffect, useState } from 'react';
import EDIChart from './EDIChart';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
    padding: theme.spacing(2),
    borderRadius: 8,
  },
}));

export default function SentReceivedChart(props) {
  const { filterValue } = props;
  const classes = useStyles();
  const history = useHistory();
  const [isFilesReceivedLoading, setIsFilesReceivedLoading] = useState(false);
  const [isFilesSentLoading, setIsFilesSentLoading] = useState(false);
  const [filesReceivedChartData, setFilesReceivedChartData] = useState([]);
  const [filesSentChartData, setFilesSentChartData] = useState([]);
  const [filesSentChartTitle, setFilesSentChartTitle] = useState('Number of Files Sent');

  useEffect(() => {
    let isCancelled = false;

    const reloadFilesReceivedChartData = (companyAccountId) => {
      if (companyAccountId) {
        setIsFilesReceivedLoading(true);
        apiService
          .getEDIFilesReceivedChart(companyAccountId)
          .then((result) => {
            if (!isCancelled) {
              setFilesReceivedChartData(result.data);
            }
            setIsFilesReceivedLoading(false);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) history.push('/login');
          });
      }
    };

    const reloadFilesSentChartData = (companyAccountId) => {
      if (companyAccountId) {
        setIsFilesSentLoading(true);
        apiService
          .getEDIFilesSentChart(companyAccountId)
          .then((result) => {
            if (!isCancelled) {
              setFilesSentChartData(result.data);
            }
            setIsFilesSentLoading(false);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) history.push('/login');
          });
      }
    };

    if (filterValue && filterValue.companyAccountId) {
      setFilesSentChartTitle(`Number of Files Sent to ${filterValue.companyName}`);
      reloadFilesReceivedChartData(filterValue.companyAccountId);
      reloadFilesSentChartData(filterValue.companyAccountId);
    }

    return () => {
      isCancelled = true;
      setIsFilesReceivedLoading(false);
    };
  }, [filterValue]);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="flex-start" alignItems="stretch">
        <Grid item xs={12}>
          <EDIChart
            ChartId="FileReceivedChart"
            Title="Number of Files Received by Verus Global"
            IsLoading={isFilesReceivedLoading}
            ChartData={filesReceivedChartData}
          />
        </Grid>
        <Grid item xs={12}>
          <EDIChart ChartId="FileSentChart" Title={filesSentChartTitle} IsLoading={isFilesSentLoading} ChartData={filesSentChartData} />
        </Grid>
      </Grid>
    </Paper>
  );
}
