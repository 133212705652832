import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
  InputLabel,
  Select,
  FormHelperText,
  Divider,
  CardActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Input,
  ListItemText,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import LoadingBar from 'react-top-loading-bar';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import ExternalEndpointList from './ExternalEndpointList';
import apiService from '../../../services/api.service';
import { UserContext } from '../../../contexts/UserContext';
import Button from '../../../wrappers/Button';
import clsx from 'clsx';
import { ReloadContext } from '../../../contexts/ReloadContext';
import TextFieldExternalApplicationKey from './TextFieldExternalApplicationKey';
import DialogRegenerateKey from './DialogRegenerateKey';
import helpService from '../../../services/help.service';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },

  input: {
    display: 'none',
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    padding: theme.spacing(3),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  entryContainer: {
    paddingBottom: theme.spacing(5),
  },
}));

export default function ExternalApplicationEntry(props) {
  let { id } = useParams();
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;

  const allSupplierId = '-1';
  const allSupplierText = 'All Suppliers';

  const { action } = props;
  const history = useHistory();
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [userContext] = useContext(UserContext);
  const classes = useStyles();
  const progressRef = useRef(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    isSuccess: false,
    message: '',
  });
  const [, setReloadContext] = useContext(ReloadContext);
  const [reloadEntry, setReloadEntry] = useState();

  const [accounts, setAccounts] = useState();
  const [users, setUsers] = useState();
  const [endpoints, setEndpoints] = useState();

  const [name, setName] = useState();
  const [accountName, setAccountName] = useState();
  const [accountId, setAccountId] = useState();
  const [userId, setUserId] = useState();
  const [contactUserName, setContactUserName] = useState();
  const [primaryKey, setPrimaryKey] = useState();
  const [secondaryKey, setSecondaryKey] = useState();
  const [enabledEndpointIds, setEnabledEndpointIds] = useState([]);
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState('');
  const [displayAccountErrorMessage, setDisplayAccountErrorMessage] = useState('');
  const [displayUserErrorMessage, setDisplayUserErrorMessage] = useState('');
  const [pendingRegenerateKeyAction, setPendingRegenerateKeyAction] = useState({
    show: false,
    isPrimaryKey: false,
  });
  const [supplierFilters, setSupplierFilters] = useState();
  const [filterSupplierNames, setFilterSupplierNames] = useState();

  const [selectedSupplierValues, setSelectedSupplierValues] = useState([allSupplierId]);

  const pageTitle = () => {
    if (action === 'add') {
      return 'Create New External API';
    } else if (action === 'edit') {
      return 'Edit External API';
    } else {
      return 'View External API';
    }
  };

  const isEdit = () => {
    return id ? true : false;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    let snackbartemp = { ...snackbar, open: false };
    setSnackbar(snackbartemp);
  };

  useEffect(() => {
    const initializeData = async (accountId) => {
      try {
        let supplierFiltersResult = await apiService.getExternalApplicationSupplierFilterItemsByAccountId(accountId);
        let assignableUsersResult = await apiService.getExternalApplicationAssignableUserByAccountId(accountId);

        setSupplierFilters(supplierFiltersResult.data);
        setUsers(assignableUsersResult.data);

        if (!isEdit() && assignableUsersResult.data.length > 0) {
          setUserId(assignableUsersResult.data[0].id);
          setSelectedSupplierValues([allSupplierId]);
        }
        progressRef.current.complete();
      } catch (err) {
        console.log('error: ', err);
        if (progressRef && progressRef.current) progressRef.current.complete();
        if (err.response && err.response.status === 401) history.push('/login');
      }
    };
    if (accountId) {
      initializeData(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (id) {
      progressRef.current.continuousStart();
      apiService
        .getExternalApplicationById(id)
        .then((result) => {
          setName(result.data.name);
          setAccountName(result.data.accountName);
          setAccountId(result.data.accountId);
          setUserId(result.data.userId);
          setEndpoints(result.data.endpoints);
          setPrimaryKey(result.data.primaryKey);
          setSecondaryKey(result.data.secondaryKey);
          setEnabledEndpointIds(result.data.endpoints.filter((x) => x.enabled).map((x) => x.endpointId));
          if (result.data.supplierIds && result.data.supplierIds.length > 0) {
            setSelectedSupplierValues(result.data.supplierIds);
          }

          progressRef.current.complete();
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
          if (progressRef && progressRef.current) progressRef.current.complete();
        });
    } else {
      apiService
        .getAccounts(0, 0, 'desc', 'Name')
        .then((result) => {
          setAccounts(result.data.list);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log('error: ', err);
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  }, [id, reloadEntry]);

  useEffect(() => {
    if (users && userId) {
      let user = users.find((user) => user.id === userId);
      setContactUserName(user.fullName);
    }
  }, [users, userId]);

  useEffect(() => {
    function GetSelectedSupplierNames() {
      if (selectedSupplierValues.length == 0 || selectedSupplierValues.indexOf(allSupplierId) > -1) {
        return allSupplierText;
      } else if (supplierFilters) {
        var supplierNames = [];
        selectedSupplierValues.forEach((supplierId) => {
          var supplier = supplierFilters.find((x) => x.id === supplierId);
          if (supplier) {
            supplierNames.push(supplier.name);
          }
        });
        return helpService.sortStringsAsc(supplierNames).join(', ');
      }

      return allSupplierText;
    }

    var selectedSupplierNames = GetSelectedSupplierNames();
    setFilterSupplierNames(selectedSupplierNames);
  }, [selectedSupplierValues, supplierFilters]);

  const handleAccountChange = (event) => {
    setUserId(undefined);
    setAccountId(event.target.value);
  };

  const handleSubmit = (event) => {
    progressRef.current.continuousStart();
    event.preventDefault();
    clearErrors();

    let supplierIds = selectedSupplierValues.indexOf(allSupplierId) > -1 ? undefined : selectedSupplierValues.join(',');

    if (!isEdit()) {
      apiService
        .createExternalApplication({
          name: name,
          userId: userId,
          supplierIds: supplierIds,
        })
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            handleSubmitError(result.errors);
          } else {
            setSnackbar({
              open: true,
              isSuccess: true,
              message: `The External API was created successfully`,
            });

            setReloadContext(new Date());
            history.replace(`/external-applications/${result.data}/edit`);
          }
          progressRef.current.complete();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err) {
            setSnackbar({
              open: true,
              isSuccess: false,
              message: err,
            });
          }
        });
    } else {
      apiService
        .updateExternalApplication({
          id: id,
          name: name,
          userId: userId,
          enabledEndpointIds: enabledEndpointIds,
          supplierIds: supplierIds,
        })
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            handleSubmitError(result.errors);
          } else {
            setSnackbar({
              open: true,
              isSuccess: true,
              message: `The External API was updated successfully`,
            });
            setReloadContext(new Date());
            setReloadEntry(new Date());
          }
          progressRef.current.complete();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err) {
            setSnackbar({
              open: true,
              isSuccess: false,
              message: err,
            });
          }
        });
    }
  };

  const handleSubmitError = (errors) => {
    for (let error of errors) {
      displayErrorMessage(error);
    }
    if (!accountId) {
      setDisplayAccountErrorMessage('Account is required.');
    }

    var errorString = '';
    for (var i = 0; i < errors.length; i++) {
      if (errors[i].errorMessage) {
        errorString += ' ' + errors[i].errorMessage + ',';
      } else errorString += ' ' + errors[i] + ',';
    }

    if (errorString) {
      setSnackbar({
        open: true,
        isSuccess: false,
        message: errorString,
      });
    }
  };

  const displayErrorMessage = (error) => {
    if (error.propertyName === 'Name') {
      setDisplayNameErrorMessage(error.errorMessage);
    } else if (error.propertyName === 'UserId') {
      setDisplayUserErrorMessage(error.errorMessage);
    }
  };

  const clearErrors = () => {
    setDisplayNameErrorMessage('');
    setDisplayUserErrorMessage('');
    setDisplayAccountErrorMessage('');
  };

  const canManage = () => {
    return userContext && userContext.permissions && userContext.permissions.canManageExternalApplications;
  };

  const handleRegenerateKeyRequest = (isPrimaryKey) => {
    setPendingRegenerateKeyAction({ show: true, isPrimaryKey: isPrimaryKey });
  };

  const handleRegenerateKey = (actionData) => {
    if (actionData.isPrimaryKey) {
      apiService
        .generateExternalApplicationPrimaryKey(id)
        .then((result) => {
          setReloadEntry(new Date());
          setSnackbar({
            open: true,
            isSuccess: true,
            message: `Primary Key was regenerated successfully`,
          });
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    } else {
      apiService
        .generateExternalApplicationSecondaryKey(id)
        .then((result) => {
          setReloadEntry(new Date());
          setSnackbar({
            open: true,
            isSuccess: true,
            message: `Secondary Key was regenerated successfully`,
          });
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleSupplierValuesChange = (e) => {
    var values = e.target.value;
    if (values.length == 0) {
      setSelectedSupplierValues([allSupplierId]);
    } else {
      var lastValue = values[values.length - 1];
      if (lastValue === allSupplierId) {
        setSelectedSupplierValues([allSupplierId]);
      } else {
        values = values.filter((x) => x !== allSupplierId);
        setSelectedSupplierValues(values);
      }
    }
  };

  function selectedSupplierMenuItem(supplierId) {
    return selectedSupplierValues.find((x) => x === supplierId) != null;
  }

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert theme={theme} onClose={handleSnackbarClose} severity="error" severity={snackbar.isSuccess ? 'success' : 'error'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={3} className={classes.entryContainer}>
              <Grid item xs={12}>
                <h4>{pageTitle()}</h4>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  required
                  error={!(displayNameErrorMessage === '')}
                  helperText={!(displayNameErrorMessage === '') ? displayNameErrorMessage : ''}
                  value={name || ''}
                  disabled={!canManage()}
                />
              </Grid>
              <Grid item xs={12}>
                {isEdit() ? (
                  <TextField fullWidth label="Account" margin="dense" name="accountName" disabled={true} value={accountName || ''} />
                ) : (
                  <FormControl required fullWidth className={classes.formControl} disabled={isEdit()} error={!(displayAccountErrorMessage === '')}>
                    <InputLabel id="account-select-label">Account</InputLabel>
                    <Select id="account-select" labelId="account-select-label" value={accountId ? accountId : ''} onChange={handleAccountChange}>
                      {accounts &&
                        accounts.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name || ''}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>{displayAccountErrorMessage === '' ? '' : displayAccountErrorMessage}</FormHelperText>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                {canManage() ? (
                  <FormControl required fullWidth className={classes.formControl} error={!(displayUserErrorMessage === '')}>
                    <InputLabel id="user-select-label">Contact User</InputLabel>
                    <Select
                      id="user-select"
                      labelId="user-select-label"
                      value={users && userId ? userId : ''}
                      onChange={(e) => setUserId(e.target.value)}
                      disabled={!accountId}
                    >
                      {users &&
                        users.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.fullName || ''}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>{displayUserErrorMessage === '' ? '' : displayUserErrorMessage}</FormHelperText>
                  </FormControl>
                ) : (
                  <TextField fullWidth label="Contact User" margin="dense" name="contactUser" disabled={true} value={contactUserName || ''} />
                )}
              </Grid>

              <Grid item xs={12}>
                {canManage() ? (
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel id="suppliers-select-label">Suppliers</InputLabel>
                    <Select
                      id="suppliers-select"
                      labelId="suppliers-select-label"
                      multiple
                      value={selectedSupplierValues}
                      onChange={handleSupplierValuesChange}
                      input={<Input />}
                      renderValue={() => {
                        return filterSupplierNames;
                      }}
                      autoWidth={true}
                      disabled={!accountId}
                    >
                      <MenuItem value={allSupplierId}>
                        <Checkbox checked={selectedSupplierMenuItem(allSupplierId)} />
                        <ListItemText primary={allSupplierText} />
                      </MenuItem>
                      <Divider style={{ marginBottom: '10px' }}></Divider>
                      {supplierFilters &&
                        supplierFilters.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              <Checkbox checked={selectedSupplierMenuItem(item.id)} />
                              <ListItemText primary={item.name || ''} />
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField fullWidth label="Supplier" margin="dense" name="suppliers" disabled={true} value={filterSupplierNames || ''} />
                )}
              </Grid>
              {isEdit() && (
                <>
                  <Grid item xs={12}>
                    <TextFieldExternalApplicationKey
                      externalApplicationKey={primaryKey}
                      onRefreshButtonClick={() => handleRegenerateKeyRequest(true)}
                      title="Primary Key"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldExternalApplicationKey
                      externalApplicationKey={secondaryKey}
                      onRefreshButtonClick={() => handleRegenerateKeyRequest(false)}
                      title="Secondary Key"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <ExternalEndpointList endpoints={endpoints} onEndpointsSelected={setEnabledEndpointIds} />
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            {canManage() && (
              <Button theme={theme} type="submit" disabled={disableSaveButton} className={classes.buttons}>
                Save
              </Button>
            )}
            <Button theme={theme} className={clsx(classes.buttons, classes.buttonRight)} isSecondary="true" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>

      <DialogRegenerateKey
        action={pendingRegenerateKeyAction}
        onConfirm={handleRegenerateKey}
        title={`Regenerate ${pendingRegenerateKeyAction.isPrimaryKey ? 'Primary Key' : 'Secondary Key'}?`}
        body="Are you sure you want to regenerate key? Once generated it cannot be undone."
      />
    </>
  );
}
