import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import { ThemeProvider } from '@material-ui/styles';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import EntityListFrame from '../../layouts/EntityListFrame';
import DataTableFilter from '../../parts/DataTableFilter';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { CSVLink } from 'react-csv';
import ExportMenu from '../../../wrappers/ExportMenu';
import { IconButton, Modal, Tooltip } from '@material-ui/core';
import ProductOverview from '../ProductOverview/ProductOverview';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import FeatureDisabledMessage from '../../parts/FeatureDisabledMessage';

function ProductsTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productCode');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [baseQueryString, setBaseQueryString] = React.useState();
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProductCode, setSelectedProductCode] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');
  const [myFeaturesContext] = useContext(MyFeaturesContext);
  const [accountId, setAccountId] = useState();
  const [productOverviewSettings, setProductOverviewSettings] = useState({});

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      setAccountId(filter.accountId);
      getData();
    }
  }, [rowsPerPage, page, filter]);

  useEffect(() => {
    const loadProductOverviewSettings = async () => {
      let result = await apiService.getProductOverviewSettings(accountId);
      setProductOverviewSettings(result.data);
    };

    if (accountId) {
      loadProductOverviewSettings();
    }
  }, [accountId]);

  const useStyles = makeStyles((theme) => ({
    titleArea: {
      marginBottom: '28px',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    ExportMenuContainer: {
      flexGrow: 0,
    },
    modalContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContentContainer: {
      margin: 60,
      width: '100%',
      maxHeight: 'calc(100% - 40px)',
      display: 'flex',
    },
    overviewIcon: {
      color: theme.palette.primaryButton.backgroundColor,
    },
  }));

  const classes = useStyles();

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getProductsTable(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers
      )
      .then((result) => {
        setProducts(result.data.items);
        setRows(createTableListData(result.data.items));
        setTotalNumberOfRows(result.data.count);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    var index = 0;
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      var suppliers = { component: 'textList', texts: [] };
      item.suppliers.map((supplier) => {
        suppliers.texts.push({ text: supplier });
      });
      let cells = [
        item.productCode,
        item.productCode,
        item.description,
        suppliers,
        item.totalQty.toLocaleString(),
        item.avgUnitValue.toLocaleString(),
        item.minUnitValue.toLocaleString(),
        item.maxUnitValue.toLocaleString(),
        item.units,
      ];

      let tmpCell = {};
      tmpCell['id'] = item.productCode;
      headCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });

      newArray.push(tmpCell);
    });
    return newArray;
  };

  const headCells = [
    {
      id: 'productCode',
      numeric: false,
      disablePadding: true,
      label: 'Product Code',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'suppliers',
      numeric: false,
      disablePadding: false,
      label: 'Suppliers',
    },
    {
      id: 'totalQty',
      numeric: true,
      disablePadding: false,
      label: 'Total Qty.',
    },
    {
      id: 'avgUnitValue',
      numeric: true,
      disablePadding: false,
      label: 'Avg. ($)',
      tooltip: 'Average unit price per commercial invoice',
    },
    {
      id: 'minUnitValue',
      numeric: true,
      disablePadding: false,
      label: 'Min ($)',
      tooltip: 'Minimum unit price per commercial invoice',
    },
    {
      id: 'maxUnitValue',
      numeric: true,
      disablePadding: false,
      label: 'Max ($)',
      tooltip: 'Maximum unit price per commercial invoice',
    },
    {
      id: 'units',
      numeric: false,
      disablePadding: false,
      label: 'Units',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getProductsTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generateProductsPDF(
            result.data.items,
            'Products     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Products Report'
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getProductsTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generateProductsCsvData(result.data.items, filter.accountName + '_' + filter.supplierName + '_' + filter.periodName);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleOverviewClick = (event) => {
    let selectedProductCode = event.currentTarget.parentNode.dataset.tag;
    let selectedProduct = products.find((x) => x.productCode === selectedProductCode);
    setSelectedProductCode(selectedProductCode);
    setSelectedProductName(selectedProduct.description);
    setModalOpen(true);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
        <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
        <EntityListFrame>
          {myFeaturesContext &&
            (myFeaturesContext.enabledProducts ? (
              <>
                <Grid container className={classes.titleArea}>
                  <Grid item xs={12} sm className="align-self-center">
                    <h4>Products</h4>
                  </Grid>
                  <Grid item xs={12} sm className={'align-self-center ' + classes.ExportMenuContainer}>
                    <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV} />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <DataTableFilter sendFilterComponentData={filterCallback} sendQueryStringsNoFreeTextSearch={setBaseQueryString} showSuppliers={true} />
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  {rows && (
                    <DataContentTable
                      title=""
                      headCells={headCells}
                      totalNumberOfRows={totalNumberOfRows}
                      rows={rows}
                      page={page}
                      handleRequestSort={handleRequestSort}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      rowActionComponent={
                        <Tooltip title="Product Overview" placement="left">
                          <IconButton aria-label="edit" onClick={handleOverviewClick}>
                            <DonutLargeIcon size="small" className={classes.overviewIcon} />
                          </IconButton>
                        </Tooltip>
                      }
                    />
                  )}
                </Grid>
              </>
            ) : (
              <FeatureDisabledMessage />
            ))}
        </EntityListFrame>
        <Modal className={classes.modalContainer} open={modalOpen} onClose={handleModalClose}>
          <div className={classes.modalContentContainer}>
            <ProductOverview
              accountId={accountId}
              productCode={selectedProductCode}
              productName={selectedProductName}
              onClose={handleModalClose}
              settings={productOverviewSettings}
            />
          </div>
        </Modal>
      </ThemeProvider>
    </>
  );
}

export default withRouter(ProductsTable);
