import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import TitleFont from '../../../Typography/TitleFont';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5),
    display: 'flex',
    flexGrow: 1,
  },
}));

export default function PortPairsTrendFilter(props) {
  const classes = useStyles();
  const { portPairFilterItems, onSelectedPorts, originId, destinationId } = props;

  const [postBackFilterDataCounter, setPostBackFilterDataCounter] = useState(0);

  const [selectedOriginId, setSelectedOriginId] = useState(originId || '');
  const [selectedDestinationId, setSelectedDestinationId] = useState(destinationId || '');

  const [originFilterItems, setOriginFilterItems] = useState([]);
  const [destinationFilterItems, setDestinationFilterItems] = useState([]);

  function sortPorts(ports) {
    let tempPorts = ports.sort(function (a, b) {
      var nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    return tempPorts;
  }

  function setOrigins(portPairFilterItems, destinationId) {
    if (portPairFilterItems) {
      var origins = [];
      portPairFilterItems.forEach((item) => {
        if (origins.findIndex((x) => x.id === item.originId) < 0) {
          if ((!destinationId || item.destinationId === destinationId) && item.origin) {
            origins.push({ id: item.originId, name: item.origin });
          }
        }
      });
      origins = sortPorts(origins);

      setOriginFilterItems(origins);
    } else {
      setOriginFilterItems([]);
    }
  }

  function setDestinations(portPairFilterItems, originId) {
    if (portPairFilterItems) {
      var destinations = [];
      portPairFilterItems.forEach((item) => {
        if (destinations.findIndex((x) => x.id === item.destinationId) < 0) {
          if ((!originId || item.originId === originId) && item.destination) {
            destinations.push({ id: item.destinationId, name: item.destination });
          }
        }
      });
      destinations = sortPorts(destinations);

      setDestinationFilterItems(destinations);
    } else {
      setDestinationFilterItems([]);
    }
  }

  function postbackOnSelectedPorts(originId, destinationId) {
    if (onSelectedPorts) {
      onSelectedPorts({ originId: originId, destinationId: destinationId });
    }
  }

  useEffect(() => {
    if (postBackFilterDataCounter) {
      postbackOnSelectedPorts(selectedOriginId, selectedDestinationId);
    }
  }, [postBackFilterDataCounter]);

  useEffect(() => {
    if (portPairFilterItems) {
      if (
        (selectedOriginId && portPairFilterItems.findIndex((x) => x.originId === selectedOriginId) < 0) ||
        (selectedDestinationId && portPairFilterItems.findIndex((x) => x.destinationId === selectedDestinationId) < 0)
      ) {
        setSelectedOriginId('');
        setSelectedDestinationId('');
      }

      setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
    }
  }, [portPairFilterItems]);

  useEffect(() => {
    if (portPairFilterItems) {
      setDestinations(portPairFilterItems, selectedOriginId);
    } else {
      setDestinationFilterItems([]);
    }
  }, [portPairFilterItems, selectedOriginId]);

  useEffect(() => {
    if (portPairFilterItems) {
      setOrigins(portPairFilterItems, selectedDestinationId);
    } else {
      setOriginFilterItems([]);
    }
  }, [portPairFilterItems, selectedDestinationId]);

  function handleOriginChange(e) {
    setSelectedOriginId(e.target.value);
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  }

  function handleDestinationChange(e) {
    setSelectedDestinationId(e.target.value);
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={6} lg={6}>
        <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
          <InputLabel id="origin-select-outlined-label">
            <TitleFont>Origin</TitleFont>
          </InputLabel>
          <Select
            labelId="origin-select-outlined-label"
            id="origin-select-outlined"
            label="Origin"
            value={(originFilterItems && originFilterItems.length > 0 && selectedOriginId) || ''}
            onChange={(e) => handleOriginChange(e)}
          >
            <MenuItem key="all" value="">
              All
            </MenuItem>
            {originFilterItems &&
              originFilterItems.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem xs={12} sm={6} md={6} lg={6}>
        <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
          <InputLabel id="destination-select-outlined-label">
            <TitleFont>Destination</TitleFont>
          </InputLabel>
          <Select
            labelId="destination-select-outlined-label"
            id="destination-select-outlined"
            label="Destination"
            value={(destinationFilterItems && destinationFilterItems.length > 0 && selectedDestinationId) || ''}
            onChange={(e) => handleDestinationChange(e)}
          >
            <MenuItem key="all" value="">
              All
            </MenuItem>
            {destinationFilterItems &&
              destinationFilterItems.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
}
