import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Divider,
  Box,
  Card,
  CardActions,
  CardContent,
  Avatar,
  TextField,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

import LoadingBar from 'react-top-loading-bar';
import red from '@material-ui/core/colors/red';
import DeleteIcon from '@material-ui/icons/Delete';
import apiService from '../../../services/api.service';
import Alert from '../../../wrappers/Alert';
import Button from '../../../wrappers/Button';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import { UserContext } from '../../../contexts/UserContext';
import { useHistory } from 'react-router';
import clsx from 'clsx';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import green from '@material-ui/core/colors/green';
import GoogleIcon from '@material-ui/icons/Google';
import GoogleLogin from 'react-google-login';
import Snackbar from '@material-ui/core/Snackbar';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import OneSignal from 'react-onesignal';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  rightTopCorner: {
    position: 'absolute',
    top: '0x',
    right: '0px',
  },
  avatar: {
    height: 133,
    width: 133,
  },
  input: {
    display: 'none',
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  googleIcon: {},
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
  },
  formControlCheckbox: {
    marginBottom: 0,
  },
}));

const UserProfileMain = (props) => {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [phoneNumber, setPhoneNumber] = useState();
  const [buyerId, setBuyerId] = useState('');
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [colorTheme, setColorTheme] = useState();
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessage, setErrorMesage] = useState('');

  const [notifyViaEmail, setNotifyViaEmail] = useState(false);
  const [notifyViaWeb, setNotifyViaWeb] = useState(false);
  const [disableNotifyViaWebCheckbox, setDisableNotifyViaWebCheckbox] = useState(false);

  const [profileFile, setProfileFile] = useState();
  const [profileImageSrc, setProfileImageSrc] = useState();
  const [image, setImage] = useState();
  const [hasGoogleLogin, setHasGoogleLogin] = useState();

  const classes = useStyles();
  const inputFile = useRef();
  const progressRef = useRef(null);
  const [userContext, setUserContext] = useContext(UserContext);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const history = useHistory();

  const [displayPhonenumberError, setDisplayPhonenumberError] = useState('');
  const [displayLastnameError, setDisplayLastnameError] = useState('');
  const [displayFirstnameError, setDsplayFirstnameError] = useState('');
  const [snackbarError = '', setsnackbarError] = useState();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);

  useEffect(() => {
    progressRef.current.continuousStart();
    getUserProfile();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  const getUserProfile = () => {
    apiService
      .getUserProfile()
      .then((result) => {
        setFirstname(result.data.firstname);
        setLastname(result.data.lastname);
        setPhoneNumber(result.data.phoneNumber);
        setColorTheme(result.data.themeColor);
        setHasGoogleLogin(result.data.hasGoogleLogin);
        setBuyerId(result.data.buyerId);
        setNotifyViaEmail(result.data.notifyViaEmail);
        setNotifyViaWebIfPermissionGranted(result.data.notifyViaWebBrowser);

        if (result.data.appId && result.data.avatarExtension)
          setProfileImageSrc('/dynamic/user/images/' + result.data.appId + '.' + result.data.avatarExtension + '?v=' + moment().format('YYYYmmddss'));
        progressRef.current.complete();
      })
      .catch((err) => {
        console.log('error: ', err);
        if (progressRef && progressRef.current) progressRef.current.complete();

        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const cleanErrors = () => {
    setDsplayFirstnameError('');
    setDisplayLastnameError('');
    setDisplayPhonenumberError('');
  };

  const validateError = (errors) => {
    for (let error of errors) {
      if (error.propertyName === 'Firstname') setDsplayFirstnameError(error.errorMessage);
      else if (error.propertyName === 'Lastname') setDisplayLastnameError(error.errorMessage);
      else if (error.propertyName === 'PhoneNumber') setDisplayPhonenumberError(error.errorMessage);
    }
  };

  const uploadSingleFile = (e) => {
    let inputFile = e.target.files[0];
    var regex = new RegExp(/\.(jpg|jpeg|png|gif)$/, 'i');
    if (inputFile && inputFile.name.match(regex)) {
      if (inputFile.size > 150000) {
        if (inputFile && inputFile.current) inputFile.current.value = '';
        setErrorMesage('The profile image cannot have a size greater than 150kb');
        setErrorAlertOpen(true);
      } else {
        setImage(e.target.files[0]);
        setErrorAlertOpen(false);
        setProfileFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const hasImage = () => {
    if (image || profileImageSrc) return true;
    else return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    cleanErrors();
    setSaveSuccess(false);
    progressRef.current.continuousStart();
    const formData = new FormData();
    formData.append('avatar', image);
    localStorage.setItem('theme', colorTheme);
    apiService
      .postUserProfile(formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          hasImage: hasImage(),
          firstname: firstname,
          phoneNumber: phoneNumber,
          lastname: lastname,
          colorTheme: colorTheme,
          buyerId: buyerId,
          notifyViaEmail: notifyViaEmail,
          notifyViaWebBrowser: notifyViaWeb,
        },
      })
      .then((result) => {
        progressRef.current.complete();

        if (result.errors && result.errors.length > 0) {
          validateError(result.errors);
          var errorString = '';
          for (var i = 0; i < result.errors.length; i++) {
            if (result.errors[i].errorMessage) {
              errorString += ' ' + result.errors[i].errorMessage + ',';
            } else errorString += ' ' + result.errors[i] + ',';
          }
          setsnackbarError(errorString);
          setOpenSnackbar(true);
        } else {
          apiService
            .getMe()
            .then((result) => {
              setUserContext(result);
              setOpenSuccessSnackbar(true);
            })
            .catch((err) => {
              console.log('error: ', err);
              if (progressRef && progressRef.current) progressRef.current.complete();
              if (err.response && err.response.status === 401) history.push('/login');
              setsnackbarError(err);
              setOpenSnackbar(true);
            });
        }
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error', err);
      });
  };

  const successResponseGoogle = (response) => {
    console.log('auth success', response);
    apiService
      .addLoginGoogle({
        userID: response.googleId,
        accessToken: response.tokenObj.id_token,
      })
      .then((result) => {
        console.log('result', result);
        if (result.errors.length) {
          //setErrorMesage(result.errors[0]);
          setsnackbarError(result.errors[0]);
          setOpenSnackbar(true);
          setErrorAlertOpen(true);
        }
        getUserProfile();
      })
      .catch((error) => {
        console.log('auth failed');
        //setAlertErrorMessage("No user registered with this social identity exists in the system. Please Sign Up first");
        setsnackbarError('No user registered with this social identity exists in the system. Please Sign Up first');
        setOpenSnackbar(true);
        if (progressRef && progressRef.current) progressRef.current.complete();
      });
  };

  const failedResponseGoogle = (response) => {
    console.log(response);
  };

  const removeGoogleLogin = () => {
    apiService
      .removeGoogle({})
      .then((result) => {
        getUserProfile();
      })
      .catch((error) => {
        setsnackbarError('Remove Google login failed');
        setOpenSnackbar(true);
        if (progressRef && progressRef.current) progressRef.current.complete();
      });
  };

  const setUpOneSignal = async () => {
    await OneSignal.setExternalUserId(userContext.id);
    let userId = await OneSignal.getUserId();
    console.log('setUpOneSignal for test notification playerId:', userId);
  };

  useEffect(() => {
    if (userContext && userContext.id) {
      setUpOneSignal();
      if (Notification.permission === 'denied') {
        setDisableNotifyViaWebCheckbox(true);
      }
    }
  }, [userContext]);

  const setNotifyViaWebIfPermissionGranted = (notifyViaWebBrowser) => {
    if (notifyViaWebBrowser && Notification.permission === 'granted') {
      setNotifyViaWeb(true);
    } else {
      setNotifyViaWeb(false);
    }
  };

  const enableNotifyViaWebWithPrompt = async () => {
    await OneSignal.showNativePrompt();
    if (Notification.permission === 'denied') {
      setDisableNotifyViaWebCheckbox(true);
    }
    setNotifyViaWebIfPermissionGranted(true);
  };

  const handleNotifyViaWebChanged = () => {
    if (!notifyViaWeb) {
      if (Notification.permission !== 'granted') {
        enableNotifyViaWebWithPrompt();
      } else {
        setNotifyViaWeb(true);
      }
    } else {
      setNotifyViaWeb(false);
    }
  };
  useEffect(() => {
    console.log('Notification.permission', Notification.permission, notifyViaWeb);
  }, [notifyViaWeb]);

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Collapse in={saveSuccess}>
        <Alert
          theme={theme}
          severity="success"
          onClose={() => {
            setSaveSuccess(false);
          }}
          className={classes.alert}
        >
          Your profile was updated!
        </Alert>
      </Collapse>
      <Collapse in={errorAlertOpen}>
        <Alert
          theme={theme}
          severity="error"
          onClose={() => {
            setErrorAlertOpen(false);
          }}
          className={classes.alert}
        >
          {errorMessage}
        </Alert>
      </Collapse>
      <div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {snackbarError}
          </Alert>
        </Snackbar>
        <Snackbar open={openSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Your profile was updated!
          </Alert>
        </Snackbar>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid style={{ display: 'flex' }} container item lg={4} md={6} xl={4} xs={12}>
            <Grid item xs={12}>
              <Card className={clsx(classes.root, classes.card)}>
                <div className={classes.rightTopCorner}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setProfileFile();
                      setProfileImageSrc();
                      setImage();
                    }}
                  >
                    <DeleteIcon size="small" className={classes.actionButtonDelete} />
                  </IconButton>
                </div>
                <CardContent>
                  <Box alignItems="center" display="flex" flexDirection="column">
                    <Avatar className={classes.avatar} src={profileFile ? profileFile : profileImageSrc ? profileImageSrc : ''} />
                  </Box>
                </CardContent>
                <Divider />
                <CardActions>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    single="true"
                    ref={inputFile}
                    onChange={uploadSingleFile}
                    type="file"
                  />
                  <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                    <Button theme={theme} style={{ width: '100%', marginTop: '10px' }} component="span">
                      Change Image
                    </Button>
                  </label>
                </CardActions>
              </Card>
              <Button style={{ marginTop: '14px' }} fullWidth onClick={() => history.push('/profile/change-password')} theme={theme}>
                Change Password
              </Button>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                render={(renderProps) => (
                  <Button
                    style={{ marginTop: '14px', height: '40px' }}
                    onClick={hasGoogleLogin ? removeGoogleLogin : renderProps.onClick}
                    theme={theme}
                    className={classes.googleIcon}
                    startIcon={<GoogleIcon />}
                    fullWidth
                  >
                    Google Login {hasGoogleLogin ? 'enabled' : 'disabled'}
                    {hasGoogleLogin ? (
                      <div
                        style={{
                          position: 'absolute',
                          top: '7px',
                          right: '10px',
                        }}
                      >
                        <CheckCircleOutlineOutlinedIcon style={{ color: green[400] }} />
                      </div>
                    ) : (
                      ''
                    )}
                  </Button>
                )}
                buttonText="Login"
                onSuccess={successResponseGoogle}
                onFailure={failedResponseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} item lg={8} md={6} xl={8} xs={12}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h4>Profile Settings</h4>
                      <Typography variant="caption" display="block" gutterBottom>
                        Update your information.
                      </Typography>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Firstname"
                        margin="dense"
                        name="firstName"
                        error={!(displayFirstnameError === '')}
                        helperText={displayFirstnameError === '' ? '' : displayFirstnameError}
                        onChange={(e) => setFirstname(e.target.value)}
                        value={firstname || ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Lastname"
                        margin="dense"
                        name="lastName"
                        error={!(displayLastnameError === '')}
                        helperText={displayLastnameError === '' ? '' : displayLastnameError}
                        onChange={(e) => setLastname(e.target.value)}
                        value={lastname || ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        margin="dense"
                        name="phone"
                        error={!(displayPhonenumberError === '')}
                        helperText={displayPhonenumberError === '' ? '' : displayPhonenumberError}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        value={phoneNumber || ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth label="Buyer Id" margin="dense" name="buyerId" onChange={(e) => setBuyerId(e.target.value)} value={buyerId || ''} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Choose Color Theme"
                        margin="dense"
                        name="country"
                        select
                        onChange={(e) => setColorTheme(e.target.value)}
                        required
                        SelectProps={{ native: true }}
                        value={colorTheme}
                      >
                        <option key="key3" value="0">
                          Dark
                        </option>
                        <option key="key5" value="1">
                          Light
                        </option>
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h4>Notification Settings</h4>
                      <Typography variant="caption" display="block" gutterBottom>
                        Receive notifications via selected targets.
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="notifyViaEmail"
                            checked={notifyViaEmail}
                            onChange={() => {
                              setNotifyViaEmail(!notifyViaEmail);
                            }}
                          />
                        }
                        label="Email (Your Username)"
                        className={classes.formControlCheckbox}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="notifyViaWebNotification"
                            checked={notifyViaWeb}
                            onChange={handleNotifyViaWebChanged}
                            disabled={disableNotifyViaWebCheckbox}
                          />
                        }
                        className={classes.formControlCheckbox}
                        label="Web Browser"
                      />
                      {disableNotifyViaWebCheckbox && <FormHelperText>* Please allow notifications in site permissions settings.</FormHelperText>}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} className={classes.buttonsContainer}>
              <Button theme={theme} type="submit" className={classes.button}>
                Save
              </Button>
              <Button theme={theme} className={clsx(classes.button, classes.buttonRight)} isSecondary="true" onClick={() => history.goBack()}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UserProfileMain;
