import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import Users from './components/pages/Users/Users';
import Accounts from './components/pages/Accounts/Accounts';
import PortPairDataTable from './components/pages/DataTables/PortPairDataTable';
import CommercialInvoiceValuePerSupplierTable from './components/pages/DataTables/CommercialInvoiceValuePerSupplierTable';
import SpendPerSupplierTable from './components/pages/DataTables/SpendPerSupplierTable';
import ShipmentsTable from './components/pages/DataTables/ShipmentsTable';
import ShipmentDetailTable from './components/pages/DataTables/ShipmentDetailTable';
import ProductsTable from './components/pages/DataTables/ProductsTable';
import Trends from './components/pages/Trends';

import { UserContext } from './contexts/UserContext';
import { AdminDrawerContext } from './contexts/AdminDrawerContext';
import { ReloadContext } from './contexts/ReloadContext';
import { ProtectedRoute } from './protected.route';
import { lightTheme } from './themes/lightTheme';
import { ThemeProvider } from '@material-ui/styles';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import ConfirmEmail from './components/pages/ConfirmEmail';
import UserProfile from './components/pages/UserProfile/UserProfile';
import Home from './components/pages/Home';
import TrackAndTrace from './components/pages/TrackAndTrace';
import { MyFeaturesContext } from './contexts/MyFeaturesContext';
import EDIReport from './components/pages/EDIReport';
import ExternalApplications from './components/pages/ExternalApplication/ExternalApplications';
import ConsolidatedShipmentsSpendsTable from './components/pages/DataTables/ConsolidatedShipmentsSpendsTable';
import CreateBooking from './components/pages/SuppliersBookings/CreateBooking';
import Bookings from './components/pages/SuppliersBookings/Bookings';
import OneSignal from 'react-onesignal';
import PurchaseOrderDataTable from './components/pages/PurchaseOrder/PurchaseOrderDataTable';
import PurchaseOrderLineDataTable from './components/pages/PurchaseOrderLine/PurchaseOrderLineDataTable';
import PurchaseOrderLineMilestonesDataTable from './components/pages/PurchaseOrderLine/PurchaseOrderLineMilestonesDataTable';
import PurchaseOrderLineSpendsDataTable from './components/pages/PurchaseOrderLine/PurchaseOrderLineSpendsDataTable';
import ReportFiles from './components/pages/ReportFiles/ReportFiles';
import ShipmentSpendsTable from './components/pages/DataTables/ShipmentSpendsTable';
import ExceptionsTable from './components/pages/DataTables/ExceptionsTable';

let routes = (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/forgotpassword" component={ForgotPassword} />
    <Route exact path="/resetpassword" component={ResetPassword} />
    <Route exact path="/confirmemail" component={ConfirmEmail} />
    <ProtectedRoute exact path="/news" component={Home} />
    <ProtectedRoute exact path="/overview" component={Dashboard} />
    <ProtectedRoute path="/trends" component={Trends} />
    <ProtectedRoute path="/accounts" component={Accounts} />
    <ProtectedRoute path="/portpairdata" component={PortPairDataTable} />
    <ProtectedRoute path="/commercialInvoiceValuePerSupplier" component={CommercialInvoiceValuePerSupplierTable} />
    <ProtectedRoute path="/spendpersupplier" component={SpendPerSupplierTable} />
    <ProtectedRoute path="/shipments" component={ShipmentsTable} />
    <ProtectedRoute path="/shipmentDetails" component={ShipmentDetailTable} />
    <ProtectedRoute path="/products" component={ProductsTable} />
    <ProtectedRoute path="/users" component={Users} />
    <ProtectedRoute path="/profile" component={UserProfile} />
    <ProtectedRoute path="/trackandtrace" component={TrackAndTrace} />
    <ProtectedRoute path="/integrations" component={EDIReport} />
    <ProtectedRoute path="/external-applications" component={ExternalApplications} />
    <ProtectedRoute path="/purchase-order" component={PurchaseOrderDataTable} />
    <ProtectedRoute path="/purchase-order-lines" component={PurchaseOrderLineDataTable} />
    <ProtectedRoute path="/purchase-order-lines-milestones" component={PurchaseOrderLineMilestonesDataTable} />
    <ProtectedRoute path="/purchase-order-lines-spends" component={PurchaseOrderLineSpendsDataTable} />
    <ProtectedRoute path="/consolidated-shipments-spends" component={ConsolidatedShipmentsSpendsTable} />
    <ProtectedRoute path="/shipment-spends" component={ShipmentSpendsTable} />
    <ProtectedRoute path="/report-files" component={ReportFiles} />
    <ProtectedRoute path="/exceptions" component={ExceptionsTable} />
    <Route exact path="/">
      <Redirect to="/news" />
    </Route>
    <Route exact path="/create-booking" component={CreateBooking} />
    <Route exact path="/bookings" component={Bookings} />
  </Switch>
);

function App() {
  const [userContext, setUserContext] = useState({});
  const [adminDrawerContext, setAdminDrawerContext] = useState(true);
  const [reloadContext, setReloadContext] = useState();
  const [myFeaturesContext, setMyFeaturesContext] = useState();

  const history = useHistory();

  useEffect(() => {
    const setUpOneSignal = async () => {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        autoResubscribe: true,
      });
      await OneSignal.setSubscription(true);
      console.log('OneSignal init');
    };

    setUpOneSignal();
  }, []);

  return (
    <Router history={history}>
      <ThemeProvider theme={lightTheme}>
        <UserContext.Provider value={[userContext, setUserContext]}>
          <ReloadContext.Provider value={[reloadContext, setReloadContext]}>
            <AdminDrawerContext.Provider value={[adminDrawerContext, setAdminDrawerContext]}>
              <MyFeaturesContext.Provider value={[myFeaturesContext, setMyFeaturesContext]}>
                <div className="App">{routes}</div>
              </MyFeaturesContext.Provider>
            </AdminDrawerContext.Provider>
          </ReloadContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
