import React, { useEffect, useRef, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReloadContext } from '../../../contexts/ReloadContext';
import { UserContext } from '../../../contexts/UserContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import DataTableFilter from '../../parts/DataTableFilter';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { CSVLink } from 'react-csv';
import ExportMenu from '../../../wrappers/ExportMenu';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import tableService from '../../../services/table.service';
import ColumnSelector from '../../parts/ColumnSelector';

const useStyles = makeStyles((theme) => ({
  titleText: {
    marginBottom: '30px',
  },
  pdfboxArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  arrivedOnTime: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.onTime,
    color: 'white',
  },
  arrivedLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.late,
    color: 'white',
  },
  arrivedVeryLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.veryLate,
    color: 'white',
  },
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

function ShipmentDetailTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('shipmentNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [userContext] = useContext(UserContext);
  const [myFeaturesContext] = useContext(MyFeaturesContext);
  const [baseQueryString, setBaseQueryString] = useState();
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();

  const [reloadContext] = useContext(ReloadContext);

  const progressRef = useRef(null);
  const history = useHistory();
  const { location } = props;
  let match = useRouteMatch();

  const defaultHeadCells = [
    {
      id: 'shipmentNumber',
      numeric: false,
      disablePadding: true,
      label: 'Shipment No.',
    },
    {
      id: 'supplier',
      numeric: false,
      disablePadding: false,
      label: 'Supplier',
    },
    {
      id: 'productCode',
      numeric: false,
      disablePadding: false,
      label: 'Product Code',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: 'Description',
    },
    {
      id: 'invoiceQty',
      numeric: true,
      disablePadding: false,
      label: 'Qty',
    },
    {
      id: 'invoiceUQ',
      numeric: false,
      disablePadding: false,
      label: 'UQ',
    },
    {
      id: 'unitValue',
      numeric: true,
      disablePadding: false,
      label: 'Unit Value ($)',
    },
    {
      id: 'DelayInDays',
      numeric: true,
      disablePadding: false,
      label: 'Delay (Day)',
    },
  ];
  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter, myFeaturesContext]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getShipmentDetailTableReport(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch
      )
      .then((result) => {
        let contentRows = createTableListData(result.data.items);
        let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);

        setHeadCells(filteredTable.headCells);
        setRows(filteredTable.contentRows);
        setTotalNumberOfRows(result.data.count);
        setHiddenColumnNames(result.data.hiddenColumnNames);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    var index = 0;
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      var productCodeCell;
      if (myFeaturesContext && myFeaturesContext.enabledProducts) {
        productCodeCell = { component: 'linkList', links: [] };
        productCodeCell.links.push({
          text: item.productCode,
          path: '/products?s=' + item.productCode + otherQueryString,
        });
      } else {
        productCodeCell = item.productCode;
      }

      var delayInDays = { text: item.delayInDays, className: '' };
      if (item.delayInDays !== null) {
        if (item.delayInDays > 0) {
          delayInDays.text = `+${item.delayInDays}`;
          if (item.delayInDays <= 3) {
            delayInDays.className = classes.arrivedLate;
          } else {
            delayInDays.className = classes.arrivedVeryLate;
          }
        } else {
          delayInDays.className = classes.arrivedOnTime;
        }
      }

      let cells = [
        index++,
        item.shipmentNumber,
        item.supplier,
        productCodeCell,
        item.description,
        item.invoiceQty.toLocaleString(),
        item.invoiceUQ,
        item.unitValue.toLocaleString(),
        delayInDays,
      ];

      let tmpCell = {};
      tmpCell['id'] = cells[0];
      defaultHeadCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });

      newArray.push(tmpCell);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };
  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getShipmentDetailTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generateShipmentDetailsPDF(
            result.data.items,
            'Shipment Details     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Shipment Details Report',
            result.data.hiddenColumnNames
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getShipmentDetailTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generateShipmentDetailsCsvData(
            result.data.items,
            filter.accountName + '_' + filter.periodName,
            result.data.hiddenColumnNames
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleColumnSelected = (headCells) => {
    setNewHeadCells(headCells);
  };

  function backToShipments(event) {
    event.preventDefault();
    history.push('/shipments?' + baseQueryString);
  }
  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <EntityListFrame>
        <Grid item xs={12} sm={12} md={12}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={backToShipments}>
              Shipments
            </Link>
            <Typography color="textPrimary">Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container className={classes.titleArea}>
          <Grid item xs={12} sm className="align-self-center">
            <h4>Shipment Details</h4>
          </Grid>
          <Grid item className={'align-self-center '}>
            <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
          </Grid>
          <Grid item className={'align-self-center '}>
            <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTableFilter sendFilterComponentData={filterCallback} sendQueryStringsNoFreeTextSearch={setBaseQueryString} />
        </Grid>
        <Divider />

        <Grid item xs={12}>
          {rows === '' ? (
            ''
          ) : (
            <DataContentTable
              title=""
              headCells={newHeadCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              page={page}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </EntityListFrame>
    </>
  );
}

export default withRouter(ShipmentDetailTable);
