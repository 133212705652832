import React, { useEffect, useRef, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { ReloadContext } from '../../../contexts/ReloadContext';
import { UserContext } from '../../../contexts/UserContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import DataTableFilter from '../../parts/DataTableFilter';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import Parser from 'html-react-parser';
import moment from 'moment';
import helperService from '../../../services/help.service';
import ExportMenu from '../../../wrappers/ExportMenu';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { format } from 'react-string-format';
import { CSVLink } from 'react-csv';
import { isConstructorDeclaration } from 'typescript';
import helpService from '../../../services/help.service';
import tableService from '../../../services/table.service';
import ColumnSelector from '../../parts/ColumnSelector';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import { Backdrop, CircularProgress } from '@material-ui/core';
import FilledSnackbar from '../../parts/FilledSnackbar';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  arrivedOnTime: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.onTime,
    color: 'white',
  },
  arrivedLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.late,
    color: 'white',
  },
  arrivedVeryLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.veryLate,
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgb(0 0 0 / 25%)',
  },
}));

function ShipmentsTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('ShipmentNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [userContext] = useContext(UserContext);
  const [baseQueryString, setBaseQueryString] = useState();
  const classes = useStyles();
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();

  const [reloadContext] = useContext(ReloadContext);

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();

  const defaultHeadCells = [
    {
      id: 'ShipmentNumber',
      numeric: false,
      disablePadding: true,
      label: 'Shipment No.',
    },
    // {
    //   id: 'Vessel',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Vessel',
    // },
    {
      id: 'DepartureVessel',
      numeric: false,
      disablePadding: false,
      label: 'Departure Vessel',
    },
    {
      id: 'ArrivalVessel',
      numeric: false,
      disablePadding: false,
      label: 'Arrival Vessel',
    },
    {
      id: 'Origin',
      numeric: false,
      disablePadding: false,
      label: 'Origin',
    },
    {
      id: 'Destination',
      numeric: false,
      disablePadding: false,
      label: 'Destination',
    },
    {
      id: 'PODName',
      numeric: false,
      disablePadding: false,
      label: 'POD',
      tooltip: 'Port of Destination',
    },
    {
      id: 'FDPName',
      numeric: false,
      disablePadding: false,
      label: 'FDP',
      tooltip: 'Final Delivery Point',
    },
    {
      id: 'Trans',
      numeric: false,
      disablePadding: false,
      label: 'Trans',
    },
    {
      id: 'Mode',
      numeric: false,
      disablePadding: false,
      label: 'Mode',
    },
    {
      id: 'Supplier',
      numeric: false,
      disablePadding: false,
      label: 'Supplier',
      minWidth: 200,
    },
    {
      id: 'OrderSellersText',
      numeric: false,
      disablePadding: false,
      label: 'Sellers',
      minWidth: 200,
    },
    {
      id: 'Importer',
      numeric: false,
      disablePadding: false,
      label: 'Importer',
    },
    {
      id: 'OriginETD',
      numeric: false,
      disablePadding: false,
      label: 'Origin ETD',
    },
    {
      id: 'OriginATD',
      numeric: false,
      disablePadding: false,
      label: 'Origin ATD',
    },
    {
      id: 'DestinationETA',
      numeric: false,
      disablePadding: false,
      label: 'Destination ETA',
    },
    {
      id: 'DestinationATA',
      numeric: false,
      disablePadding: false,
      label: 'Destination ATA',
    },
    {
      id: 'PODETA',
      numeric: false,
      disablePadding: false,
      label: 'POD ETA',
    },
    {
      id: 'PODATA',
      numeric: false,
      disablePadding: false,
      label: 'POD ATA',
    },
    {
      id: 'FDPETA',
      numeric: false,
      disablePadding: false,
      label: 'FDP ETA',
    },
    {
      id: 'FDPATA',
      numeric: false,
      disablePadding: false,
      label: 'FDP ATA',
    },
    {
      id: 'DelayInDays',
      numeric: true,
      disablePadding: false,
      label: 'Delay (Day)',
    },
    {
      id: 'Weight',
      numeric: true,
      disablePadding: false,
      label: 'Weight (Kg)',
    },
    {
      id: 'Volume',
      numeric: true,
      disablePadding: false,
      label: Parser('<span>Volume (m<sup>3</sup>)</span>'),
    },
    {
      id: 'TEU',
      numeric: true,
      disablePadding: false,
      label: 'TEU',
    },
    {
      id: 'CarrierCode',
      numeric: true,
      disablePadding: false,
      label: 'Carrier Code',
    },
    {
      id: 'ContainerCount',
      numeric: true,
      disablePadding: false,
      label: 'Container Count',
    },
    {
      id: 'Incoterm',
      numeric: true,
      disablePadding: false,
      label: 'Incoterm',
    },
    {
      id: 'SupplierBookingDate',
      numeric: false,
      disablePadding: false,
      label: 'Supplier Booking Date',
    },
    {
      id: 'CargoReadyDate',
      numeric: false,
      disablePadding: false,
      label: 'Cargo Ready Date',
    },
    {
      id: 'CustomsClearedDate',
      numeric: false,
      disablePadding: false,
      label: 'Customs Cleared Date',
    },
    {
      id: 'RequiredDeliveryDate',
      numeric: false,
      disablePadding: false,
      label: 'Required Delivery Date',
    },
    {
      id: 'ActualDeliveryDate',
      numeric: false,
      disablePadding: false,
      label: 'Actual Delivery Date',
    },
    {
      id: 'FreeTimeDays',
      numeric: true,
      disablePadding: false,
      label: 'Free Time (Day)',
    },
    {
      id: 'ContainerNumbersText',
      numeric: false,
      disablePadding: false,
      label: 'Container Numbers',
    },
    {
      id: 'OrderNumbersText',
      numeric: false,
      disablePadding: false,
      label: 'Order Numbers',
    },
    {
      id: 'FocusedShipments',
      numeric: false,
      disablePadding: false,
      label: 'Focused',
    },
  ];

  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();
  const [myFeaturesContext, setMyFeaturesContext] = useContext(MyFeaturesContext);

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    var portOrigin = (data && data.specialSearch && data.specialSearch.portOriginCode) || '';
    var portDestination = (data && data.specialSearch && data.specialSearch.portDestinationCode) || '';
    var trans = (data && data.specialSearch && data.specialSearch.trans) || '';
    var mode = (data && data.specialSearch && data.specialSearch.mode) || '';

    apiService
      .getShipments(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers,
        portOrigin,
        portDestination,
        trans,
        mode,
        data.showFocusedShipment
      )
      .then((result) => {
        let contentRows = createTableListData(result.data.shipmentsReportItems);
        let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
        setHeadCells(filteredTable.headCells);
        setRows(filteredTable.contentRows);
        setTotalNumberOfRows(result.data.count);
        setHiddenColumnNames(result.data.hiddenColumnNames);
        progressRef.current.complete();
        handleBackdropClose();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) {
          progressRef.current.complete();
          console.log('error: ', err);
        }
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      let etd = moment(item.originETD);
      let atd = moment(item.originATD);
      let eta = moment(item.destinationETA);
      let ata = moment(item.destinationATA);
      let podeta = moment(item.podeta);
      let podata = moment(item.podata);
      let fdpeta = moment(item.fdpeta);
      let fdpata = moment(item.fdpata);

      let supplierBookingDate = item.supplierBookingDate ? moment(item.supplierBookingDate) : null;
      let cargoReadyDate = item.cargoReadyDate ? moment(item.cargoReadyDate) : null;
      let customsClearedDate = item.customsClearedDate ? moment(item.customsClearedDate) : null;
      let requiredDeliveryDate = item.requiredDeliveryDate ? moment(item.requiredDeliveryDate) : null;
      let actualDeliveryDate = item.actualDeliveryDate ? moment(item.actualDeliveryDate) : null;

      var shipmentNumberLinks = item.shipmentNumber;

      if (myFeaturesContext && myFeaturesContext.enabledTrackAndTrace && item.hasShipmentTracking) {
        shipmentNumberLinks = { component: 'linkList', links: [] };
        shipmentNumberLinks.links.push({
          text: item.shipmentNumber,
          path: '/trackandtrace?s=' + item.shipmentNumber,
        });
      } else if (item.hasDetails == true) {
        shipmentNumberLinks = { component: 'linkList', links: [] };
        shipmentNumberLinks.links.push({
          text: item.shipmentNumber,
          path: '/shipmentDetails?s=' + item.shipmentNumber + otherQueryString,
        });
      }

      var delayInDays = { text: item.delayInDays, className: '' };
      if (item.delayInDays !== null) {
        if (item.delayInDays > 0) {
          delayInDays.text = format('+{0}', item.delayInDays);
          if (item.delayInDays <= 3) {
            delayInDays.className = classes.arrivedLate;
          } else {
            delayInDays.className = classes.arrivedVeryLate;
          }
        } else {
          delayInDays.className = classes.arrivedOnTime;
        }
      }

      var orderNumbers = { component: 'tags', texts: [] };
      if (item.orderNumbers) {
        item.orderNumbers.map((orderNumber) => {
          orderNumbers.texts.push({ text: orderNumber });
        });
      }

      var containerNumbers = { component: 'tags', texts: [] };
      if (item.containerNumbers) {
        item.containerNumbers.map((number) => {
          containerNumbers.texts.push({ text: number });
        });
      }

      var sellerNames = { component: 'textList', texts: [] };
      if (item.orderSellers) {
        item.orderSellers.map((sellerName) => {
          sellerNames.texts.push({ text: sellerName });
        });
      }

      var focusedShipments = {
        component: 'checkbox',
        checked: item.hasFocusedShipment,
        action: () => handleCheckboxChange(item.shipmentNumber, item.hasFocusedShipment),
      };

      let rowData = {
        id: item.shipmentNumber,
        ShipmentNumber: shipmentNumberLinks,
        // Vessel: item.vessel,
        DepartureVessel: item.departureVessel,
        ArrivalVessel: item.arrivalVessel,
        Origin: item.origin,
        Destination: item.destination,
        PODName: item.podName,
        FDPName: item.fdpName,
        Trans: item.trans,
        Mode: item.mode,
        Supplier: item.supplier,
        OrderSellersText: sellerNames,
        Importer: item.importer,
        OriginETD: etd.isValid() ? etd.format('L') : '',
        OriginATD: atd.isValid() ? atd.format('L') : '',
        DestinationETA: eta.isValid() ? eta.format('L') : '',
        DestinationATA: ata.isValid() ? ata.format('L') : '',
        PODETA: podeta.isValid() ? podeta.format('L') : '',
        PODATA: podata.isValid() ? podata.format('L') : '',
        FDPETA: fdpeta.isValid() ? fdpeta.format('L') : '',
        FDPATA: fdpata.isValid() ? fdpata.format('L') : '',
        DelayInDays: delayInDays,
        Weight: helperService.thousands_separators(item.weight),
        Volume: helperService.thousands_separators(item.volume),
        TEU: helperService.thousands_separators(item.teu),
        CarrierCode: item.carrierCode,
        ContainerCount: item.containerCount,
        Incoterm: item.incoterm,
        SupplierBookingDate: supplierBookingDate ? supplierBookingDate.format('L') : '',
        CargoReadyDate: cargoReadyDate ? cargoReadyDate.format('L') : '',
        CustomsClearedDate: customsClearedDate ? customsClearedDate.format('L') : '',
        RequiredDeliveryDate: requiredDeliveryDate ? requiredDeliveryDate.format('L') : '',
        ActualDeliveryDate: actualDeliveryDate ? actualDeliveryDate.format('L') : '',
        FreeTimeDays: item.freeTimeDays,
        ContainerNumbersText: containerNumbers,
        OrderNumbersText: orderNumbers,
        FocusedShipments: focusedShipments,
      };

      newArray.push(rowData);
    });

    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      var portOrigin = (filter && filter.specialSearch && filter.specialSearch.portOriginCode) || '';
      var portDestination = (filter && filter.specialSearch && filter.specialSearch.portDestinationCode) || '';
      var trans = (filter && filter.specialSearch && filter.specialSearch.trans) || '';
      var mode = (filter && filter.specialSearch && filter.specialSearch.mode) || '';
      apiService
        .getShipments(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          portOrigin,
          portDestination,
          trans,
          mode
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generateShipmentPDF(
            result.data.shipmentsReportItems,
            'Shipments     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Shipment Report',
            result.data.hiddenColumnNames
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      var portOrigin = (filter && filter.specialSearch && filter.specialSearch.portOriginCode) || '';
      var portDestination = (filter && filter.specialSearch && filter.specialSearch.portDestinationCode) || '';
      var trans = (filter && filter.specialSearch && filter.specialSearch.trans) || '';
      var mode = (filter && filter.specialSearch && filter.specialSearch.mode) || '';
      apiService
        .getShipments(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          portOrigin,
          portDestination,
          trans,
          mode
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generateShipmentsCsvData(
            result.data.shipmentsReportItems,
            filter.accountName + '_' + filter.periodName,
            result.data.hiddenColumnNames
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleColumnSelected = (headCells) => {
    setNewHeadCells(headCells);
  };

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: '',
    success: false,
  });

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  const FocusShipmentAsync = async (shipmentNumber) => {
    let res = await apiService.focusShipment(shipmentNumber);
    if (res && res.errors.length === 0) {
      setSnackbar({
        open: true,
        msg: `Shipment No.${shipmentNumber} has been focus.`,
        success: true,
      });
      progressRef.current.continuousStart();
      getData();
    } else {
      setSnackbar({
        open: true,
        msg: `Shipment No.${shipmentNumber} has not been focus.`,
        success: false,
      });
    }
  };

  const UnfocusShipmentAsync = async (shipmentNumber) => {
    let res = await apiService.unfocusShipment(shipmentNumber);
    if (res && res.errors.length === 0) {
      setSnackbar({
        open: true,
        msg: `Shipment No.${shipmentNumber} has been unfocus.`,
        success: true,
      });
      progressRef.current.continuousStart();
      getData();
    } else {
      setSnackbar({
        open: true,
        msg: `Shipment No.${shipmentNumber} has not been unfocus.`,
        success: false,
      });
    }
  };

  const handleCheckboxChange = (shipmentNumber, focused) => {
    if (shipmentNumber) {
      setOpenBackdrop(true);
      if (focused) {
        UnfocusShipmentAsync(shipmentNumber);
      } else {
        FocusShipmentAsync(shipmentNumber);
      }
    }
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <FilledSnackbar open={snackbar.open} autoHideDuration={4000} onClose={handleSnackbarClose} isSuccess={snackbar.success} message={snackbar.msg} />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <EntityListFrame>
        <Grid container className={classes.titleArea}>
          <Grid item xs={12} sm className="align-self-center">
            <h4>Shipments</h4>
          </Grid>
          <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
            <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
          </Grid>
          <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
            <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTableFilter
            sendFilterComponentData={filterCallback}
            sendQueryStringsNoFreeTextSearch={setBaseQueryString}
            showSuppliers={true}
            showFocused={true}
          />
        </Grid>
        <Divider />

        <Grid item xs={12}>
          {rows === '' ? (
            ''
          ) : (
            <DataContentTable
              title=""
              headCells={newHeadCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              page={page}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </EntityListFrame>
    </>
  );
}

export default withRouter(ShipmentsTable);
