import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import { ThemeProvider } from '@material-ui/styles';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import EntityListFrame from '../../layouts/EntityListFrame';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { CSVLink } from 'react-csv';
import ExportMenu from '../../../wrappers/ExportMenu';
import { IconButton, Modal, Tooltip } from '@material-ui/core';
import ProductOverview from '../ProductOverview/ProductOverview';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import FeatureDisabledMessage from '../../parts/FeatureDisabledMessage';
import PurchaseOrderDataTableFilter from './PurchaseOrderDataTableFilter';
import moment from 'moment';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import ListAltIcon from '@material-ui/icons/ListAlt';

function PurchaseOrderDataTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('orderNumber');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [baseQueryString, setBaseQueryString] = React.useState();
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState('');
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [myFeaturesContext] = useContext(MyFeaturesContext);

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const useStyles = makeStyles((theme) => ({
    titleArea: {
      marginBottom: '28px',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    ExportMenuContainer: {
      flexGrow: 0,
    },
    modalContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContentContainer: {
      margin: 60,
      width: '100%',
      maxHeight: 'calc(100% - 40px)',
      maxWidth: 'calc(100vw - 40px)',
      display: 'flex',
    },
    overviewIcon: {
      color: theme.palette.primaryButton.backgroundColor,
    },
  }));

  const classes = useStyles();

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
    setSelectedAccountId(data.accountId);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getPurchaseOrderTableReport(
        data.accountId,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers,
        data.buyerId
      )
      .then((result) => {
        setProducts(result.data.items);
        setRows(createTableListData(result.data.items));
        setTotalNumberOfRows(result.data.count);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    var index = 0;
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      var shipmentStatuses = 'Pending';
      if (item.shipmentStatuses && item.shipmentStatuses.length > 0) {
        if (item.shipmentStatuses[0].shipmentNumber) {
          shipmentStatuses = { component: 'linkList', links: [] };
          item.shipmentStatuses.map((shipmentStatus) => {
            shipmentStatuses.links.push({
              text: shipmentStatus.shipmentNumber + ': ' + shipmentStatus.status,
              path: '/trackandtrace?s=' + shipmentStatus.shipmentNumber,
            });
          });
        } else {
          shipmentStatuses = item.shipmentStatuses[0].status;
        }
      }

      var momentTime = moment(item.orderDate);

      let cells = [item.orderNumber, item.orderNumber, item.supplier, item.deliverTo, momentTime.format('l'), shipmentStatuses];

      let tmpCell = {};
      tmpCell['id'] = item.orderNumber;
      headCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });
      newArray.push(tmpCell);
    });
    return newArray;
  };

  const headCells = [
    {
      id: 'orderNumber',
      numeric: false,
      disablePadding: true,
      label: 'Order Number',
    },
    {
      id: 'supplier',
      numeric: false,
      disablePadding: false,
      label: 'Supplier',
    },
    {
      id: 'deliverTo',
      numeric: false,
      disablePadding: false,
      label: 'Deliver To',
    },
    {
      id: 'orderDate',
      numeric: true,
      disablePadding: false,
      label: 'Order Date',
    },
    {
      id: 'shipmentStatuses',
      numeric: false,
      disablePadding: false,
      label: 'Shipment Status',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderTableReport(
          filter.accountId,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generatePurhcaseOrderEDIPDF(
            result.data.items,
            'Purchase Orders     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Purchase Orders Report'
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderTableReport(
          filter.accountId,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generatePurhcaseOrderEDICsvData(
            result.data.items,
            filter.accountName + '_' + filter.supplierName + '_' + filter.periodName
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleOverviewClick = (event) => {
    let selectedOrderNumber = event.currentTarget.parentNode.dataset.tag;
    setSelectedOrderNumber(selectedOrderNumber);
    setModalOpen(true);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
        <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
        <EntityListFrame>
          {myFeaturesContext &&
            (myFeaturesContext.enabledPurchaseOrder ? (
              <>
                <Grid container className={classes.titleArea}>
                  <Grid item xs={12} sm className="align-self-center">
                    <h4>Purchase Orders</h4>
                  </Grid>
                  <Grid item xs={12} sm className={'align-self-center ' + classes.ExportMenuContainer}>
                    <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <PurchaseOrderDataTableFilter
                    sendFilterComponentData={filterCallback}
                    sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                    showSuppliers={true}
                    showDateBasedOn={false}
                  />
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  {rows && (
                    <DataContentTable
                      title=""
                      headCells={headCells}
                      totalNumberOfRows={totalNumberOfRows}
                      rows={rows}
                      page={page}
                      handleRequestSort={handleRequestSort}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      rowActionComponent={
                        <Tooltip title="Purchase Order Details" placement="left">
                          <IconButton aria-label="edit" onClick={handleOverviewClick}>
                            <ListAltIcon size="small" className={classes.overviewIcon} />
                          </IconButton>
                        </Tooltip>
                      }
                    />
                  )}
                </Grid>
              </>
            ) : (
              <FeatureDisabledMessage />
            ))}
        </EntityListFrame>
        <Modal className={classes.modalContainer} open={modalOpen} onClose={handleModalClose}>
          <div className={classes.modalContentContainer}>
            <PurchaseOrderDetails orderNumber={selectedOrderNumber} accountId={selectedAccountId} onClose={handleModalClose} />
          </div>
        </Modal>
      </ThemeProvider>
    </>
  );
}

export default withRouter(PurchaseOrderDataTable);
