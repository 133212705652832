import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router';
import apiService from '../../../services/api.service';
import clsx from 'clsx';
import Button from '../../../wrappers/Button';
import Alert from '../../../wrappers/Alert';
import { Grid, Divider, Card, CardContent, TextField, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingBar from 'react-top-loading-bar';
import red from '@material-ui/core/colors/red';
import { ReloadContext } from '../../../contexts/ReloadContext';
import Snackbar from '@material-ui/core/Snackbar';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import '../../../index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },

  input: {
    display: 'none',
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    padding: theme.spacing(3),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

const AccountEntry = (props) => {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();
  const progressRef = useRef(null);
  const history = useHistory();
  let { id } = useParams();
  const { action } = props;

  const [company, setCompany] = useState();
  const [firstname, setFirstname] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const [lastname, setLastname] = useState();
  const [contact, setContact] = useState();
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [firstNameErrorMessage, setfirstNameErrorMessage] = useState('');
  const [lastNameErrorMessage, setlastNameErrorMessage] = useState('');
  const [phonenumberErrorMessage, setphonenumberErrorMessage] = useState('');
  const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState('');
  const [, setReloadContext] = useContext(ReloadContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    isSuccess: false,
    message: '',
  });

  useEffect(() => {
    if (id) {
      progressRef.current.continuousStart();
      apiService
        .getAccount(id)
        .then((result) => {
          setCompany(result.data.name);
          setContact(result.data.contactEmail);

          progressRef.current.complete();
        })
        .catch((err) => {
          console.log('error: ', err);
          if (progressRef && progressRef.current) progressRef.current.complete();

          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  }, []);

  const isEdit = () => {
    return id ? true : false;
  };

  const handleSubmit = (event) => {
    progressRef.current.continuousStart();
    event.preventDefault();
    clearErrors();
    const formData = new FormData();

    if (!isEdit()) {
      apiService
        .createAccount(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            name: company,
            contactEmail: contact,
            firstName: firstname,
            lastName: lastname,
            phoneNumber: phonenumber,
          },
        })
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            for (let error of result.errors) {
              handleSubmitError(error);
            }
            var errorString = '';
            for (var i = 0; i < result.errors.length; i++) {
              if (result.errors[i].errorMessage) {
                errorString += ' ' + result.errors[i].errorMessage + ',';
              } else errorString += ' ' + result.errors[i] + ',';
            }

            if (errorString) {
              setSnackbar({
                open: true,
                isSuccess: false,
                message: errorString,
              });
            }
          } else {
            setDisableSaveButton(true);
            setReloadContext(new Date());

            setSnackbar({
              open: true,
              isSuccess: true,
              message: `The account was created successfully`,
            });
          }
          progressRef.current.complete();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err) {
            setSnackbar({
              open: true,
              isSuccess: false,
              message: err,
            });
          }
        });
    } else {
      apiService
        .updateAccount(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            id: id,
            name: company,
            contactEmail: contact,
          },
        })
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            for (let error of result.errors) {
              handleSubmitError(error);
            }
            var errorString = '';
            for (var i = 0; i < result.errors.length; i++) {
              if (result.errors[i].errorMessage) {
                errorString += ' ' + result.errors[i].errorMessage + ',';
              } else errorString += ' ' + result.errors[i] + ',';
            }
            if (errorString) {
              setSnackbar({
                open: true,
                isSuccess: false,
                message: errorString,
              });
            }
          } else {
            setSnackbar({
              open: true,
              isSuccess: true,
              message: `The account was updated successfully`,
            });
            setReloadContext(new Date());
          }
          progressRef.current.complete();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err) {
            setSnackbar({
              open: true,
              isSuccess: false,
              message: err,
            });
          }
        });
    }
  };

  const handleSubmitError = (error) => {
    if (error.propertyName === 'Name') {
      setNameErrorMessage(error.errorMessage);
    } else if (error.propertyName === 'ContactEmail') {
      setContactEmailErrorMessage(error.errorMessage);
    } else if (error.propertyName === 'FirstName') {
      setfirstNameErrorMessage(error.errorMessage);
    } else if (error.propertyName === 'LastName') {
      setlastNameErrorMessage(error.errorMessage);
    } else if (error.propertyName === 'PhoneNumber') {
      setphonenumberErrorMessage(error.errorMessage);
    }
  };

  const clearErrors = () => {
    setNameErrorMessage('');
    setContactEmailErrorMessage('');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    let snackbartemp = { ...snackbar };
    snackbartemp.open = false;
    setSnackbar(snackbartemp);
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert theme={theme} onClose={handleSnackbarClose} severity="error" severity={snackbar.isSuccess ? 'success' : 'error'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {action === 'edit' ? <h4>Edit Account</h4> : <h4>Create New Account</h4>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company"
                  margin="dense"
                  name="company"
                  onChange={(e) => setCompany(e.target.value)}
                  required
                  error={!(nameErrorMessage === '')}
                  helperText={!(nameErrorMessage === '') ? nameErrorMessage : ''}
                  value={company || ''}
                />
              </Grid>
              {isEdit() ? (
                ''
              ) : (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Firstname"
                    margin="dense"
                    name="firstname"
                    error={!(firstNameErrorMessage === '')}
                    helperText={firstNameErrorMessage === '' ? '' : firstNameErrorMessage}
                    onChange={(e) => setFirstname(e.target.value)}
                    value={firstname || ''}
                    required
                  />
                </Grid>
              )}
              {isEdit() ? (
                ''
              ) : (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Lastname"
                    margin="dense"
                    name="lastname"
                    error={!(lastNameErrorMessage === '')}
                    helperText={lastNameErrorMessage === '' ? '' : lastNameErrorMessage}
                    onChange={(e) => setLastname(e.target.value)}
                    value={lastname || ''}
                    required
                  />
                </Grid>
              )}

              {isEdit() ? (
                ''
              ) : (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    margin="dense"
                    type="email"
                    name="phonenumber"
                    error={!(phonenumberErrorMessage === '')}
                    helperText={phonenumberErrorMessage === '' ? '' : phonenumberErrorMessage}
                    onChange={(e) => setPhonenumber(e.target.value)}
                    value={phonenumber || ''}
                    required
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Contact Email"
                  margin="dense"
                  name="contact"
                  type="email"
                  error={!(contactEmailErrorMessage === '')}
                  helperText={!(contactEmailErrorMessage === '') ? contactEmailErrorMessage : ''}
                  onChange={(e) => setContact(e.target.value)}
                  required
                  value={contact || ''}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            <Button theme={theme} type="submit" disabled={disableSaveButton} className={classes.buttons}>
              Save
            </Button>
            <Button theme={theme} className={clsx(classes.buttons, classes.buttonRight)} isSecondary="true" onClick={() => props.history.goBack()}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default withRouter(AccountEntry);
