import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router';

import apiService from '../../../services/api.service';
import clsx from 'clsx';
import Button from '../../../wrappers/Button';
import Alert from '../../../wrappers/Alert';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingBar from 'react-top-loading-bar';
import red from '@material-ui/core/colors/red';
import Snackbar from '@material-ui/core/Snackbar';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { UserContext } from '../../../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },

  input: {
    display: 'none',
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
}));

const FeaturesSettingEntry = (props) => {
  const classes = useStyles();
  const progressRef = useRef(null);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [snackbarError = '', setsnackbarError] = useState();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);
  const [userContext] = useContext(UserContext);
  const history = useHistory();
  let { id } = useParams();
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;

  const [model, setModel] = useState({
    accountId: '',
    accountName: '',
    enabledTrackAndTrace: false,
    enabledEDIReport: false,
    enabledTrendsOverTime: false,
    enabledCommercialInvoices: false,
    enabledSpendPerSuppliers: false,
    enabledProducts: false,
    enabledPurchaseOrder: false,
    enabledPurchaseOrderLinesFeature: false,
    enabledReportFilesFeature: false,
    enabledPurchaseOrderLineSpendsFeature: false,
    enabledPurchaseOrderLineMilestonesFeature: false,
    enabledExceptionsFeature: false,
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    setOpenSuccessSnackbar(false);
  };

  useEffect(() => {
    if (id) {
      progressRef.current.continuousStart();
      apiService
        .getAccountFeatures(id)
        .then((result) => {
          let model = {
            accountId: result.data.accountId,
            accountName: result.data.accountName,
            enabledTrackAndTrace: result.data.enabledTrackAndTrace,
            enabledEDIReport: result.data.enabledEDIReport,
            enabledTrendsOverTime: result.data.enabledTrendsOverTime,
            enabledCommercialInvoices: result.data.enabledCommercialInvoices,
            enabledSpendPerSuppliers: result.data.enabledSpendPerSuppliers,
            enabledProducts: result.data.enabledProducts,
            enabledPurchaseOrder: result.data.enabledPurchaseOrder,
            enabledPurchaseOrderLinesFeature: result.data.enabledPurchaseOrderLinesFeature,
            enabledReportFilesFeature: result.data.enabledReportFilesFeature,
            enabledPurchaseOrderLineSpendsFeature: result.data.enabledPurchaseOrderLineSpendsFeature,
            enabledPurchaseOrderLineMilestonesFeature: result.data.enabledPurchaseOrderLineMilestonesFeature,
            enabledExceptionsFeature: result.data.enabledExceptionsFeature,
          };
          setModel(model);

          progressRef.current.complete();
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');

          if (progressRef && progressRef.current) progressRef.current.complete();
        });
    }
  }, []);

  const handleEnabledFeatureChange = (event) => {
    setModel({ ...model, [event.target.name]: event.target.checked });
  };

  const handleSubmit = (event) => {
    progressRef.current.continuousStart();
    event.preventDefault();
    apiService
      .updateAccountFeatures(model)
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          var errorString = '';
          for (var i = 0; i < result.errors.length; i++) {
            if (result.errors[i].errorMessage) {
              errorString += ' ' + result.errors[i].errorMessage + ',';
            } else errorString += ' ' + result.errors[i] + ',';
          }
          setsnackbarError(errorString);
          setOpenSnackbar(true);
        } else {
          setDisableSaveButton(true);
          setOpenSuccessSnackbar(true);
        }
        progressRef.current.complete();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          history.push('/login');
        } else {
          if (progressRef && progressRef.current) progressRef.current.complete();

          setsnackbarError(err);
          setOpenSnackbar(true);
        }
      });
  };

  const canManage = () => {
    return userContext.isAdmin && userContext.permissions.canManageAccounts;
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {snackbarError}
          </Alert>
        </Snackbar>
        <Snackbar open={openSuccessSnackbar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Updated successfully
          </Alert>
        </Snackbar>
      </div>
      <h4 className={classes.title}> {model.accountName && model.accountName + ': '}Features Setting</h4>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <FormGroup row>
          <Grid container spacing={3}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledTrackAndTrace}
                      onChange={handleEnabledFeatureChange}
                      name="enabledTrackAndTrace"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Track and Trace"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledTrendsOverTime}
                      onChange={handleEnabledFeatureChange}
                      name="enabledTrendsOverTime"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Trends Over Time"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledCommercialInvoices}
                      onChange={handleEnabledFeatureChange}
                      name="enabledCommercialInvoices"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Commercial Invoices"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledSpendPerSuppliers}
                      onChange={handleEnabledFeatureChange}
                      name="enabledSpendPerSuppliers"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Spend Per Suppliers"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledProducts}
                      onChange={handleEnabledFeatureChange}
                      name="enabledProducts"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Products"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledEDIReport}
                      onChange={handleEnabledFeatureChange}
                      name="enabledEDIReport"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Document Integrations"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledPurchaseOrder}
                      onChange={handleEnabledFeatureChange}
                      name="enabledPurchaseOrder"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Purchase Orders"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledPurchaseOrderLinesFeature}
                      onChange={handleEnabledFeatureChange}
                      name="enabledPurchaseOrderLinesFeature"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Purchase Order Lines"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledPurchaseOrderLineSpendsFeature}
                      onChange={handleEnabledFeatureChange}
                      name="enabledPurchaseOrderLineSpendsFeature"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Purchase Order Line Spends"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledPurchaseOrderLineMilestonesFeature}
                      onChange={handleEnabledFeatureChange}
                      name="enabledPurchaseOrderLineMilestonesFeature"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Purchase Order Line Milestones"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledReportFilesFeature}
                      onChange={handleEnabledFeatureChange}
                      name="enabledReportFilesFeature"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Download Report Files"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={model.enabledExceptionsFeature}
                      onChange={handleEnabledFeatureChange}
                      name="enabledExceptionsFeature"
                      color="primary"
                      disabled={canManage() === false}
                    />
                  }
                  label="Enable Exceptions Report"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {canManage() ? (
              <Grid item xs={12} className={classes.buttonsContainer}>
                <Button theme={theme} type="submit" disabled={disableSaveButton} className={classes.buttons}>
                  Save
                </Button>
                <Button theme={theme} className={clsx(classes.buttons, classes.buttonRight)} isSecondary="true" onClick={() => props.history.goBack()}>
                  Cancel
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.buttonsContainer}>
                <Button theme={theme} className={clsx(classes.buttons, classes.buttonRight)} isSecondary="true" onClick={() => props.history.goBack()}>
                  Back
                </Button>
              </Grid>
            )}
          </Grid>
        </FormGroup>
      </form>
    </>
  );
};

export default withRouter(FeaturesSettingEntry);
