import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chargeableWeightCalculator: {
    backgroundColor: '#e7e7e7',
    padding: theme.spacing(2),
  },
  inputTitle: {
    minHeight: 32,
    alignItems: 'center',
  },
  inputControl: {
    width: '100%',
    '& div': {
      backgroundColor: '#b49ae8',
      borderRadius: 0,
    },
    '& input': {
      color: 'white !important',
      paddingTop: 0,
      paddingBottom: 0,
      height: 34,
    },
    '& fieldset': {
      borderColor: '#e7e7e7',
    },
    '& :hover fieldset': {
      borderColor: '#bb86fc !important',
    },
    '& .Mui-focused fieldset': {
      borderColor: '#bb86fc !important',
    },
  },
  inputControlLeft: {
    '& div': {
      'border-top-left-radius': 4,
      'border-bottom-left-radius': 4,
    },
  },
  inputControlRight: {
    '& div': {
      'border-top-right-radius': 4,
      'border-bottom-right-radius': 4,
    },
  },
  totalText: {
    fontSize: '1.75rem',
    fontWeight: 600,
  },
}));

const CustomInput = withStyles((theme) => ({
  input: {
    color: 'White',
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,0.5)',
    paddingRight: '26px',
    paddingLeft: 14,
    '&:focus': {
      borderColor: '#6934D1',
      backgroundColor: '#b49ae8',
    },
  },
}))(InputBase);

const CustomSwitch = withStyles((theme) => ({
  switchBase: {
    color: '#6934d1',
    '&$checked': {
      color: '#6934d1',
    },
    '&$checked + $track': {
      backgroundColor: '#b49ae8',
    },
  },
  checked: {},
  track: {},
}))(Switch);

function ChargeableWeightCalculator(props) {
  const classes = useStyles();
  const history = useHistory();

  const cbmToCft = 35.319837;
  const inToCm = 2.54;
  const kgToLb = 2.20462;

  const [qtyInput, setQtyInput] = useState(0);

  const [isSpecifyDimensions, setIsSpecifyDimensions] = useState(true);
  const [dimensionCmValue, setDimensionCmValue] = useState({ length: 0, width: 0, height: 0 });
  const [dimensionInput, setDimensionInput] = useState({ length: 0, width: 0, height: 0, unit: 'cm' });
  const [volumeCbmValue, setVolumeCbmValue] = useState(0);
  const [volumeInput, setVolumeInput] = useState({ amount: 0, unit: 'cbm' });
  const [weightKgValue, setWeightKgValue] = useState(0);
  const [weightInput, setWeightInput] = useState({ amount: 0, unit: 'kg' });

  //nd: need to remove
  const [lengthInput, setLengthInput] = useState(0);
  const [widthInput, setWidthInput] = useState(0);
  const [heightInput, setHeightInput] = useState(0);
  //const [dimensionUnitInput, setDimensionInput] = useState('cm');
  //const [volumeInput, setVolumeInput] = useState(0);
  const [volumeUnitInput, setVolumeUnitInput] = useState('cbm');
  //const [weightInput, setWeightInput] = useState(0);
  const [weightUnitInput, setWeightUnitInput] = useState('kg');
  //

  const [isStandardDimFactor, setIsStandardDimFactor] = useState(true);
  const [standardDimFactorInput, setStandardDimFactorInput] = useState(0);
  const [customDimFactorInput, setCustomDimFactorInput] = useState(0);
  const [customDimFactorUnitInput, setCustomDimFactorUnitInput] = useState(0);
  const [dimFactorValue, setDimFactorValue] = useState(0);

  const [dimWeight, setDimWeight] = useState(0);
  const [totalShipmentWeight, setTotalShipmentWeight] = useState(0);

  const [triggerCalculateDimWeight, setTriggerCalculateDimWeight] = useState(0);
  const [triggerCalculateTotalShipmentWeight, setTriggerCalculateTotalShipmentWeight] = useState(0);

  const handleStandardDimFactorChange = (e) => {
    setStandardDimFactorInput(e.target.value);
  };

  useEffect(() => {
    function calculateVolume(length, width, height, qty, unit) {
      let volume = length * width * height * qty;
      if (unit === 'cm') {
        volume = volume / 100 / 100 / 100;
      } else if (unit === 'in') {
        volume = volume / 12 / 12 / 12;
      }
      return volume;
    }

    if (isSpecifyDimensions) {
      let volume = calculateVolume(dimensionInput.length, dimensionInput.width, dimensionInput.height, qtyInput, dimensionInput.unit);
      setVolumeInput(volume);
      setTriggerCalculateDimWeight(triggerCalculateDimWeight + 1);
    }
  }, [qtyInput, dimensionInput, isSpecifyDimensions]);

  useEffect(() => {
    function calculateDimWeight(volume, volumeUnit, dimFactorValue) {
      let dimWeight = 0;
      if (dimFactorValue > 0) {
        let cbmValue = volume;
        if (volumeUnit === 'cft') {
          cbmValue = convertCFTtoCBM(volume);
        }
        dimWeight = (cbmValue / dimFactorValue) * 1000000;
      }
      return dimWeight;
    }

    let dimWeight = calculateDimWeight(volumeInput, volumeUnitInput, dimFactorValue);
    setDimWeight(dimWeight);
  }, triggerCalculateDimWeight);

  useEffect(() => {
    let totalShipmentWeight = weightInput * qtyInput;
    setTotalShipmentWeight(totalShipmentWeight);
  }, triggerCalculateTotalShipmentWeight);

  useEffect(() => {
    let weight;
    if (weightInput.unit === 'kg') {
      weight = convertLBtoKG(weightInput);
    } else {
      weight = convertKGtoLB(weightInput);
    }
    setWeightInput(weight);
    setTriggerCalculateTotalShipmentWeight(triggerCalculateTotalShipmentWeight + 1);
    setTriggerCalculateDimWeight(triggerCalculateDimWeight + 1);
  }, weightInput);

  function convertCBMtoCFT(value) {
    return value * cbmToCft;
  }
  function convertCFTtoCBM(value) {
    return value / cbmToCft;
  }

  function convertINtoCM(value) {
    return value * inToCm;
  }
  function convertCMtoIN(value) {
    return value / inToCm;
  }

  function convertKGtoLB(value) {
    return value * kgToLb;
  }
  function convertLBtoKG(value) {
    return value / kgToLb;
  }

  const handleQtyChange = (event) => {
    setQtyInput(event.target.value);
  };
  const handleLengthChange = (event) => {
    setLengthInput(event.target.value);
  };
  const handleWidthChange = (event) => {
    setWidthInput(event.target.value);
  };
  const handleHeightChange = (event) => {
    setHeightInput(event.target.value);
  };

  return (
    <Paper className={classes.root + ' ' + classes.chargeableWeightCalculator}>
      <Grid container spacing={3}>
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h6">Chargeable Weight Calculator</Typography>
        </Grid>
        {/* Controls */}
        <Grid item container spacing={2}>
          {/* Qty */}
          <Grid item spacing={1} direction="column" xs={4} md={1} container>
            <Grid item className={classes.inputTitle}>
              <Typography variant="caption">Qty</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="qtyField"
                label=""
                variant="outlined"
                value={qtyInput}
                onChange={handleQtyChange}
                className={classes.inputControl + ' ' + classes.inputControlLeft + ' ' + classes.inputControlRight}
              />
            </Grid>
          </Grid>
          {/* Specify dimensions */}
          <Grid item spacing={1} direction="column" xs={12} md={11} lg={8} container>
            <Grid item className={classes.inputTitle}>
              <Typography variant="caption">
                <Grid container alignItems="center">
                  <Grid item>Specify dimensions</Grid>
                  <Grid item>
                    <CustomSwitch size="small" color="primary" />
                  </Grid>
                  <Grid item>Total volume</Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item container direction="row">
              <Grid item xs>
                <TextField id="lengthField" label="" variant="outlined" value={lengthInput} className={classes.inputControl + ' ' + classes.inputControlLeft} />
              </Grid>
              <Grid item xs>
                <TextField id="widthField" label="" variant="outlined" value={widthInput} className={classes.inputControl} />
              </Grid>
              <Grid item xs>
                <TextField id="heightField" label="" variant="outlined" value={heightInput} className={classes.inputControl} />
              </Grid>
              <Grid item xs style={{ display: 'flex' }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="select-dimensions-unit"
                  variant="outlined"
                  className={[classes.inputControl].join(' ')}
                  input={<CustomInput />}
                >
                  <MenuItem key={'cm'} value={'cm'}>
                    cm
                  </MenuItem>
                  <MenuItem key={'in'} value={'in'}>
                    in
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs>
                <TextField id="qtyField" label="" variant="outlined" className={classes.inputControl} />
              </Grid>
              <Grid item xs style={{ display: 'flex' }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="select-volume-unit"
                  variant="outlined"
                  className={[classes.inputControl, classes.inputControlRight].join(' ')}
                  input={<CustomInput />}
                >
                  <MenuItem key={'cbm'} value={'cbm'}>
                    cbm
                  </MenuItem>
                  <MenuItem key={'cft'} value={'cft'}>
                    cft
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Unit weight */}
          <Grid item spacing={1} direction="column" xs={8} md={4} lg={3} container>
            <Grid item className={classes.inputTitle}>
              <Typography variant="caption">Unit weight</Typography>
            </Grid>
            <Grid item container direction="row" justify="flex-start">
              <Grid item xs>
                <TextField id="qtyField" label="" variant="outlined" className={[classes.inputControl, classes.inputControlLeft].join(' ')} />
              </Grid>
              <Grid item xs style={{ display: 'flex' }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="select-weight-unit"
                  variant="outlined"
                  className={[classes.inputControl, classes.inputControlRight].join(' ')}
                  input={<CustomInput />}
                >
                  <MenuItem key={'kg'} value={'kg'}>
                    kg
                  </MenuItem>
                  <MenuItem key={'lb'} value={'lb'}>
                    lb
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* Dim factor */}
          <Grid item spacing={1} direction="column" xs={12} md={8} lg={5} container>
            <Grid item className={classes.inputTitle}>
              <Typography variant="caption">
                <Grid container alignItems="center">
                  <Grid item>Standard Dim Factors</Grid>
                  <Grid item>
                    <CustomSwitch size="small" color="primary" />
                  </Grid>
                  <Grid item>Custom</Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                value={standardDimFactorInput}
                onChange={handleStandardDimFactorChange}
                className={[classes.inputControl, classes.inputControlLeft, classes.inputControlRight].join(' ')}
                input={<CustomInput />}
              >
                <MenuItem value={0}>
                  <em>Select Dim Factor</em>
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Ocean LCL <em>(1:1000)</em>
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Truck LTL (EU) <em>(1:3000)</em>
                </MenuItem>
                <MenuItem key={3} value={3}>
                  Express Fright/Courier <em>(1:5000)</em>
                </MenuItem>
                <MenuItem key={4} value={4}>
                  Air Freight <em>(1:6000)</em>
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          {/* Dim weight */}
          <Grid item spacing={1} direction="column" xs={6} md container>
            <Grid item className={classes.inputTitle}>
              <Typography variant="caption">Dim weight</Typography>
            </Grid>
            <Grid item>
              <Typography component="label" variant="h5" className={classes.totalText}>
                {dimWeight}
              </Typography>
              <Typography component="label" variant="caption">
                {weightUnitInput}
              </Typography>
            </Grid>
          </Grid>
          {/* Total shipment weight */}
          <Grid item spacing={1} direction="column" xs={6} md container>
            <Grid item className={classes.inputTitle}>
              <Typography variant="caption">Total shipment weight</Typography>
            </Grid>
            <Grid item>
              <Typography component="label" variant="h5" className={classes.totalText}>
                {totalShipmentWeight}
              </Typography>
              <Typography component="label" variant="caption">
                {weightUnitInput}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ChargeableWeightCalculator;
