import { createContext } from 'react';

export const UserContext = createContext({
  firstName: '',
  isAdmin: false,
  permissions: {
    canManageAccounts: false,
    canReadAccounts: false,
    canManageUsers: false,
    canReadUsers: false,
  },
});
