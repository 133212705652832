import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '150px',
    width: '100%',
    marginBottom: '20px',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.topRedBar.backgroundColor,
  },
  logoImg: {
    //filter: theme.palette.topRedBar.logoImgFilter,
  },
}));

function TopRedBar(props) {
  const classes = useStyles();
  let logoImageUrl = parseInt(localStorage.getItem('theme')) === 1 ? '/images/VERUS-LOGO-TOP.png' : '/images/VERUS-LOGO-TOP-dark.png';
  return (
    <div className={classes.root}>
      <div style={{ position: 'absolute', top: '85px', right: '25px' }}>
        <img src={logoImageUrl} alt="verus-global-logo" className={classes.logoImg} />
      </div>
    </div>
  );
}

export default TopRedBar;
