import React, { useEffect, useRef, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { ReloadContext } from '../../../contexts/ReloadContext';
import { UserContext } from '../../../contexts/UserContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import DataTableFilter from '../../parts/DataTableFilter';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import helperService from '../../../services/help.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { CSVLink } from 'react-csv';
import ExportMenu from '../../../wrappers/ExportMenu';
import FeatureDisabledMessage from '../../parts/FeatureDisabledMessage';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';

function SpendPerSupplierTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Supplier');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [userContext] = useContext(UserContext);
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [myFeaturesContext] = useContext(MyFeaturesContext);

  const [reloadContext] = useContext(ReloadContext);

  const progressRef = useRef(null);
  const history = useHistory();
  let match = useRouteMatch();

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const useStyles = makeStyles((theme) => ({
    titleText: {
      marginBottom: '30px',
    },
    pdfboxArea: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    titleArea: {
      marginBottom: '28px',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  }));

  const classes = useStyles();

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getSpendPerSupplierTable(data.accountId, data.start, data.end, order, orderBy, page * rowsPerPage, rowsPerPage, data.freeTextSearch)
      .then((result) => {
        setRows(createTableListData(result.data.items));
        setTotalNumberOfRows(result.data.count);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    var index = 0;
    items.map((item) => {
      let cells = [index++, item.supplier, helperService.thousands_separators(item.amount)];

      let tmpCell = {};
      tmpCell['id'] = item.id;
      headCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });
      newArray.push(tmpCell);
    });
    return newArray;
  };

  const headCells = [
    {
      id: 'Supplier',
      numeric: false,
      disablePadding: true,
      label: 'Supplier',
    },
    {
      id: 'Amount',
      numeric: true,
      disablePadding: false,
      label: 'Total Spend ($)',
      tooltip: 'Total Charges billed to you by Verus',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };
  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getSpendPerSupplierTable(filter.accountId, filter.start, filter.end, order, orderBy, 0, totalNumberOfRows, filter.freeTextSearch)
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generateSpendPerSupplierPDF(
            result.data.items,
            'Spend Per Supplier     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Spend Per Supplier Report'
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getSpendPerSupplierTable(filter.accountId, filter.start, filter.end, order, orderBy, 0, totalNumberOfRows, filter.freeTextSearch)
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generateSpendPerSupplierCsvData(result.data.items, filter.accountName + '_' + filter.periodName);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <EntityListFrame>
        {myFeaturesContext &&
          (myFeaturesContext.enabledSpendPerSuppliers ? (
            <>
              <Grid container className={classes.titleArea}>
                <Grid item xs={12} sm className="align-self-center">
                  <h4>Spend Per Supplier</h4>
                </Grid>
                <Grid item className={'align-self-center'}>
                  <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataTableFilter sendFilterComponentData={filterCallback} showDateBasedOn={false} />
              </Grid>
              <Divider />

              <Grid item xs={12}>
                {rows === '' ? (
                  ''
                ) : (
                  <DataContentTable
                    title=""
                    headCells={headCells}
                    totalNumberOfRows={totalNumberOfRows}
                    rows={rows}
                    page={page}
                    handleRequestSort={handleRequestSort}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                )}
              </Grid>
            </>
          ) : (
            <FeatureDisabledMessage />
          ))}
      </EntityListFrame>
    </>
  );
}

export default withRouter(SpendPerSupplierTable);
