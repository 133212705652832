import { Grid, TextField, makeStyles, Typography, Button, withStyles, IconButton, Divider, FormControl, Select, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useBookingStore } from '../../../contexts/BookingContext';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section: {
        backgroundColor: '#0000001f',
        borderRadius: 8,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    formControl: {
        minWidth: 210,
    },
    formControlUnit: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: theme.palette.inputControl.backgroundColor,
        }
    },
    selectOutline: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.inputControl.backgroundColor,
    },
    input: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
    unitInput: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
    row: {
        padding: 12,
        background: '#0000000f',
        borderRadius: 8,
        marginBottom: theme.spacing(0.5)
    }
}));

const AddButton = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        color: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primaryButton.hoverBackgroundColor,
        }
    },
}))((props) => (
    <Button
        {...props}
        variant="text"
        size="small"
        disableElevation
        disableRipple
        startIcon={<AddCircleIcon />}
    >
        Add goods/packs
    </Button>
));

const DeleteButton = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5),
        color: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primaryButton.hoverBackgroundColor,
        }
    },
}))((props) => (
    <IconButton
        aria-label="delete"
        {...props}>
        <DeleteIcon />
    </IconButton>
));

const SmallTextField = (props) => {
    const { width } = props;
    const classes = useStyles();
    return <TextField variant="outlined" InputProps={{
        className: classes.input, style: { width: width || 180 }
    }}{...props} />;
}

const UnitTextField = (props) => {
    const { width } = props;
    const classes = useStyles();
    return <TextField variant="outlined" InputProps={{
        className: classes.unitInput, style: { width: width || 120 }
    }}{...props} />;
}

const DropdownPackType = (props) => {
    const { width, goodsId, value, handleInputChange } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [packType, setPackType] = useState("Pallet");

    useEffect(() => {
        if (value) { setPackType(value) }
    }, [])

    useEffect(() => {
        if (packType !== value) {
            handleInputChange(goodsId, 'packType', packType)
        }
    }, [packType])

    return (
        <FormControl className={classes.formControlUnit} style={{ width: width || 100 }}>
            <Select
                labelId="packtype"
                id={"packtype"}
                variant="outlined"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={packType}
                onChange={(e) => setPackType(e.target.value)}
                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
            >
                <MenuItem value={"Pallet"}>Pallet</MenuItem>
                <MenuItem value={"Package"}>Package</MenuItem>
                <MenuItem value={"Unit"}>Unit</MenuItem>
            </Select>
        </FormControl>
    )
}

const DropdownUD = (props) => {
    const { width, goodsId, value, handleInputChange } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [UD, setUD] = useState("Meters");

    useEffect(() => {
        if (value) { setUD(value) }
    }, [])

    useEffect(() => {
        if (UD !== value) {
            handleInputChange(goodsId, 'ud', UD)
        }
    }, [UD])

    return (
        <FormControl className={classes.formControlUnit} style={{ width: width || 100 }}>
            <Select
                labelId="weight"
                id="weight"
                variant="outlined"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={UD}
                onChange={(e) => setUD(e.target.value)}
                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
            >
                <MenuItem value={"Inches"}>IN</MenuItem>
                <MenuItem value={"Kilometers"}>KM</MenuItem>
                <MenuItem value={"Meters"}>M</MenuItem>
            </Select>
        </FormControl>
    )
}

const DropdownUQ = (props) => {
    const { width, goodsId, value, handleInputChange } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [UQ, setUQ] = useState("Cubic Meters");

    useEffect(() => {
        if (value) { setUQ(value) }
    }, [])

    useEffect(() => {
        if (UQ !== value) {
            handleInputChange(goodsId, 'uq', UQ)
        }
    }, [UQ])

    return (
        <FormControl className={classes.formControlUnit} style={{ width: width || 100 }}>
            <Select
                labelId="weight"
                id="weight"
                variant="outlined"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={UQ}
                onChange={(e) => setUQ(e.target.value)}
                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
            >
                <MenuItem value={"Liter"}>L</MenuItem>
                <MenuItem value={"Cubic Meters"}>M3</MenuItem>
                <MenuItem value={"Mega Liter"}>ML</MenuItem>
            </Select>
        </FormControl>
    )
}

function GoodsOrPacks(props) {
    const bookingStore = useBookingStore();
    const classes = useStyles();
    const [goods, setGoods] = useState(toJS(bookingStore.goodsOrPacks));

    const tmpGoods = { id: "", pieces: "", packType: "", length: "", width: "", height: "", ud: "", weight: "", uq: "", description: "", markAndNumbers: "", linePrice: "", tariffNum: "" };
    const randomNumber = () => {
        const number = Math.floor(100000 + Math.random() * 900000);
        return String(number);
    }

    useEffect(() => {
        if (goods && goods.length < 1) {
            tmpGoods.id = randomNumber();
            setGoods(prevState => [...prevState, tmpGoods]);
        }
    }, [])

    const handleAddButtonClick = () => {
        const newGoods = tmpGoods;
        newGoods.id = randomNumber();
        setGoods(prevState => [...prevState, newGoods]);
    }

    const handleDelete = (goodsId) => {
        let newGoods = goods.filter(x => x.id !== goodsId);
        console.log("newGoods", newGoods);
        setGoods(newGoods);
    }

    useEffect(() => {
        if (goods) {
            console.log("Goods", goods);
            bookingStore.setGoodsOrPacks(goods);
            console.log("goodsOrPacks", toJS(bookingStore.goodsOrPacks));
        }
    }, [goods])

    const handleInputChange = (goodsId, key, value) => {
        const tmpGoods = goods.map(item => {
            if (item.id === goodsId) {
                item[key] = value;
            }
            return item;
        })
        setGoods(tmpGoods);
    }

    return (
        <>
            <Grid item container xs={12} alignItems="center">
                {goods.length > 0 && goods.map((item, index) => <Grid item container xs={12} alignItems="center" key={`${item.id + index}`} className={classes.row}>
                    <Grid item ><SmallTextField width={120} placeholder="Pieces" value={item.pieces} onChange={(e) => handleInputChange(item.id, 'pieces', e.target.value)} /></Grid>
                    <Grid item ><DropdownPackType width={125} goodsId={item.id} value={item.packType} handleInputChange={handleInputChange} /></Grid>
                    <Grid item ><UnitTextField width={110} placeholder="Length" value={item.length} onChange={(e) => handleInputChange(item.id, 'length', e.target.value)} /></Grid>
                    <Grid item ><UnitTextField width={110} placeholder="Width" value={item.width} onChange={(e) => handleInputChange(item.id, 'width', e.target.value)} /></Grid>
                    <Grid item ><UnitTextField width={110} placeholder="Height" value={item.height} onChange={(e) => handleInputChange(item.id, 'height', e.target.value)} /></Grid>
                    <Grid item ><DropdownUD width={70} goodsId={item.id} value={item.ud} handleInputChange={handleInputChange} /></Grid>
                    <Grid item ><UnitTextField width={110} placeholder="Weight" value={item.weight} onChange={(e) => handleInputChange(item.id, 'weight', e.target.value)} /></Grid>
                    <Grid item ><DropdownUQ width={70} goodsId={item.id} value={item.uq} handleInputChange={handleInputChange} /></Grid>
                    <Grid item ><SmallTextField width={125} placeholder="Description" value={item.description} onChange={(e) => handleInputChange(item.id, 'description', e.target.value)} /></Grid>
                    <Grid item ><SmallTextField width={160} placeholder="Marks and Number" value={item.markAndNumbers} onChange={(e) => handleInputChange(item.id, 'markAndNumbers', e.target.value)} /></Grid>
                    <Grid item ><SmallTextField width={110} placeholder="Line Price" value={item.linePrice} onChange={(e) => handleInputChange(item.id, 'linePrice', e.target.value)} /></Grid>
                    <Grid item ><SmallTextField width={110} placeholder="Tariff Num." value={item.tariffNum} onChange={(e) => handleInputChange(item.id, 'tariffNum', e.target.value)} /></Grid>
                    <Grid item xs justifyContent="flex-end"><DeleteButton onClick={() => handleDelete(item.id)} /></Grid>
                </Grid>
                )}
                <Grid item xs={12}>
                    <AddButton onClick={handleAddButtonClick} />
                </Grid>
            </Grid>
        </>
    );
}

export default GoodsOrPacks;