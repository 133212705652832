import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import authService from '../../services/auth.service';
import { UserContext } from '../../contexts/UserContext';
import { AdminDrawerContext } from '../../contexts/AdminDrawerContext';
import { ReloadContext } from '../../contexts/ReloadContext';
import apiService from '../../services/api.service';
import clsx from 'clsx';
import moment from 'moment';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import StorageIcon from '@material-ui/icons/Storage';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Refresh from '@material-ui/icons/Refresh';
import { useMediaLayout } from 'use-media';
import NotificationPopup from '../parts/NotificationPopup';
import useComponentVisible from '../../hooks/useComponentVisible';
import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import Group from '@material-ui/icons/Group';
import AddBoxIcon from '@material-ui/icons/AddBox';

import PublicIcon from '@material-ui/icons/Public';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';

import MenuFont from '../Typography/MenuFont';
import TimelineIcon from '@material-ui/icons/Timeline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import StoreIcon from '@material-ui/icons/Store';
import MapIcon from '@material-ui/icons/Map';
import { MyFeaturesContext } from '../../contexts/MyFeaturesContext';
import CloudIcon from '@material-ui/icons/Cloud';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const useListMenuItemStyles = makeStyles((theme) => ({
  listItem: {
    color: '#fff',
    paddingLeft: theme.spacing(3),
    '&:hover': {
      color: '#fff',
    },
  },
  listItemIcon: {
    color: theme.palette.adminDrawer.text,
  },
  menuFont: {
    fontSize: '1rem',
  },
}));
function ListMenuItem(props) {
  const classes = useListMenuItemStyles();
  const { title, tooltipTitle, linkTo, iconComponent } = props;
  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : title} placement="left">
      <ListItem button component={Link} to={linkTo} className={classes.listItem}>
        <ListItemIcon className={classes.listItemIcon}>{iconComponent}</ListItemIcon>
        <MenuFont className={classes.menuFont}>{title}</MenuFont>
      </ListItem>
    </Tooltip>
  );
}

const drawerWidth = 250;

const useAdminDrawerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  drawerButtons: {
    color: theme.palette.adminDrawer.button,
  },
  topDrawerButtons: {
    color: theme.palette.adminDrawer.topButton,
    backgroundColor: 'rgb(255, 255, 255,0.6)',
    height: '32px',
    width: '32px',
    margin: '0 0 0 13px',
    '&:hover,&:focus': {
      backgroundColor: '#e0dbdb',
    },
  },
  logo: {
    maxWidth: 35,
  },
  minWidth: {
    minWidth: '35px',
  },
  avatar: {
    height: '35px',
    width: '35px',
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: theme.palette.adminDrawer.main,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.adminDrawer.main,
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'rgb(190,190,190,0.3)',
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.adminDrawer.button,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    paddingLeft: '10px',
    flexGrow: 1,
    color: theme.palette.adminDrawer.text,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.adminDrawer.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100vh',
    overflowX: 'hidden',
    '& ::-webkit-scrollbar': {
      width: 8,
    },
  },
  drawerPaperClose: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  customBadge: {
    backgroundColor: '#BB86FC',
    color: 'white',
  },
  text: {
    color: theme.palette.adminDrawer.text,
  },
  'message-text > a': {
    color: theme.palette.adminDrawer.text,
  },
  divider: {
    background: theme.palette.adminDrawer.divider,
  },
  bottom: {
    borderTop: '1px solid #ffffff33',
    marginTop: 'auto',
  },
  fontSizeText: {
    fontSize: '1rem',
  },
  menuScoll: {
    overflowY: 'overlay',
    overflowX: 'hidden',
  }
}));

export default function AdminDrawer() {
  const classes = useAdminDrawerStyles();
  const [profileUrl, setProfileUrl] = useState('');
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const openProfile = Boolean(profileAnchorEl);
  const [userContext, setUserContext] = useContext(UserContext);
  const [adminDrawerContext, setAdminDrawerContext] = useContext(AdminDrawerContext);

  const [reloadContext, setReloadContext] = useContext(ReloadContext);
  const [notificationBadge, setNotificationBadge] = useState();
  const [myFeaturesContext, setMyFeaturesContext] = useContext(MyFeaturesContext);

  // const [openNotificationPopup, setOpenNotificationPopup] = useState(false);

  const isWide = useMediaLayout({ maxWidth: '1000px' });

  const history = useHistory({ forceRefresh: true });
  const { ref1, ref2, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userContext === undefined || userContext.id === undefined) {
          const result = await apiService.getMe();
          localStorage.setItem('theme', result.themeColor);
          setUserContext(result);
        } else {
          localStorage.setItem('theme', userContext.themeColor);
        }

        const myFeaturesResponse = await apiService.getMyFeatures();
        setMyFeaturesContext(myFeaturesResponse.data);
      } catch (ex) {
        history.push('/login');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (notificationBadge && !isComponentVisible) {
      var messages = [];
      var messagesToCleanUp = userContext.messages.filter((x) => x.isRead === false);

      for (let mess of messagesToCleanUp) messages.push(mess.id);

      if (messages.length > 0) {
        apiService.setMessageAsRead(messages).then(() => {
          apiService.getMe().then((result) => {
            setUserContext(result);
            console.log('LOADNING....');
          });
        });
      }
    }
  }, [isComponentVisible]);

  useEffect(() => {
    if (isWide) {
      setAdminDrawerContext(false);
    }
  }, [isWide]);

  useEffect(() => {
    setProfileUrl(
      userContext && userContext.avatarExtension
        ? '/dynamic/user/images/' + userContext.id + '.' + userContext.avatarExtension + '?v' + moment().format('YYYYMMDDhhmm')
        : ''
    );
    setNotificationBadge(userContext && userContext.messages && userContext.messages.filter((x) => x.isRead === false).length);
  }, [userContext]);

  const handleMenu = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleLogout = (event) => {
    authService.logout();
    setUserContext(null);
    history.push('/login');
  };

  const handleClose = () => {
    setProfileAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setAdminDrawerContext(true);
  };
  const handleDrawerClose = () => {
    setAdminDrawerContext(false);
  };

  return (
    <div className={classes.root}>
      <div
        ref={ref1}
        style={{
          position: 'absolute',
          top: '42px',
          right: '85px',
          zIndex: '1500',
        }}
      >
        {isComponentVisible && (
          <p onClick={() => setIsComponentVisible(true)}>
            <NotificationPopup messages={userContext.messages} />
          </p>
        )}
      </div>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, adminDrawerContext && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} style={{ background: 'transparent', boxShadow: 'none' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, adminDrawerContext && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          {/* <img src={logo_company} alt="logo" className={classes.logo} /> */}
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}></Typography>
          <Tooltip title="Refresh">
            <IconButton color="inherit" className={classes.topDrawerButtons} onClick={() => setReloadContext(new Date())}>
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications" ref={ref2}>
            <IconButton
              color="inherit"
              className={classes.topDrawerButtons}
              onClick={() => {
                isComponentVisible ? setIsComponentVisible(false) : setIsComponentVisible(true);
              }}
            >
              <Badge badgeContent={notificationBadge} classes={{ badge: classes.customBadge }}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <div>
            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
              {userContext && userContext.id ? <Avatar src={profileUrl} className={classes.avatar}></Avatar> : ''}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={profileAnchorEl}
              getContentAnchorEl={null}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={openProfile}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push('/profile');
                }}
              >
                <ListItemIcon className={classes.minWidth}>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon className={classes.minWidth}>
                  <PowerSettingsNewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </MenuItem>
            </Menu>
          </div>
          <div>
            <img src="../../../images/iconSmallFickleTitle.png" width="48" />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !adminDrawerContext && classes.drawerPaperClose),
        }}
        open={adminDrawerContext}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} className={clsx(classes.text, !adminDrawerContext && classes.menuButtonHidden)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List className={clsx(classes.text, classes.menuScoll)}>
          <ListMenuItem title="News" linkTo="/news" iconComponent={<PublicIcon />} />
          <Divider className={classes.divider} />

          <ListMenuItem title="Overview" linkTo="/overview" iconComponent={<DonutLargeIcon />} />
          {myFeaturesContext && myFeaturesContext.enabledTrackAndTrace && (
            <ListMenuItem title="Track and Trace" linkTo="/trackandtrace" iconComponent={<MapIcon />} />
          )}

          {myFeaturesContext && myFeaturesContext.enabledTrendsOverTime && (
            <ListMenuItem title="Trends Over Time" linkTo="/trends" iconComponent={<TimelineIcon />} />
          )}

          <Divider className={classes.divider} />

          <ListMenuItem title="Port Pair Data" linkTo="/portpairdata" iconComponent={<StorageIcon />} />
          {myFeaturesContext && myFeaturesContext.enabledCommercialInvoices && (
            <ListMenuItem
              title="Commercial Invoices"
              tooltipTitle="Commercial Invoice Value Per Supplier"
              linkTo="/commercialInvoiceValuePerSupplier"
              iconComponent={<AttachMoneyIcon />}
            />
          )}

          {myFeaturesContext && myFeaturesContext.enabledSpendPerSuppliers && (
            <ListMenuItem title="Spend Per Supplier" linkTo="/spendpersupplier" iconComponent={<MonetizationOnIcon />} />
          )}
          <ListMenuItem title="Shipments" linkTo="/shipments" iconComponent={<DirectionsBoatIcon />} />
          {myFeaturesContext && myFeaturesContext.enabledProducts && <ListMenuItem title="Products" linkTo="/products" iconComponent={<StoreIcon />} />}
          {myFeaturesContext && myFeaturesContext.enabledEDIReport && (
            <ListMenuItem title="Integrations" linkTo="/integrations" iconComponent={<AssignmentIcon />} />
          )}
          {myFeaturesContext && myFeaturesContext.enabledPurchaseOrder && (
            <ListMenuItem title="Purchase Orders" linkTo="/purchase-order" iconComponent={<ListAltIcon />} />
          )}
          {myFeaturesContext && myFeaturesContext.enabledPurchaseOrderLinesFeature && (
            <ListMenuItem title="Purchase Order Lines" linkTo="/purchase-order-lines" iconComponent={<ListAltIcon />} />
          )}
          {myFeaturesContext && myFeaturesContext.enabledPurchaseOrderLineSpendsFeature && (
            <ListMenuItem title="Order Lines Spends" linkTo="/purchase-order-lines-spends" iconComponent={<ListAltIcon />} />
          )}
          {myFeaturesContext && myFeaturesContext.enabledPurchaseOrderLineMilestonesFeature && (
            <ListMenuItem title="Order Lines Milestones" linkTo="/purchase-order-lines-milestones" iconComponent={<ListAltIcon />} />
          )}

          {myFeaturesContext && myFeaturesContext.enabledConsolidatedShipmentsSpendsFeature && (
            <ListMenuItem title="Shipments Spends" linkTo="/consolidated-shipments-spends" iconComponent={<MonetizationOnIcon />} />
          )}
          {myFeaturesContext && myFeaturesContext.enabledShipmentSpendsFeature && (
            <ListMenuItem title="Shipment Spends" linkTo="/shipment-spends" iconComponent={<MonetizationOnIcon />} />
          )}
          {myFeaturesContext && myFeaturesContext.enabledReportFilesFeature && (
            <ListMenuItem title="Report Files" linkTo="/report-files" iconComponent={<InsertDriveFileIcon />} />
          )}
          {myFeaturesContext && myFeaturesContext.enabledExceptionsFeature && (
            <ListMenuItem title="Exceptions" linkTo="/exceptions" iconComponent={<InsertDriveFileIcon />} />
          )}
        </List>

        <List className={classes.bottom}>
          {userContext.isAdmin && userContext.permissions.canReadAccounts ? (
            <ListMenuItem title="Accounts" linkTo="/accounts" iconComponent={<AddBoxIcon />} />
          ) : null}
          <ListMenuItem title="Users" linkTo="/users" iconComponent={<Group />} />
          {userContext && userContext.permissions && userContext.permissions.canManageExternalApplications ? (
            <ListMenuItem title="External API" linkTo="/external-applications" iconComponent={<CloudIcon />} />
          ) : null}
        </List>
      </Drawer>
    </div>
  );
}
