import React, { useContext } from 'react';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import NumberOfPurchaseOrder from './NumberOfPurchaseOrder';
import GridContainer from '../../../components/Grid/GridContainer.js';
import GridItem from '../../Grid/GridItem';

export default function GeneralChartsTabContainer(props) {
  const [myFeaturesContext] = useContext(MyFeaturesContext);
  const { baseQueryString, filterComponentData, ShowTrendLine, ShowAvgLine } = props;
  return (
    <>
      <GridContainer>
        {myFeaturesContext.enabledTrackAndTrace && (
          <GridItem xs={12}>
            <NumberOfPurchaseOrder
              FilterComponentData={filterComponentData}
              baseQueryString={baseQueryString}
              ShowTrendLine={ShowTrendLine}
              ShowAvgLine={ShowAvgLine}
            />
          </GridItem>
        )}
      </GridContainer>
    </>
  );
}
