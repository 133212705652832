import { Button, Divider, makeStyles, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useContext, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import SupplierEntityListFrame from './SupplierEntityListFrame';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import FormBookingAddress from './FormBookingAddress';
import FormBookingContainerInfo from './FormBookingContainerInfo';
import FormBookingGoods from './FormBookingGoods';
import { useBookingStore } from '../../../contexts/BookingContext';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '100%',
    },
    stepIconColor: {
        color: theme.palette.primaryButton.backgroundColor + '!important',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        width: '100%',
        textAlign: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    formContainer: {
        margin: theme.spacing(0),
    },
    submitButton: {
        color: theme.palette.primaryButton.fontColor,
        backgroundColor: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            color: theme.palette.primaryButton.fontColor,
            backgroundColor: theme.palette.primaryButton.hoverBackgroundColor,
        },
    },
    linkButton: {
        color: theme.palette.primaryButton.backgroundColor,
        '&:hover': {
            color: theme.palette.primaryButton.hoverBackgroundColor,
        },
    },
    groupButton: {
        display: 'flex',
        justifyContent: 'right'
    }
}));

function getSteps() {
    return ['Address Information', 'Container Information', 'Goods Value & Additional Information'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <FormBookingAddress />;
        case 1:
            return <FormBookingContainerInfo />;
        case 2:
            return <FormBookingGoods />;
        default:
            return 'Unknown stepIndex';
    }
}

function CreateBooking(props) {
    let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
    const bookingStore = useBookingStore();
    const classes = useStyles();
    const progressRef = useRef(null);

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        console.log("bookingStore", bookingStore.pickupAddress);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <>
            <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
            <SupplierEntityListFrame>
                <h4 >Create Booking</h4>
                <Divider style={{ width: "100%" }} />
                <div className={classes.stepper}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconProps={{
                                    classes: {
                                        active: classes.stepIconColor,
                                        completed: classes.stepIconColor,
                                    }
                                }}>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ color: 'green', textAlign: 'center' }}><CheckCircleIcon /></div>
                                <Typography className={classes.instructions}>Create booking completed</Typography>
                                <Button color="primary" variant="text" component={Link} to="/bookings" className={classes.linkButton}>see Bookings</Button>
                            </div>
                        ) : (
                            <div>
                                <div className={classes.formContainer}>{getStepContent(activeStep)}</div>
                                <div className={classes.groupButton}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                                    <Button variant="contained" color="primary" className={classes.submitButton} onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </SupplierEntityListFrame>
        </>
    );
}

export default CreateBooking;