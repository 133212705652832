import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& h6': {
      color: theme.palette.adminDrawer.main,
    },
  },
  ticker: {
    '& .fifi-ticker': {
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      border: 'none',
      backgroundColor: theme.palette.fbxIndexes.ticker.backgroundColor + ' !important',
    },
    '& .index-title': {
      backgroundColor: theme.palette.adminDrawer.main + ' !important',
      padding: '4px 10px !important',
      top: '0px !important',
    },
    '& .fifi-ticker .content-wrapper .lane': {
      color: theme.palette.fbxIndexes.lane.color + ' !important',
    },
  },
}));

function FBXIndexes(props) {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.freightos.com/tools/embed.js';
    script.setAttribute('data-id', 'freightos-tool');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item className={classes.ticker}>
        <ticker titlebg="#04395a" titlecolor="#c70000" tickerbg="#58b7d4" tickercolor="#7f3030" border="#222222"></ticker>
      </Grid>
    </Grid>
  );
}

export default FBXIndexes;
