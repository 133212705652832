import React, { useEffect, useRef, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import { UserContext } from '../../../contexts/UserContext';
import EntityListFrame from '../../layouts/EntityListFrame';
import DataTableFilter from '../../parts/DataTableFilter';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import GridItem from '../../../components/Grid/GridItem';
import { withRouter } from 'react-router';
import Parser from 'html-react-parser';
import helperService from '../../../services/help.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { CSVLink } from 'react-csv';
import ExportMenu from '../../../wrappers/ExportMenu';

function PortPairDataTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Origin');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [userContext] = useContext(UserContext);
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();

  const progressRef = useRef(null);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    titleText: {
      marginBottom: '30px',
    },
    pdfboxArea: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    titleArea: {
      marginBottom: '28px',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  }));

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setFilter(data);
    setPage(0);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    var portOrigin = (data && data.specialSearch && data.specialSearch.portOriginCode) || '';
    var portDestination = (data && data.specialSearch && data.specialSearch.portDestinationCode) || '';
    apiService
      .getPortPairData(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        portOrigin,
        portDestination
      )
      .then((result) => {
        setRows(createTableListData(result.data.portPairDataReportItems));
        setTotalNumberOfRows(result.data.count);

        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];

    items.map((item) => {
      let cells = [
        item.origin + item.destination,
        item.origin,
        item.destination,
        item.weight.toLocaleString(),
        item.volume.toLocaleString(),
        item.teu.toLocaleString(),
        item.avgTransitTime.toLocaleString(),
      ];

      let tmpCell = {};
      tmpCell['id'] = item.origin + item.destination;
      headCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });

      newArray.push(tmpCell);
    });

    return newArray;
  };

  const classes = useStyles();
  const headCells = [
    {
      id: 'Origin',
      numeric: false,
      disablePadding: true,
      label: 'Origin',
    },
    {
      id: 'Destination',
      numeric: false,
      disablePadding: false,
      label: 'Destination',
    },
    {
      id: 'Weight',
      numeric: true,
      disablePadding: false,
      label: 'Weight (Kg)',
    },
    {
      id: 'Volume',
      numeric: true,
      disablePadding: false,
      label: Parser('<span>Volume (m<sup>3</sup>)</span>'),
    },
    {
      id: 'TEU',
      numeric: true,
      disablePadding: false,
      label: 'TEU',
    },
    {
      id: 'avgTransitTime',
      numeric: true,
      disablePadding: false,
      label: 'Avg. Transit Time (days)',
    },
  ];

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      var portOrigin = (filter && filter.specialSearch && filter.specialSearch.portOriginCode) || '';
      var portDestination = (filter && filter.specialSearch && filter.specialSearch.portDestinationCode) || '';
      apiService
        .getPortPairData(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          portOrigin,
          portDestination
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generatePortPairPDF(
            result.data.portPairDataReportItems,
            'Port Pair Data     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Port Pair Data Report'
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      var portOrigin = (filter && filter.specialSearch && filter.specialSearch.portOriginCode) || '';
      var portDestination = (filter && filter.specialSearch && filter.specialSearch.portDestinationCode) || '';
      apiService
        .getPortPairData(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          portOrigin,
          portDestination
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generatePortPairCsvData(result.data.portPairDataReportItems, filter.accountName + '_' + filter.periodName);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <EntityListFrame>
        <Grid container className={classes.titleArea}>
          <Grid item xs={12} sm className="align-self-center">
            <h4>Port Pair Data</h4>
          </Grid>
          <Grid item className={'align-self-center '}>
            <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DataTableFilter sendFilterComponentData={filterCallback} />
        </Grid>
        <Grid item xs={12} id="dashboardPrint">
          {rows === '' ? (
            ''
          ) : (
            <DataContentTable
              title=""
              headCells={headCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              page={page}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </EntityListFrame>
    </>
  );
}

export default withRouter(PortPairDataTable);
