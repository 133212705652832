import React, { useEffect } from 'react';
import { Box, makeStyles, Paper, Tab, Tabs, withStyles, withWidth } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useTabPanelStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) => (props.tabBackgroundColor ? props.tabBackgroundColor : theme.palette.myTab.backgroundColor),
    marginTop: -1,
    border: '1px solid #' + theme.palette.myTab.borderColor,
    borderRadius: 0,
    borderBottomLeftRadius: 8,
    flexGrow: 1,
  },
}));

export function TabPanel(props) {
  const { children, value, index, className, tabBackgroundColor, ...other } = props;
  const classes = useTabPanelStyle(props);

  return (
    <Paper
      className={clsx(classes.root, className)}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Paper>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const MyTabs = withStyles((theme) => ({
  root: {
    marginLeft: theme.palette.myTab.tabs.marginLeft,
  },
  indicator: {
    height: 0,
  },
}))(Tabs);

export const MyTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.myTab.activeTabFontColor,
      fontWeight: theme.typography.fontWeightBold,
    },
    borderBottom: '1px solid ' + theme.palette.myTab.borderColor,
    outline: 'none !important',
    color: theme.palette.adminDrawer.main,
    fontWeight: theme.typography.fontWeightBold,
  },
  selected: {
    borderLeft: '1px solid ' + theme.palette.myTab.borderColor,
    borderTop: '1px solid ' + theme.palette.myTab.borderColor,
    borderRight: '1px solid ' + theme.palette.myTab.borderColor,
    borderBottom: (props) => '1px solid ' + (props.tabBackgroundColor ? props.tabBackgroundColor : theme.palette.myTab.backgroundColor),
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: (props) => (props.tabBackgroundColor ? props.tabBackgroundColor : theme.palette.myTab.backgroundColor),
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
}))((props) => {
  const { tabBackgroundColor, ...rest } = props;
  return <Tab disableRipple {...rest} />;
});
