import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DataContentTable from '../../parts/DataContentTable';
import { ThemeProvider } from '@material-ui/styles';
import apiService from '../../../services/api.service';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import EntityListFrame from '../../layouts/EntityListFrame';
import { withRouter } from 'react-router';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import pdfTableService from '../../../services/pdf.table.service';
import csvTableService from '../../../services/csv.table.service';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { CSVLink } from 'react-csv';
import ExportMenu from '../../../wrappers/ExportMenu';
import { MyFeaturesContext } from '../../../contexts/MyFeaturesContext';
import FeatureDisabledMessage from '../../parts/FeatureDisabledMessage';
import moment from 'moment';
import tableService from '../../../services/table.service';
import ColumnSelector from '../../parts/ColumnSelector';
import PurchaseOrderDataTableFilter from '../PurchaseOrder/PurchaseOrderDataTableFilter';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  arrivedOnTime: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.onTime,
    color: 'white',
  },
  arrivedLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.late,
    color: 'white',
  },
  arrivedVeryLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.veryLate,
    color: 'white',
  },
}));

function PurchaseOrderLineMilestonesDataTable(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [baseQueryString, setBaseQueryString] = useState();
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [myFeaturesContext] = useContext(MyFeaturesContext);

  const progressRef = useRef(null);
  const history = useHistory();

  const defaultHeadCells = [
    {
      id: 'OrderNumber',
      numeric: false,
      disablePadding: true,
      label: 'Order No.',
    },
    {
      id: 'LineId',
      numeric: true,
      disablePadding: false,
      label: 'Line No.',
    },
    {
      id: 'OrderNumberSplit',
      numeric: true,
      disablePadding: false,
      label: 'Split No.',
    },
    {
      id: 'ShippingDate',
      numeric: true,
      disablePadding: false,
      label: 'Shipping Date',
    },
    {
      id: 'DeliveryDate',
      numeric: true,
      disablePadding: false,
      label: 'Delivery Date',
    },
    {
      id: 'RequiredInStoreDate',
      numeric: true,
      disablePadding: false,
      label: 'Required In Store',
    },
    {
      id: 'RecentEventType',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'OriginETD',
      numeric: true,
      disablePadding: false,
      label: 'ETD',
    },
    {
      id: 'OriginATD',
      numeric: true,
      disablePadding: false,
      label: 'ATD',
    },
    {
      id: 'DestinationETA',
      numeric: true,
      disablePadding: false,
      label: 'ETA',
    },
    {
      id: 'DestinationATA',
      numeric: true,
      disablePadding: false,
      label: 'ATA',
    },
    {
      id: 'InStoreDelayInDays',
      numeric: true,
      disablePadding: false,
      label: 'In Store Delay(D)',
      tooltip: 'Compare "Required In Store" and Shipment Arrival Date.',
    },
    {
      id: 'ArrivalDelayInDays',
      numeric: true,
      disablePadding: false,
      label: 'Arrival Delay(D)',
      tooltip: 'Compare "Delivery Date" and Shipment Arrival Date.',
    },
    {
      id: 'DepartureDelayInDays',
      numeric: true,
      disablePadding: false,
      label: 'Departure Delay(D)',
      tooltip: 'Compare "Shipping Date" and Shipment Departure Date.',
    },
    {
      id: 'TransitDelayInDays',
      numeric: true,
      disablePadding: false,
      label: 'Transit Delay(D)',
      tooltip: 'Compare Expected Transit Time and Actual Transit Time.',
    },
    {
      id: 'VendorBookingDelayInDays',
      numeric: true,
      disablePadding: false,
      label: 'Vendor Booking Delay(D)',
      tooltip: 'Compare "Shipping Date - 21 days" and Actual Vendor Booking Date.',
    },
  ];

  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();

  useEffect(() => {
    if (filter) {
      progressRef.current.continuousStart();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getPurchaseOrderLineMilestonesTableReport(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers,
        data.buyerId
      )
      .then((result) => {
        let contentRows = createTableListData(result.data.items);
        let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
        // console.log(filteredTable.headCells);
        setHeadCells(filteredTable.headCells);
        setRows(filteredTable.contentRows);
        setTotalNumberOfRows(result.data.count);
        setHiddenColumnNames(result.data.hiddenColumnNames);
        progressRef.current.complete();
      })
      .catch((err) => {
        if (progressRef && progressRef.current) progressRef.current.complete();
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  };

  const createTableListData = (items) => {
    let newArray = [];
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    const createDelayCell = (delayInDays, maxLateDay) => {
      let delayInDaysCell = { text: delayInDays, className: '' };
      if (delayInDays !== null) {
        if (delayInDays > 0) {
          delayInDaysCell.text = `+${delayInDays}`;
          if (delayInDays <= maxLateDay) {
            delayInDaysCell.className = classes.arrivedLate;
          } else {
            delayInDaysCell.className = classes.arrivedVeryLate;
          }
        } else {
          delayInDaysCell.className = classes.arrivedOnTime;
        }
      }
      return delayInDaysCell;
    };

    items.map((item, index) => {
      let shippingDate = item.shippingDate ? moment(item.shippingDate) : null;
      let deliveryDate = item.deliveryDate ? moment(item.deliveryDate) : null;
      let requiredInStoreDate = item.requiredInStoreDate ? moment(item.requiredInStoreDate) : null;
      let originETD = item.originETD ? moment(item.originETD) : null;
      let originATD = item.originATD ? moment(item.originATD) : null;
      let destinationETA = item.destinationETA ? moment(item.destinationETA) : null;
      let destinationATA = item.destinationATA ? moment(item.destinationATA) : null;

      let inStoreDelayInDays = createDelayCell(item.inStoreDelayInDays, 3);
      let arrivalDelayInDays = createDelayCell(item.arrivalDelayInDays, 3);
      let departureDelayInDays = createDelayCell(item.departureDelayInDays, 3);
      let transitDelayInDays = createDelayCell(item.transitDelayInDays, 7);
      let vendorBookingDelayInDays = createDelayCell(item.vendorBookingDelayInDays, 3);

      var shipmentNumber = { component: 'linkList', links: [] };
      if (item.shipmentNumber) {
        shipmentNumber.links.push({
          text: item.shipmentNumber,
          path: '/shipments?s=' + item.shipmentNumber,
        });
      } else {
        shipmentNumber = '';
      }

      var shipmentStatus = item.recentEventType;
      if (item.recentEventType && item.recentEventType != 'Pending' && item.recentEventType != 'Closed' && item.shipmentNumber) {
        shipmentStatus = {
          component: 'linkList',
          links: [
            {
              text: item.recentEventType,
              path: '/trackandtrace?s=' + item.shipmentNumber,
            },
          ],
        };
      }

      let rowData = {
        id: index,
        OrderNumber: item.orderNumber,
        LineId: item.lineId,
        OrderNumberSplit: item.orderNumberSplit,
        ShippingDate: shippingDate ? shippingDate.format('L') : '',
        DeliveryDate: deliveryDate ? deliveryDate.format('L') : '',
        RequiredInStoreDate: requiredInStoreDate ? requiredInStoreDate.format('L') : '',
        RecentEventType: shipmentStatus,
        OriginETD: originETD ? originETD.format('L') : '',
        OriginATD: originATD ? originATD.format('L') : '',
        DestinationETA: destinationETA ? destinationETA.format('L') : '',
        DestinationATA: destinationATA ? destinationATA.format('L') : '',
        InStoreDelayInDays: inStoreDelayInDays,
        ArrivalDelayInDays: arrivalDelayInDays,
        DepartureDelayInDays: departureDelayInDays,
        TransitDelayInDays: transitDelayInDays,
        VendorBookingDelayInDays: vendorBookingDelayInDays,
      };

      newArray.push(rowData);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderLineMilestonesTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          progressRef.current.complete();
          pdfTableService.generatePurhcaseOrderEDILineMilestonesPDF(
            result.data.items,
            'Purchase Orders Line Milestones     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Purchase Orders Line Milestones Report',
            result.data.hiddenColumnNames
          );
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderLineMilestonesTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          progressRef.current.complete();
          var csvData = csvTableService.generatePurchaseOrderLineMilestonesCsvData(
            result.data.items,
            filter.accountName + '_' + filter.supplierName + '_' + filter.periodName,
            result.data.hiddenColumnNames
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch((err) => {
          if (progressRef && progressRef.current) progressRef.current.complete();
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const handleColumnSelected = (headCells) => {
    setNewHeadCells(headCells);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
        <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
        <EntityListFrame>
          {myFeaturesContext &&
            (myFeaturesContext.enabledPurchaseOrder ? (
              <>
                <Grid container className={classes.titleArea}>
                  <Grid item xs={12} sm className="align-self-center">
                    <h4>Order Lines Milestones</h4>
                  </Grid>
                  <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                    <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
                  </Grid>
                  <Grid item xs={12} sm className={'align-self-center ' + classes.ExportMenuContainer}>
                    <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <PurchaseOrderDataTableFilter
                    sendFilterComponentData={filterCallback}
                    sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                    showSuppliers={true}
                    freeTextFilterOn='Order Number, Code and Status.'
                  />
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  {rows && (
                    <DataContentTable
                      title=""
                      headCells={newHeadCells}
                      totalNumberOfRows={totalNumberOfRows}
                      rows={rows}
                      page={page}
                      handleRequestSort={handleRequestSort}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </Grid>
              </>
            ) : (
              <FeatureDisabledMessage />
            ))}
        </EntityListFrame>
      </ThemeProvider>
    </>
  );
}

export default withRouter(PurchaseOrderLineMilestonesDataTable);
