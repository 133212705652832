export function createBookingStore() {
    return {
        pickupData: {
            company: "",
            address: "",
            address2: "",
            passcode: "",
            city: "",
            state: "",
            country: "",
            contact: "",
            phone: "",
            fax: "",
            email: "",
        },
        deliveryData: {
            company: "",
            address: "",
            address2: "",
            passcode: "",
            city: "",
            state: "",
            country: "",
            contact: "",
            phone: "",
            fax: "",
            email: "",
        },
        containerInformation: {
            origin: "",
            destination: "",
            mode: "",
            shipperRef: "",
            orderRef: "",
            goodsDescription: "",
            pack: "",
            packUnit: "Pallet",
            weight: "",
            weightUnit: "Kilograms",
            volume: "",
            volumeUnit: "Cubic Meters",
        },
        voyageNo: "",
        vessel: "",
        CFSCutOff: "",
        CTOCutOff: "",
        ETD: "",
        ETA: "",
        carrier: "",
        CFSReference: "",
        houseBillNumber: "",
        goodsValue: {
            goodsValue: "",
            goodsValueSelected: "",
            insuranceValue: "",
            insuranceValueSelected: "",
            shipperCODAmount: "",
            shipperCODAmountSelected: "",
        },
        additionalInformation: {
            warehouseRec: "",
            estimatedPickupDate: "",
            estimatedPickupTime: "",
            pickupRequiredByDate: "",
            pickupRequiredByTime: "",
            pickupEquipmentSelected: "",
            estimatedDeliveryDate: "",
            estimatedDeliveryTime: "",
            deliveryRequiredByDate: "",
            deliveryRequiredByTime: "",
            cartageDropMode: "",
            serviceLevel: "",
            incoterm: "",
            additionalTerms: "",
            chargesApply: "",
            releaseType: "",
            onBoard: "",
        },
        goodsOrPacks: [],
        referenceNumbers: [],
        bookingCustoms: {
            customs: "",
            markAndNumbers: "",
            specialInstructions: "",
        },
        setPickupData(objData) {
            this.pickupData = { ...objData };
        },
        setDeliveryData(objData) {
            this.deliveryData = { ...objData };
        },
        setContainerInformation(objData) {
            this.containerInformation = { ...objData };
        },
        setGoodsValue(objData) {
            this.goodsValue = { ...objData };
        },
        setAdditionalInformation(objData) {
            this.additionalInformation = { ...objData };
        },
        setGoodsOrPacks(arrayData) {
            this.goodsOrPacks = [...arrayData];
        },
        setReferenceNumbers(arrayData) {
            this.referenceNumbers = [...arrayData];
        },
        setBookingCustoms(objData) {
            this.bookingCustoms = { ...objData };
        }
    }
}