import React, { useState, useRef, useContext, useEffect, useLayoutEffect } from 'react';
import AdminDrawer from '../layouts/AdminDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { darkTheme } from '../../themes/darkTheme';
import { lightThemeAdmin } from '../../themes/lightTheme';
import LoadingBar from 'react-top-loading-bar';
import { UserContext } from '../../contexts/UserContext';
import { ReloadContext } from '../../contexts/ReloadContext';
import Container from '@material-ui/core/Container';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer.js';
import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TopRedBar from '../layouts/TopRedBar';
import TotalShipmentCard from './Dashboard/TotalShipmentCard';
import TotalFCLTEUCard from './Dashboard/TotalFCLTEUCard';
import TotalCBMCard from './Dashboard/TotalCBMCard';
import JobTypes from './Dashboard/JobTypesChart';
import CustomCharges from './Dashboard/CustomChargesChart';
import IncontermCharts from './Dashboard/IncontermCharts';
import CBMPerSupplier from './Dashboard/CBMPerSupplierChart';
import WeightPerPortal from './Dashboard/WeightPerPortalChart';
import LCLVolumePerPortPair from './Dashboard/LCLVolumePerPortPair';
import TEUPerPortPair from './Dashboard/TEUPerPortPair';
import IcontermPerPortPairChart from './Dashboard/IcontermPerPortPairChart';
import VolumePerCountryChart from './Dashboard/VolumePerCountryChart';
import JobTypeSupplierChart from './Dashboard/JobTypePerSupplierChart';
import PDFModule from '../../services/pdf.service';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomsAndDutyPerSupplierChart from './Dashboard/CustomsAndDutyPerSupplierChart';
import AirFreightKGSPerSupplierChart from './Dashboard/AirFreightKGSPerSupplierChart';
import AvgTransitTimePerPortPairChart from './Dashboard/AvgTransitTimePerPortPairChart';
import ShipmentDelaysChart from './Dashboard/ShipmentDelaysChart';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import ExportToPdfButton from '../../wrappers/ExportToPdfButton';
import DashboardChartGridItem from './Dashboard/parts/DashboardChartGridItem';
import TotalDisbursementChargesChart from './Dashboard/TotalDisbursementCharges';
import DataTableFilter from '../parts/DataTableFilter';
import { MyFeaturesContext } from '../../contexts/MyFeaturesContext';
import OrderDelaysChart from './Dashboard/OrderDelaysChart';
import apiService from '../../services/api.service';
import CASAFreightSpendsPieChart from './Dashboard/CASAFreightSpendsPieChart';
import FreightSpendsPieChart from './Dashboard/FreightSpendsPieChart';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: 0,
    maxWidth: '1600px',
  },
  spacer: {
    flex: '1 1 auto',
  },
  footer: {
    margin: 0,
    padding: 0,
    maxWidth: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

function Dashboard(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;

  const childRef = useRef();
  const classes = useStyles();
  const progressRef = useRef(null);
  const [userContext] = useContext(UserContext);
  const [reloadContext] = useContext(ReloadContext);
  const [myFeaturesContext] = useContext(MyFeaturesContext);
  const [filterComponentData, setFilterComponentData] = useState(true);
  const [showPDFButton, setshowPDFButton] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [baseQueryString, setBaseQueryString] = useState();
  const [topBoxSize, setTopBoxSize] = useState({ sm: 6, md: 6, lg: 3 });
  const [accountId, setAccountId] = useState();
  const [overviewSettings, setOverviewSettings] = useState();
  const currentPieChartIndexRef = useRef();
  const [chartBoxSizes, setChartBoxSizes] = useState({});

  const generatePdf = () => {
    setOpenBackdrop(true);
    am4core.unuseTheme(am4themes_animated);
    var returnValue = childRef.current
      .preparePDF()
      .then(() => {
        const element = document.querySelector('#dashboardPrint');
        const options = {
          filename: 'Report-' + new Date().toDateString() + '.pdf',
          excludeClassNames: ['exclude'],
          company: filterComponentData.companyName,
          period: filterComponentData.periodName,
          suppliers: filterComponentData.suppliers ? filterComponentData.supplierName : 'ALL',
        };

        return PDFModule(element, options, () => {
          am4core.useTheme(am4themes_animated);
          setOpenBackdrop(false);
        });
      })
      .then(() => {
        childRef.current.finishedPDF();
      });
  };

  useEffect(() => {
    if (myFeaturesContext) {
      if (myFeaturesContext.enabledCommercialInvoices) {
        setTopBoxSize({ sm: 6, md: 6, lg: 3 });
      } else {
        setTopBoxSize({ sm: 12, md: 4, lg: 4 });
      }
    }
  }, [myFeaturesContext]);

  useEffect(() => {
    const pieChartNames = ['CustomCharges', 'CASAFreightSpendsPieChart', 'FreightSpendsPieChart', 'JobTypes', 'ShipmentDelay', 'OrderDelay'];

    const getDisplayPieChartNames = () => {
      let displayChartNames = [];
      pieChartNames.forEach((x) => {
        if (!isHidden(x)) {
          displayChartNames.push(x);
        }
      });
      return displayChartNames;
    };

    const getPieChartsLayout = (totalDisplayCharts, currentChartIndex) => {
      switch (totalDisplayCharts) {
        case 6:
          return { xs: 12, sm: 6, md: 4, lg: 4 };
        case 5:
          if (currentChartIndex < 3) {
            return { xs: 12, sm: 6, md: 4, lg: 4 };
          } else {
            return { xs: 12, sm: true, md: 6, lg: 6 };
          }
        case 4:
          return { xs: 12, sm: 12, md: 6, lg: 3 };
        case 3:
          if (currentChartIndex < 2) {
            return { xs: 12, sm: 6, md: 4 };
          } else {
            return { xs: 12, sm: 12, md: 4 };
          }
        case 2:
          return { xs: 12, sm: 12, md: 6 };
        case 1:
          return { xs: 12 };
      }
    };

    if (overviewSettings) {
      let tempChartBoxSizes = {};
      let displayChartNames = getDisplayPieChartNames();
      displayChartNames.map((chartName, index) => {
        tempChartBoxSizes[chartName] = getPieChartsLayout(displayChartNames.length, index);
      });
      console.log(tempChartBoxSizes);
      setChartBoxSizes(tempChartBoxSizes);
    }
  }, [overviewSettings]);

  useEffect(() => {
    const loadOverviewSettings = async () => {
      let result = await apiService.getOverviewSettings(accountId);
      setOverviewSettings(result.data);
    };

    setOverviewSettings(undefined);
    if (accountId) {
      loadOverviewSettings();
    }
  }, [accountId]);

  useEffect(() => {
    if (filterComponentData && filterComponentData.accountId) {
      setAccountId(filterComponentData.accountId);
    }
  }, [filterComponentData]);

  const isHidden = (chartName) => {
    if (overviewSettings) {
      if (overviewSettings.hiddenCharts && overviewSettings.hiddenCharts.length > 0) {
        let hiddenChartIndex = overviewSettings.hiddenCharts.findIndex((hiddenChart) => hiddenChart.toLowerCase() === chartName.toLowerCase());
        return hiddenChartIndex > -1;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <div className={classes.root}>
          <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
          <AdminDrawer />
          <main className={classes.content}>
            <TopRedBar />
            <Container id="dashboardPrint" maxWidth="lg" className={classes.container}>
              <GridContainer>
                <Hidden lgUp>
                  <GridItem xs={12} sm={12} md={12}>
                    <ExportToPdfButton onClick={generatePdf}></ExportToPdfButton>
                  </GridItem>
                </Hidden>
                <GridItem xs={12} sm={12} md={12} lg={9} style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                  <DataTableFilter
                    sendFilterComponentData={setFilterComponentData}
                    showSuppliers={true}
                    showFreeText={false}
                    sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                  />
                </GridItem>
                <Hidden mdDown>
                  <GridItem xs={12} sm={12} md={12} lg={3} className="align-self-center">
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                      <ExportToPdfButton onClick={generatePdf}></ExportToPdfButton>
                    </div>
                  </GridItem>
                </Hidden>
                <div className={classes.spacer} />
                <GridItem xs={12} sm={12} md={12}>
                  <Divider />
                </GridItem>
              </GridContainer>
              <GridContainer style={{ paddingTop: '15px' }}>
                <DashboardChartGridItem xs={12} {...topBoxSize}>
                  <TotalShipmentCard FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
                <DashboardChartGridItem xs={12} {...topBoxSize}>
                  <TotalFCLTEUCard FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
                <DashboardChartGridItem xs={12} {...topBoxSize}>
                  <TotalCBMCard FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
                {myFeaturesContext && myFeaturesContext.enabledCommercialInvoices && (
                  <DashboardChartGridItem xs={12} {...topBoxSize}>
                    <TotalDisbursementChargesChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                )}
              </GridContainer>
              <GridContainer>
                {chartBoxSizes.CustomCharges && (
                  <DashboardChartGridItem {...chartBoxSizes.CustomCharges}>
                    <CustomCharges FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                )}
                {chartBoxSizes.CASAFreightSpendsPieChart && (
                  <DashboardChartGridItem {...chartBoxSizes.CASAFreightSpendsPieChart}>
                    <CASAFreightSpendsPieChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                )}
                {chartBoxSizes.FreightSpendsPieChart && (
                  <DashboardChartGridItem {...chartBoxSizes.FreightSpendsPieChart}>
                    <FreightSpendsPieChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                )}
                {chartBoxSizes.JobTypes && (
                  <DashboardChartGridItem {...chartBoxSizes.JobTypes}>
                    <JobTypes FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                )}
                {chartBoxSizes.ShipmentDelay && (
                  <DashboardChartGridItem {...chartBoxSizes.ShipmentDelay}>
                    <ShipmentDelaysChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                )}
                {chartBoxSizes.OrderDelay && (
                  <DashboardChartGridItem {...chartBoxSizes.OrderDelay}>
                    <OrderDelaysChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                )}
              </GridContainer>
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12}>
                  <AirFreightKGSPerSupplierChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
                {/* <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                  <ChargeCode FilterComponentData={filterComponentData} showDataItems="10" />
                </DashboardChartGridItem> */}
              </GridContainer>

              {myFeaturesContext && myFeaturesContext.enabledCommercialInvoices && (
                <GridContainer>
                  <DashboardChartGridItem xs={12} sm={12} md={12}>
                    <CustomsAndDutyPerSupplierChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                  </DashboardChartGridItem>
                </GridContainer>
              )}
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12} lg={12}>
                  <IncontermCharts FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
              </GridContainer>
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12}>
                  <AvgTransitTimePerPortPairChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
              </GridContainer>
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12}>
                  <JobTypeSupplierChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
              </GridContainer>
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12}>
                  <CBMPerSupplier FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
              </GridContainer>
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                  <WeightPerPortal FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
                <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                  <LCLVolumePerPortPair FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
              </GridContainer>
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                  <TEUPerPortPair FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
                <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                  <IcontermPerPortPairChart FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
              </GridContainer>
              <GridContainer>
                <DashboardChartGridItem xs={12} sm={12} md={12}>
                  <VolumePerCountryChart ref={childRef} FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                </DashboardChartGridItem>
              </GridContainer>
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Dashboard;
