import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(255,255,255,0.7)',
    borderRadius: 6,
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressSmall: {
    width: '20px !important',
    height: '20px !important',
  },
  progressBig: {
    width: '60px !important',
    height: '60px !important',
  },
}));

export default function LoadingOverlay(props) {
  const classes = useStyles();
  const { IsLoading, Size } = props;

  return (
    <>
      {IsLoading ? (
        <div className={classes.root}>
          <CircularProgress className={clsx({ [classes.progressSmall]: Size === 'small', [classes.progressBig]: Size === 'big' })} />
        </div>
      ) : (
        ''
      )}
    </>
  );
}
