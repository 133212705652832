import { useLocalObservable } from "mobx-react";
import React, { createContext, useContext } from "react";
import { createBookingStore } from "../stores/booking.store";

const BookingContext = createContext(null)

export const BookingProvider = ({ children }) => {
    const bookingStore = useLocalObservable(createBookingStore)

    return <BookingContext.Provider value={bookingStore}>
        {children}
    </BookingContext.Provider>
}

export const useBookingStore = () => useContext(BookingContext)