import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Divider } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import apiService from '../../../services/api.service';
import StockIndexesChart from './StockIndexesChart';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },
  indexItem: {
    width: '100%',
    height: '100%',
    paddingRight: theme.spacing(1),
  },
  carouselProvider: {
    width: '100%',
    alignItems: 'center',
  },
  stockName: {
    color: '#4a95ed',
    marginBottom: theme.spacing(1),
  },
  currentIndex: {
    color: theme.palette.stockIndexes.priceColor,
    marginBottom: -5,
  },
  movement: {
    '&.pos': {
      color: '#3dddbf',
    },
    '&.neg': {
      color: '#f22b2b',
    },
  },
  navigateButton: {
    top: 0,
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'opacity .15s ease',
    border: 0,
    outline: 0,
    backgroundColor: 'transparent',
    color: theme.palette.stockIndexes.navigateButtonColor,
    '&:focus,&:hover': {
      color: theme.palette.adminDrawer.topButton,
      outline: 0,
    },
    '&:disabled': {
      color: 'rgba(16, 16, 16, 0.3)',
    },
  },
  navigateButtonDivider: {
    height: '80%',
  },
}));

function StockIndexes(props) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();
  const [visibleSlides, setVisibleSlide] = useState();
  const [indiceChartDatas, setIndiceChartDatas] = useState([]);
  const stockIndexes = [
    {
      name: 'S&P/ASX 200',
      symbol: '^AXJO',
    },
    {
      name: 'S&P 500',
      symbol: '^GSPC',
    },
    {
      name: 'Dow Jones',
      symbol: '^DJI',
    },
    {
      name: 'NASDAQ',
      symbol: '^IXIC',
    },
    {
      name: 'FTSE 100',
      symbol: '^FTSE',
    },
    {
      name: 'DAX',
      symbol: '^GDAXI',
    },
    {
      name: 'Euro Stoxx 50',
      symbol: '^STOXX50E',
    },
    {
      name: 'Nikkei 225',
      symbol: '^N225',
    },
    {
      name: 'HANG SENG',
      symbol: '^HSI',
    },
    {
      name: 'SET',
      symbol: '^SET.BK',
    },
  ];

  useEffect(() => {
    calculateVisibleSlides(width);
  }, [width]);

  function calculateVisibleSlides(screenWidth) {
    if (screenWidth > 1625) {
      setVisibleSlide(6);
    } else if (screenWidth > 1425) {
      setVisibleSlide(5);
    } else if (screenWidth > 1225) {
      setVisibleSlide(4);
    } else if (screenWidth > 985) {
      setVisibleSlide(3);
    } else if (screenWidth > 725) {
      setVisibleSlide(2);
    } else if (screenWidth > 525) {
      setVisibleSlide(1);
    }
  }

  useEffect(() => {
    const loadIndiceChartDatas = async (symbols) => {
      let result = await apiService.getIndiceChartDatas(symbols);
      setIndiceChartDatas(result.data);
    };

    if (stockIndexes) {
      let symbols = stockIndexes.map((x) => x.symbol);
      loadIndiceChartDatas(symbols.join(','));
    }
  }, []);

  return (
    <Paper className={classes.root}>
      <CarouselProvider
        visibleSlides={visibleSlides}
        totalSlides={stockIndexes.length}
        naturalSlideWidth={400}
        naturalSlideHeight={110}
        className={classes.carouselProvider + ' d-flex'}
        isIntrinsicHeight={true}
      >
        <Slider
          className="flex-grow-1"
          trayProps={{
            draggable: true,
          }}
        >
          {stockIndexes &&
            stockIndexes.map((item, index) => {
              let indiceChartData = indiceChartDatas ? indiceChartDatas.find((x) => x.symbol === item.symbol) : {};
              return (
                <Slide key={`slide${index}`}>
                  <Box className={classes.indexItem + ' d-flex '}>
                    <Grid container direction="row" spacing={1} className="flex-grow-1">
                      <Grid item xs={5} container direction="column" justify="space-between" alignItems="flex-start">
                        <Grid item>
                          <Typography variant="subtitle2" className={classes.stockName}>
                            {item.name}
                          </Typography>
                        </Grid>
                        {indiceChartData && (
                          <Grid item>
                            <Typography variant="subtitle2" className={classes.currentIndex}>
                              {indiceChartData.price.toLocaleString()}
                            </Typography>
                            <Typography variant="caption" className={indiceChartData.changePrice < 0 ? classes.movement + ' neg' : classes.movement + ' pos'}>
                              {indiceChartData.changePrice.toLocaleString()}({indiceChartData.changePricePercentage.toLocaleString()}%)
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <StockIndexesChart ChartId={item.symbol} ChartData={indiceChartData} />
                      </Grid>
                      <Grid item xs={1}>
                        <Divider orientation="vertical"></Divider>
                      </Grid>
                    </Grid>
                  </Box>
                </Slide>
              );
            })}
        </Slider>
        <ButtonBack className={classes.navigateButton}>
          <NavigateBefore fontSize="large" />
        </ButtonBack>
        <Divider orientation="vertical" className={classes.navigateButtonDivider}></Divider>
        <ButtonNext className={classes.navigateButton}>
          <NavigateNextIcon fontSize="large" />
        </ButtonNext>
      </CarouselProvider>
    </Paper>
  );
}

export default StockIndexes;
