import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import authService from '../../services/auth.service';
import { UserContext } from '../../contexts/UserContext';
import { AdminDrawerContext } from '../../contexts/AdminDrawerContext';
import { ReloadContext } from '../../contexts/ReloadContext';
import apiService from '../../services/api.service';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useMediaLayout } from 'use-media';
import useComponentVisible from '../../hooks/useComponentVisible';
import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';

import PublicIcon from '@material-ui/icons/Public';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';

import MenuFont from '../Typography/MenuFont';
import { MyFeaturesContext } from '../../contexts/MyFeaturesContext';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ViewListIcon from '@material-ui/icons/ViewListRounded';

const useListMenuItemStyles = makeStyles((theme) => ({
  listItem: {
    color: '#fff',
    paddingLeft: theme.spacing(3),
    '&:hover': {
      color: '#fff',
    },
  },
  listItemIcon: {
    color: theme.palette.adminDrawer.text,
  },
  menuFont: {
    fontSize: '1rem',
  },
}));
function ListMenuItem(props) {
  const classes = useListMenuItemStyles();
  const { title, tooltipTitle, linkTo, iconComponent } = props;
  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : title} placement="left">
      <ListItem button component={Link} to={linkTo} className={classes.listItem}>
        <ListItemIcon className={classes.listItemIcon}>{iconComponent}</ListItemIcon>
        <MenuFont className={classes.menuFont}>{title}</MenuFont>
      </ListItem>
    </Tooltip>
  );
}

const drawerWidth = 240;

const useAdminDrawerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  drawerButtons: {
    color: theme.palette.adminDrawer.button,
  },
  topDrawerButtons: {
    color: theme.palette.adminDrawer.topButton,
    backgroundColor: 'rgb(255, 255, 255,0.6)',
    height: '32px',
    width: '32px',
    margin: '0 0 0 13px',
    '&:hover,&:focus': {
      backgroundColor: '#e0dbdb',
    },
  },
  logo: {
    maxWidth: 35,
  },
  minWidth: {
    minWidth: '35px',
  },
  avatar: {
    height: '35px',
    width: '35px',
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: theme.palette.adminDrawer.main,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.adminDrawer.main,
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'rgb(190,190,190,0.3)',
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.adminDrawer.button,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    paddingLeft: '10px',
    flexGrow: 1,
    color: theme.palette.adminDrawer.text,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.adminDrawer.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  customBadge: {
    backgroundColor: '#BB86FC',
    color: 'white',
  },
  text: {
    color: theme.palette.adminDrawer.text,
  },
  'message-text > a': {
    color: theme.palette.adminDrawer.text,
  },
  divider: {
    background: theme.palette.adminDrawer.divider,
  },
  bottom: {
    marginTop: 'auto',
  },
  fontSizeText: {
    fontSize: '1rem',
  },
}));

export default function SupplierDrawer() {
  const classes = useAdminDrawerStyles();
  const [profileUrl, setProfileUrl] = useState('');
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const openProfile = Boolean(profileAnchorEl);
  const [userContext, setUserContext] = useContext(UserContext);
  const [adminDrawerContext, setAdminDrawerContext] = useContext(AdminDrawerContext);

  const [reloadContext, setReloadContext] = useContext(ReloadContext);
  const [notificationBadge, setNotificationBadge] = useState();
  const [myFeaturesContext, setMyFeaturesContext] = useContext(MyFeaturesContext);

  // const [openNotificationPopup, setOpenNotificationPopup] = useState(false);

  const isWide = useMediaLayout({ maxWidth: '1000px' });

  const history = useHistory({ forceRefresh: true });
  const { ref1, ref2, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);


  useEffect(() => {
    if (isWide) {
      setAdminDrawerContext(false);
    }
  }, [isWide]);

  const handleMenu = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setAdminDrawerContext(true);
  };
  const handleDrawerClose = () => {
    setAdminDrawerContext(false);
  };

  return (
    <div className={classes.root}>
      <div
        ref={ref1}
        style={{
          position: 'absolute',
          top: '42px',
          right: '85px',
          zIndex: '1500',
        }}
      >
      </div>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, adminDrawerContext && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} style={{ background: 'transparent', boxShadow: 'none' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, adminDrawerContext && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          {/* <img src={logo_company} alt="logo" className={classes.logo} /> */}
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}></Typography>

          <div>
            <img src="../../../images/iconSmallFickleTitle.png" width="48" />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !adminDrawerContext && classes.drawerPaperClose),
        }}
        open={adminDrawerContext}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} className={clsx(classes.text, !adminDrawerContext && classes.menuButtonHidden)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List className={classes.text}>
          <ListMenuItem title="Create Booking" linkTo="/create-booking" iconComponent={<AddCircleIcon />} />

          <ListMenuItem title="Bookings" linkTo="/bookings" iconComponent={<ViewListIcon />} />

        </List>

      </Drawer>
    </div>
  );
}
