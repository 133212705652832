import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export default function DelayChangedTextField(props) {
  const { onValueChange, value, ...remains } = props;

  const [valueText, setValueText] = useState(value);

  let timer;

  useEffect(() => {
    timer = setTimeout(() => {
      setSearchValues();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [valueText]);

  const handleValueTextChange = (e) => {
    setValueText(e.target.value);
  };

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      setSearchValues();
    }
  };

  const setSearchValues = () => {
    clearTimeout(timer);
    if (onValueChange) {
      onValueChange(valueText);
    }
  };

  return <TextField {...remains} value={valueText} onChange={handleValueTextChange} onKeyPress={handleOnKeyUp} />;
}
