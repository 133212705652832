import { Divider, FormControl, Grid, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
import { useBookingStore } from '../../../contexts/BookingContext';
import ReferenceNumbers from './ReferenceNumbers';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    section: {
        backgroundColor: '#0000001f',
        borderRadius: 8,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        margin: '0 8px',
    },
    formControl: {
        minWidth: 100,
    },
    formControlPickupEquipment: {
        minWidth: 210
    },
    selectRoot: {
        '&:focus': {
            backgroundColor: theme.palette.inputControl.backgroundColor,
        }
    },
    selectOutline: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.inputControl.backgroundColor,
    },
    input: {
        marginRight: theme.spacing(0.5),
        backgroundColor: theme.palette.inputControl.backgroundColor,
        '& input': {
            padding: theme.spacing(1),
        }
    },
}));

const SmallTextField = (props) => {
    const { width } = props;
    const classes = useStyles();
    return <TextField variant="outlined" InputProps={{
        className: classes.input, style: { width: width || 210 }
    }}{...props} />;
}

const SmallSelect = (props) => {
    const { width, menuItems, selectedValue, additionalInfoKey, value, handleAdditionalInfoInputChange } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("");

    useEffect(() => {
        if (value) { setSelected(value) }
        else { setSelected(selectedValue) }
    }, [])

    useEffect(() => {
        if (selected !== value) {
            handleAdditionalInfoInputChange(additionalInfoKey, selected)
        }
    }, [selected])

    return (
        <FormControl className={classes.formControlPickupEquipment} style={{ width: width || 210 }}>
            <Select
                variant="outlined"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={selected}
                onChange={(e) => { setSelected(e.target.value) }}
                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
            >
                {menuItems && menuItems.map((item, index) => <MenuItem key={`${item + index}`} value={`${item}`}>{item}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

function FormBookingGoods(props) {
    const bookingStore = useBookingStore();
    const classes = useStyles();
    const [openGoodsValue, setOpenGoodsValue] = useState(false);
    const [openInsuranceValue, setOpenInsuranceValue] = useState(false);
    const [openShipperCODAmount, setOpenShipperCODAmount] = useState(false);
    const [openPickupEquipment, setOpenPickupEquipment] = useState(false);

    const [goodsValueData, setGoodsValueData] = useState(toJS(bookingStore.goodsValue));
    const [additionalInfo, setAdditionalInfo] = useState(toJS(bookingStore.additionalInformation));
    const [bookingCustoms, setBookingCustoms] = useState(toJS(bookingStore.bookingCustoms));

    useEffect(() => {
        const tmpData = { ...additionalInfo, cartageDropMode: cartageDropMode[4], serviceLevel: serviceLevel[4], incoterm: incoterm[13], chargesApply: chargesApply[0], releaseType: releaseType[9], onBoard: onBoard[0] }
        setAdditionalInfo(tmpData)
    }, [])

    const handleGoodsValueDataInputChange = (key, value) => {
        const tmpData = { ...goodsValueData, [key]: value }
        setGoodsValueData(tmpData);
    }

    const handleAdditionalInfoInputChange = (key, value) => {
        const tmpData = { ...additionalInfo, [key]: value }
        setAdditionalInfo(tmpData);
    }

    const handleBookingCustomsInputChange = (key, value) => {
        const tmpData = { ...bookingCustoms, [key]: value }
        setBookingCustoms(tmpData);
    }

    useEffect(() => {
        if (goodsValueData) {
            console.log(goodsValueData);
            bookingStore.setGoodsValue(goodsValueData);
            console.log("goodsValueData", toJS(bookingStore.goodsValue));
        }
    }, [goodsValueData])

    useEffect(() => {
        if (additionalInfo) {
            console.log(additionalInfo);
            bookingStore.setAdditionalInformation(additionalInfo);
            console.log("additionalInfo", toJS(bookingStore.additionalInformation));
        }
    }, [additionalInfo])

    useEffect(() => {
        if (bookingCustoms) {
            console.log(bookingCustoms);
            bookingStore.setBookingCustoms(bookingCustoms);
            console.log("bookingCustoms", toJS(bookingStore.bookingCustoms));
        }
    }, [bookingCustoms])

    const cartageDropMode = ['Any', 'Haulier Supplies Lift', 'Hand Unload/Load by Premise', 'Hand Unload/Load by Haulier', 'Premise Supplies Lift', 'Ask Client'];
    const serviceLevel = ['Cross Border HKG Jobs', 'Deferred', 'Direct', 'Door to Door', 'Standard', 'Transhipment'];
    const incoterm = ['Cost And Freight', 'Cost, Insurance And Freight', 'Carriage and Insurance Paid To', 'Carriage Paid To',
        'Delivered At Place', 'Delivered At Terminal', 'Delivered Duty Paid', 'Delivered at Place Unloaded', 'Ex Works', 'Free Alongside Ship',
        'FCA - Free Carrier (seller is responsible for origin and loading)', 'FCA - Free Carrier (buyer is responsible for origin and loading)',
        'FCA - Free Carrier (seller is responsible for origin, buyer for loading)', 'Free On Board'
    ];
    const chargesApply = [`Do not replace rate amounts with 'As Agreed' on both sets.`,
        `Replace all charges with 'As Agreed' on both sets.`,
        `'As Agreed': do not replace charges on first set, replace all charges on second set.`,
        `'As Agreed': do not replace charges on first set, replace Prepaid charges on second set.`,
        `'As Agreed': replace all charges on first set, do not replace charges on second set.`,
        `'As Agreed': replace all charges on first set, replace Prepaid charges on second set.`,
        `'As Agreed': replace Collect charges on first set, do not replace charges on second set.`,
        `'As Agreed': replace Collect charges on first set, replace all charges on second set.`,
        `'As Agreed': replace Collect charges on first set, replace Prepaid charges on second set.`,
    ];
    const releaseType = [`Letter of Credit (Bank Release)`, `Sight Draft (Bank Release)`, `Time Draft (Bank Release)`,
        `Company/Cashier Check`, `Cash Against Documents`, `Express Bill of Lading`, `Letter of Indemnity`, `Not Negotiable unless consigned to Order`,
        `Original Bill - Surrendered at Origin`, `Original Bill Required at Destination`, `Sea Waybill`, `Original Bill Received`];
    const onBoard = [`Shipped`, `Clean`, `Laden`, `Received For Shipment`];

    return (
        <div>
            <Grid container alignItems='center'>
                <Grid item container xs={12} className={classes.section} spacing={2} alignItems='center'>
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Goods Value</Typography></Grid>

                    <Grid item xs={12} md={2}><Typography variant="body1">Goods Value:</Typography></Grid>
                    <Grid item container xs={12} md={10}>
                        <Grid item ><SmallTextField value={goodsValueData.goodsValue} onChange={(e) => handleGoodsValueDataInputChange('goodsValue', e.target.value)} /></Grid>
                        {/* <Grid item xs={3} lg={1}></Grid> */}
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="mode"
                                    id="mode"
                                    variant="outlined"
                                    open={openGoodsValue}
                                    onClose={() => setOpenGoodsValue(false)}
                                    onOpen={() => setOpenGoodsValue(true)}
                                    value={goodsValueData.goodsValueSelected}
                                    onChange={(e) => handleGoodsValueDataInputChange('goodsValueSelected', e.target.value)}
                                    classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                                >
                                    <MenuItem value={"HKD"}>HKD</MenuItem>
                                    <MenuItem value={"CNY"}>CNY</MenuItem>
                                    <MenuItem value={"AUD"}>AUD</MenuItem>
                                    <MenuItem value={"USD"}>USD</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={2}><Typography variant="body1">Insurance Value:</Typography></Grid>
                    <Grid item container xs={12} md={10}>
                        <Grid item ><SmallTextField value={goodsValueData.insuranceValue} onChange={(e) => handleGoodsValueDataInputChange('insuranceValue', e.target.value)} /></Grid>
                        {/* <Grid item xs={3} lg={1}></Grid> */}
                        <Grid item >
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="mode"
                                    id="mode"
                                    variant="outlined"
                                    open={openInsuranceValue}
                                    onClose={() => setOpenInsuranceValue(false)}
                                    onOpen={() => setOpenInsuranceValue(true)}
                                    value={goodsValueData.insuranceValueSelected}
                                    onChange={(e) => handleGoodsValueDataInputChange('insuranceValueSelected', e.target.value)}
                                    classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                                >
                                    <MenuItem value={"HKD"}>HKD</MenuItem>
                                    <MenuItem value={"CNY"}>CNY</MenuItem>
                                    <MenuItem value={"AUD"}>AUD</MenuItem>
                                    <MenuItem value={"USD"}>USD</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Shipper COD Amount:</Typography></Grid>
                    <Grid item container xs={12} md={10}>
                        <Grid item ><SmallTextField value={goodsValueData.shipperCODAmount} onChange={(e) => handleGoodsValueDataInputChange('shipperCODAmount', e.target.value)} /></Grid>
                        {/* <Grid item xs={3} lg={1}></Grid> */}
                        <Grid item >
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="mode"
                                    id="mode"
                                    variant="outlined"
                                    open={openShipperCODAmount}
                                    onClose={() => setOpenShipperCODAmount(false)}
                                    onOpen={() => setOpenShipperCODAmount(true)}
                                    value={goodsValueData.shipperCODAmountSelected}
                                    onChange={(e) => handleGoodsValueDataInputChange('shipperCODAmountSelected', e.target.value)}
                                    classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                                >
                                    <MenuItem value={"Company Check"}>Company Check</MenuItem>
                                    <MenuItem value={"Bank Check"}>Bank Check</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid></Grid>
                </Grid>


                <Grid item container xs={12} className={classes.section} spacing={2} alignItems='center'>
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Additional Information</Typography></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Warehouse Rec:</Typography></Grid>
                    <Grid item xs={12} md={10}><SmallTextField type='date' value={additionalInfo.warehouseRec} onChange={(e) => handleAdditionalInfoInputChange('warehouseRec', e.target.value)} /></Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Estimated Pickup:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}>
                        <SmallTextField type='date' value={additionalInfo.estimatedPickupDate} onChange={(e) => handleAdditionalInfoInputChange('estimatedPickupDate', e.target.value)} />
                        <SmallTextField type='time' value={additionalInfo.estimatedPickupTime} width={125} onChange={(e) => handleAdditionalInfoInputChange('estimatedPickupTime', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Pickup Required By:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}>
                        <SmallTextField type='date' value={additionalInfo.pickupRequiredByDate} onChange={(e) => handleAdditionalInfoInputChange('pickupRequiredByDate', e.target.value)} />
                        <SmallTextField type='time' value={additionalInfo.pickupRequiredByTime} width={125} onChange={(e) => handleAdditionalInfoInputChange('pickupRequiredByTime', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Pickup Equipment:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <FormControl className={classes.formControlPickupEquipment} style={{ width: 255 }}>
                            <Select
                                labelId="mode"
                                id="mode"
                                variant="outlined"
                                open={openPickupEquipment}
                                onClose={() => setOpenPickupEquipment(false)}
                                onOpen={() => setOpenPickupEquipment(true)}
                                value={additionalInfo.pickupEquipmentSelected}
                                onChange={(e) => handleAdditionalInfoInputChange('pickupEquipmentSelected', e.target.value)}
                                classes={{ root: classes.selectRoot, outlined: classes.selectOutline }}
                            >
                                <MenuItem value={"Any"}>Any</MenuItem>
                                <MenuItem value={"Haulier Supplies Lift"}>Haulier Supplies Lift</MenuItem>
                                <MenuItem value={"Hand Unload/Load by Premise"}>Hand Unload/Load by Premise</MenuItem>
                                <MenuItem value={"Hand Unload/Load by Haulier"}>Hand Unload/Load by Haulier</MenuItem>
                                <MenuItem value={"Premise Supplies Lift"}>Premise Supplies Lift</MenuItem>
                                <MenuItem value={"Ask Client"}>Ask Client</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={2}><Typography variant="body1">Estimated Delivery:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}>
                        <SmallTextField type='date' value={additionalInfo.estimatedDeliveryDate} onChange={(e) => handleAdditionalInfoInputChange('estimatedDeliveryDate', e.target.value)} />
                        <SmallTextField type='time' width={125} value={additionalInfo.estimatedDeliveryTime} onChange={(e) => handleAdditionalInfoInputChange('estimatedDeliveryTime', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Delivery Required By:</Typography></Grid>
                    <Grid item xs={12} md={10} lg={4}>
                        <SmallTextField type='date' value={additionalInfo.deliveryRequiredByDate} onChange={(e) => handleAdditionalInfoInputChange('deliveryRequiredByDate', e.target.value)} />
                        <SmallTextField type='time' width={125} value={additionalInfo.deliveryRequiredByTime} onChange={(e) => handleAdditionalInfoInputChange('deliveryRequiredByTime', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Cartage Drop Mode:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallSelect menuItems={cartageDropMode} width={255} selectedValue={cartageDropMode[4]} additionalInfoKey={'cartageDropMode'} value={additionalInfo.cartageDropMode} handleAdditionalInfoInputChange={handleAdditionalInfoInputChange} />
                    </Grid>

                    <Grid item xs={12} md={2}><Typography variant="body1">Service Level:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallSelect menuItems={serviceLevel} width={255} selectedValue={serviceLevel[4]} additionalInfoKey={'serviceLevel'} value={additionalInfo.serviceLevel} handleAdditionalInfoInputChange={handleAdditionalInfoInputChange} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Incoterm:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallSelect menuItems={incoterm} width={255} selectedValue={incoterm[13]} additionalInfoKey={'incoterm'} value={additionalInfo.incoterm} handleAdditionalInfoInputChange={handleAdditionalInfoInputChange} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Additional Terms:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallTextField width={255} value={additionalInfo.additionalTerms} onChange={(e) => handleAdditionalInfoInputChange('additionalTerms', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Charges Apply:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallSelect menuItems={chargesApply} width={355} selectedValue={chargesApply[0]} additionalInfoKey={'chargesApply'} value={additionalInfo.chargesApply} handleAdditionalInfoInputChange={handleAdditionalInfoInputChange} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Release Type:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallSelect menuItems={releaseType} width={355} selectedValue={releaseType[9]} additionalInfoKey={'releaseType'} value={additionalInfo.releaseType} handleAdditionalInfoInputChange={handleAdditionalInfoInputChange} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">On Board:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallSelect menuItems={onBoard} width={255} selectedValue={onBoard[0]} additionalInfoKey={'onBoard'} value={additionalInfo.onBoard} handleAdditionalInfoInputChange={handleAdditionalInfoInputChange} />
                    </Grid>
                </Grid>

                <Grid item container xs={12} className={classes.section} spacing={2} >
                    <Grid item xs={12}><Typography variant="h6" gutterBottom>Reference Numbers</Typography></Grid>
                    <ReferenceNumbers />
                    <Grid item xs={12} md={2}><Typography variant="body1">Customs#:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallTextField width={255} value={bookingCustoms.customs} onChange={(e) => handleBookingCustomsInputChange('customs', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Marks & Numbers:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallTextField width={255} value={bookingCustoms.markAndNumbers} onChange={(e) => handleBookingCustomsInputChange('markAndNumbers', e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={2}><Typography variant="body1">Special instructions:</Typography></Grid>
                    <Grid item xs={12} md={10}>
                        <SmallTextField width={255} multiline rows={4} value={bookingCustoms.specialInstructions} onChange={(e) => handleBookingCustomsInputChange('specialInstructions', e.target.value)} />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default FormBookingGoods;