import React, { useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../wrappers/Button';
import { UserContext } from '../../contexts/UserContext';

function DeleteDialog(props) {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  useEffect(() => {
    var action = props.action;
    if (action.show) {
      setOpenDeleteDialog(true);
    }
  }, [props.action]);

  const handleCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    props.handleDelete(props.action.id);
    setOpenDeleteDialog(false);
  };
  return (
    <Dialog open={openDeleteDialog} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button theme={parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark'} onClick={handleDelete}>
          Yes, please
        </Button>
        <Button theme={parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark'} isSecondary="true" onClick={handleCancel}>
          No, take me back
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
